import './Applications.scss'
import React, { useState } from 'react'
import JotformEmbed from 'react-jotform-embed';
import { useLocation, useSearchParams } from 'react-router-dom';

import unlock from '../../Images/unlock.png'
import mail from '../../Images/mail.png'
import Logo from '../../Images/Logo.png'
import Close from '../../Images/closeMenu.png'
import Arrow from '../../Images/downArrow.png'
// explain
import Apply from '../../Images/Apply.png'
import Review from '../../Images/Review.png'
import Found from '../../Images/Found.png'
import cash from '../../Images/003-cash.png'
import discount from '../../Images/002-discount.png'
import convenience from '../../Images/001-convenience.png'

import appmoney from '../../Images/appmoney.png'
import appcalendar from '../../Images/appcalendar.png'
import appdropshipping from '../../Images/appdropshipping.png'
import appsearch from '../../Images/appsearch.png'
import appwallet from '../../Images/appwallet.png'

import { Helmet } from "react-helmet";
import { useEffect } from 'react';
// var __html = require('./ApplicationsJS.html');

export default function Applications({ navigateTo }) {
    const [searchParams] = useSearchParams();
    const [scrollPosition, setScrollPosition] = useState(0);
    const [scrollButton, setScrollButton] = useState(false);
    const [expleation, setExpleation] = useState(false);

    const Location = useLocation()

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
        if (position > 2100) {
            setScrollButton(true)
        } else if (position < 2100) {
            setScrollButton(false)
        }
    };

    useEffect(() => {
        setTimeout(() => {
            // setExpleation(true)
        }, 3500);

        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
        // 

    }, [null]);

    return (
        <div className="Applications">
            <Helmet>
                {/* <!-- TITLE --> */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>SENEER | Apply Fast For Business Funding</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="SENEER | Apply Fast For Business Funding" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="SENEER | Apply Fast For Business Funding" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="website" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="/Logo.png" data-react-helmet="true" />
                <meta property="og:image:width" content="1200" data-react-helmet="true" />
                <meta property="og:image:height" content="1200" data-react-helmet="true" />

                {/* <!-- Full link to the current webpage address --> */}
                <meta name="og:url" property="og:url" content="https://www.seneercapital.com/apply" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Visit our business funding application page for a streamlined process, ensuring fast and efficient funding tailored to your unique business needs." data-react-helmet="true" />
                <meta name="og:description" property="og:description" content="Visit our business funding application page for a streamlined process, ensuring fast and efficient funding tailored to your unique business needs." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:site_name" property="og:site_name" content="SENEER | Apply Fast For Business Funding" data-react-helmet="true" />
                <meta name="keywords" content="Financing, Business, Advance, Funding, Cash, Merchant, Capital, Loan, Credit, Repayment" data-react-helmet="true" />
            </Helmet>
            {/* ApplicationsHeader */}
            <div className="ApplicationsHeader" id='top'>
                <div className='ApplicationsHeader12'>
                    <span>
                        <h1>SENEER Capital</h1>
                        <h2>Quick business Funds</h2>
                    </span>
                    <span>
                        <img src={Logo} alt="Logo Seneer Capital Application page" />
                    </span>
                </div>
            </div>
            {/* ApplicationsHeader2 */}
            <div className='ApplicationsHeader2' onClick={() => setExpleation(true)}>
                <img src={unlock} alt="" className='ApplicationsHeader2Lock' />
                <h3> <b>Soft Check &nbsp;</b>| Your Information is encrypted  <img src={Arrow} alt="" /></h3>
            </div>

            {/* ApplicationsMainFormPage */}
            <div className="ApplicationsMainFormPage">
                <div className="JotFrom">
                    <JotformEmbed src={`https://form.jotformeu.com/232388604634157${Location.search ? Location.search : ''}`} />
                </div>
            </div>
            {/* Explain */}
            <div className="HowItWorkMain">

                <div className="HowItWorkMainPhone">
                    <h1>Flexible Business <br /> funding designed <br /> just for you</h1>
                    <h2>Grow your business 2.5x faster <br /> with SENEER Capital financing solutions.</h2>
                    <button onClick={() => window.scrollTo(0, 0)}>Get Funded!</button>
                    <h3>No Credit/Hard checks | Equity-free</h3>

                    <div className="HowItWorkMainPhoneBanner">
                        <h4>Grow Your <br /><span>Business</span></h4>

                        <div className="HowItWorkMainWhyUsShadow"></div>
                    </div>

                    <div className="HowItWorkMainWhyUsBorder"></div>
                </div>

                <div className="HowItWorkMainWhyUs">
                    <h1>Here’s why Business keep choosing <br /> <span>SENEER Capital</span></h1>

                    <div className="HowItWorkMainWhyUsMain">
                        <div className="HowItWorkMainWhyUsMainbOX">
                            <img src={appmoney} alt="Apply online for small business loan | SENEER Capital" />
                            <h2>Keep your supply chain running <br />smoothly with continuous cash infusions <br />tailored to your business needs</h2>
                        </div>

                        <div className="HowItWorkMainWhyUsMainbOX">
                            <img src={appcalendar} alt="Apply online for small business loan | SENEER Capital" />
                            <h2>Enjoy the freedom to adapt your <br /> plan in real time  with <br /> our flexible options</h2>
                        </div>

                        <div className="HowItWorkMainWhyUsMainbOX">
                            <img src={appdropshipping} alt="Apply online for small business loan | SENEER Capital" />
                            <h2>With our financing solutions you <br /> have the autonomy to allocate funds <br /> where they're needed most</h2>
                        </div>
                    </div>

                    <div className="HowItWorkMainWhyUsBorder"></div>
                </div>

                <div className="HowItWorkMainExplain">
                    <div className="HowItWorkMainExplainHeader">
                        <h1>How it works</h1>
                        <h2>The process for acquiring a working capital loan is fast and simple.</h2>
                    </div>
                    <div className="HowItWorkMainExplainBody">
                        <div className="HowItWorkMainExplainBodyBoxLine"></div>

                        <div className="HowItWorkMainExplainBodyBox">
                            <div className="HowItWorkMainExplainBodyBoxLineDot"></div>
                            <div className="HowItWorkMainExplainBodyBoxLeft">
                                <h2>STEP 1</h2>
                                <h3>Submit an <br /> application</h3>
                            </div>
                            <div className="HowItWorkMainExplainBodyBoxRight">
                                <h4>Discover a new level of convenience when applying with us! Experience the simplest and most streamlined application process ever devised. Gone are the days of complicated paperwork and lengthy procedures. With just a few clicks, you'll be well on your way to seizing exciting opportunities.</h4>
                            </div>
                        </div>

                        <div className="HowItWorkMainExplainBodyBox">
                            <div className="HowItWorkMainExplainBodyBoxLineDot"></div>
                            <div className="HowItWorkMainExplainBodyBoxLeft">
                                <h2>STEP 2</h2>
                                <h3>Review <br /> & Approve</h3>
                            </div>
                            <div className="HowItWorkMainExplainBodyBoxRight">
                                <h4>After the thorough underwriting process is completed, a highly skilled funding specialist will personally connect with you. They will guide you through the various offers available, tailored specifically to meet the unique needs of your business. </h4>
                            </div>
                        </div>

                        <div className="HowItWorkMainExplainBodyBox">
                            <div className="HowItWorkMainExplainBodyBoxLineDot"></div>
                            <div className="HowItWorkMainExplainBodyBoxLeft">
                                <h2>STEP 3</h2>
                                <h3>Get <br /> Funded</h3>
                            </div>
                            <div className="HowItWorkMainExplainBodyBoxRight">
                                <h4>Once you've accepted the offer, we'll promptly send you a contract that outlines the terms and conditions of the agreement. This contract will be tailored specifically to your chosen offer, ensuring complete transparency and clarity. </h4>
                            </div>
                        </div>

                        <div className="HowItWorkMainExplainBodyBox">
                            <div className="HowItWorkMainExplainBodyBoxLineDot"></div>
                            <div className="HowItWorkMainExplainBodyBoxLeft">
                                <h2>STEP 4</h2>
                                <h3>Continue an <br /> ongoing partnership</h3>
                            </div>
                            <div className="HowItWorkMainExplainBodyBoxRight">
                                <h4>Our working capital team provides continuous, personalized service to help support your <br /> short—and long-term—business goals.</h4>
                            </div>
                        </div>
                    </div>

                    <div className="HowItWorkMainWhyUsBorder"></div>
                </div>

                <div className="HowItWorkMainWarning">

                    <div class="ApplicationsReviewBox">
                        <img class="lazyload-mobile" src="https://dav3uwijswi8g.cloudfront.net/become/homepage/img/news_logo_techcrunch.svg?v=2.5" data-src="https://dav3uwijswi8g.cloudfront.net/become/homepage/img/news_logo_techcrunch.svg?v=2.5" alt="SENEER Capital Tech Crunch" />
                        <img class="lazyload-mobile" src="https://www.freepnglogos.com/uploads/forbes-logo-png/b-c-forbes-business-grey-logo-17.png" data-src="https://dav3uwijswi8g.cloudfront.net/become/homepage/img/news_logo_forbes.svg?v=2.5" alt="SENEER Capital Forbes" />
                        <img class="lazyload-mobile" src="https://dav3uwijswi8g.cloudfront.net/become/homepage/img/news_logo_inc.svg?v=2.5" data-src="https://dav3uwijswi8g.cloudfront.net/become/homepage/img/news_logo_inc.svg?v=2.5" alt="SENEER Capital Inc" />
                        <img class="lazyload-mobile" src="https://dav3uwijswi8g.cloudfront.net/become/homepage/img/news_logo_business_insider.svg?v=2.5" data-src="https://dav3uwijswi8g.cloudfront.net/become/homepage/img/news_logo_business_insider.svg?v=2.5" alt="SENEER Capital Smart Company" />
                        <img class="lazyload-mobile" src="https://dav3uwijswi8g.cloudfront.net/become/homepage/img/news_logo_crowdfund_insider.svg?v=2.5" data-src="https://dav3uwijswi8g.cloudfront.net/become/homepage/img/news_logo_crowdfund_insider.svg?v=2.5" alt="SENEER Capital Crowd Fund Insider" />
                        <img class="lazyload-mobile" src="https://dav3uwijswi8g.cloudfront.net/become/homepage/img/news_logo_debanked.svg?v=2.5" data-src="https://dav3uwijswi8g.cloudfront.net/become/homepage/img/news_logo_debanked.svg?v=2.5" alt="SENEER Capital DeBanked" />
                    </div>
                    <div className="ApplicationsSeneer">
                        <h1>SENEER Capital</h1>
                        <div className="ApplicationsSeneerBox">
                            <img src={mail} alt="" />
                            <a href="mailto:info@seneercapital.com">info@seneercapital.com</a>
                        </div>
                    </div>
                    <div className="ApplicationsWar">
                        <div className="ApplicationsWarSub">
                            <img src={unlock} alt="" />
                            <h2>- This will NOT affect your credit score</h2>
                            <h3>- We keep your data safe and will not share your information without your approval</h3>
                        </div>
                    </div>
                    <div className="ApplicationsFooter">
                        <h3>Depending on your business's location and other characteristics, your business loan could be provided by one of the companies within the SENEER family and a member of the FDIC. The lender will be clearly specified in your loan agreement before you sign it. All loans are subject to approval by the lender.</h3>
                        <h4>SENEER is a registered trademark. All rights reserved.</h4>
                    </div>

                </div>

            </div>


            {/* Applications Toturial  */}
            {expleation ?
                <div className="ApplicationsToturial">
                    <div className="ApplicationsToturialShadow" onClick={() => setExpleation(false)}></div>
                    <div className="ApplicationsToturialMain">
                        <div className="ApplicationsToturialMainImgClose">
                            <img src={Close} alt="" onClick={() => setExpleation(false)} />
                        </div>
                        <div className="ApplicationsToturialMainHeader">
                            <h3>You Are Secure</h3>
                            <h4>We prioritize your financial security and protect you with encryption, identity verification, and the most widely secure payment processor.</h4>
                        </div>
                        <div className="ApplicationsToturialMainBody">
                            <div className="ApplicationsToturialMainBodyBox">
                                <div className="ApplicationsToturialMainBodyBoxLeft">
                                    <img src={appsearch} alt="" />
                                </div>
                                <div className="ApplicationsToturialMainBodyBoxRight">
                                    <h3>Soft Credit Check</h3>
                                    <h4>
                                        A soft inquiry allows SENEER Capital to review your credit to get a sense of how much fund your business will qualified for.
                                    </h4>
                                    <h5>This will NOT affect your credit score.</h5>
                                </div>
                            </div>
                            <div className="ApplicationsToturialMainBodyBox">
                                <div className="ApplicationsToturialMainBodyBoxLeft">
                                    <img src={appmoney} alt="" />
                                </div>
                                <div className="ApplicationsToturialMainBodyBoxRight">
                                    <h3>Your Data, Encrypted</h3>
                                    <h4>We protect your personal and financial data with bank level encryption.</h4>
                                </div>
                            </div>
                            <div className="ApplicationsToturialMainBodyBox">
                                <div className="ApplicationsToturialMainBodyBoxLeft">
                                    <img src={appwallet} alt="" />
                                </div>
                                <div className="ApplicationsToturialMainBodyBoxRight">
                                    <h3>3 Minute Application</h3>
                                    <h4>Our application form is easy to fill out and can be completed in less than 3 minutes!</h4>
                                </div>
                            </div>
                            <div className="ApplicationsToturialMainBodyBox">
                                <div className="ApplicationsToturialMainBodyBoxLeft">
                                    <img src={appcalendar} alt="" />
                                </div>
                                <div className="ApplicationsToturialMainBodyBoxRight">
                                    <h3>Get Fund Today!</h3>
                                    <h4>Once you've accepted the offer, we'll promptly send you a contract that outlines the terms and conditions of the agreement.</h4>
                                </div>
                            </div>
                        </div>
                        <div className="ApplicationsToturialMainBodyClose" onClick={() => setExpleation(false)}>
                            <h1>Dismiss</h1>
                        </div>
                    </div>
                </div>
                : null}

            {/* GetFund Button */}
            {scrollButton ?
                <div className="GetFundButton">
                    <button onClick={() => {
                        setExpleation(false)
                        window.scrollTo(0, 0)
                    }}>Apply Now</button>
                </div>
                : null}
        </div>
    )
}


