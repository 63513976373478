import React from "react";
import './SBALoan.scss';
import $ from 'jquery';
import { useState } from "react";
import { Helmet } from "react-helmet";

// images
import Review from '../../Images/Review.png'
import next from '../../Images/next.png'
import blog from '../../Images/blog2.jpg'
import blog3 from '../../Images/blog9.jpg'
import blog2 from '../../Images/blog10.jpg'
import MainPic from '../../Images/MainPic.jpg'

import clock from '../../Images/clock.png'
import computer from '../../Images/computer.png'
import books from '../../Images/books.png'
import { useNavigate } from "react-router-dom";

export default function SBALoan({  FaqList, Blogs }) {
    const navigate = useNavigate()

    function readingTime(S) {
        const text = S.Text;
        const wpm = 225;
        const words = text.trim().split(/\s+/).length;
        const time = Math.ceil(words / wpm);
        return time
    }

    const getSpecificNumberFaq = () => {
        const number = Math.random() * FaqList.length
        if (number <= (FaqList.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumberFaq()
        }
    }
    const [getNumberFaq, setgetNumberFaq] = useState(getSpecificNumberFaq());
    const getSpecificNumberBlog = () => {
        const number = Math.random() * Blogs.length
        if (number <= (Blogs.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumberBlog()
        }
    }
    const [getNumberBlog, setgetNumberBlog] = useState(getSpecificNumberBlog());


    return (
        <div className="SBALoan">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Apply Online for an Small Business Loan Loan | SENEER</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Apply Online for an Small Business Loan Loan | SENEER" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Apply Online for an Small Business Loan Loan | SENEER" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="website" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="website" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.seneercapital.com/sba-loan" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Apply online for an SBA loan and access flexible financing with attractive rates. Discover the benefits of Small Business Administration loans. Get started with your application today." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Apply online for an SBA loan and access flexible financing with attractive rates. Discover the benefits of Small Business Administration loans. Get started with your application today." data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Apply Online for an Small Business Loan Loan | SENEER" data-react-helmet="true" />
                <meta name="keywords" content="SBA Loan, Small Business Administration Loan, Government-Guaranteed Loan, SBA 7(a) Loan, SBA 504 Loan, SBA Loan Eligibility, SBA Loan Requirements, SBA Loan Rates, SBA Loan Application, SBA Loan Process" data-react-helmet="true" />
            </Helmet>
            {/* SBALoanMainBanner */}
            <div className="SBALoanMainBanner">
                <div className="SBALoanMainBannerLeft">
                    <h1>Apply Online for an Small Business Loan</h1>
                    <h2>Embrace the flexibility of SBA loans with manageable monthly payments and substantial capital injections to fortify your cash flow.</h2>
                    <button onClick={() => navigate('/apply')}>Apply Now</button>
                </div>
                <div className="SBALoanMainBannerRight">
                    <img src={blog} alt="SBA Loan, Small Business Administration Loan, Government-Guaranteed Loan, SBA 7(a) Loan, SBA 504 Loan, SBA Loan Eligibility, SBA Loan Requirements, SBA Loan Rates, SBA Loan Application, SBA Loan Process" />
                    <div className="SBALoanMainBannerRightBubble1"></div>
                    <div className="SBALoanMainBannerRightBubble2"></div>
                </div>
            </div>
            <div className="SBALoanMainBanner2">
                <div className="SBALoanMainBanner2Top">
                    <div className="SBALoanMainBanner2TopBox">
                        <h2>SBA Loans Available Up To</h2>
                        <h3>$5,000,000</h3>
                    </div>
                    <div className="SBALoanMainBanner2TopBox">
                        <h2>With Attractive Rates Starting As Low As</h2>
                        <h3>2.25%</h3>
                    </div>
                    <div className="SBALoanMainBanner2TopBox">
                        <h2>Enjoy SBA Loan Terms Extending Up To</h2>
                        <h3>30 Years</h3>
                    </div>
                </div>
                {/* Title */}
                <h1>LET'S DELVE INTO THE DETAILS</h1>
                <div className="SBALoanMainBanner2Bottom">
                    <div className="SBALoanMainBanner2BottomBox">
                        <img src={Review} alt="SBA Loan, Small Business Administration Loan, Government-Guaranteed Loan, SBA 7(a) Loan, SBA 504 Loan, SBA Loan Eligibility, SBA Loan Requirements, SBA Loan Rates, SBA Loan Application, SBA Loan Process" />
                        <h2>Understanding SBA Loans:</h2>
                        <h3>SBA loans serve as a pivotal resource for entrepreneurs across the USA, offering opportunities to establish, nurture, and expand thriving businesses. These loans represent a form of conventional financing, rigorously regulated and partially guaranteed by the U.S. Small Business Administration. They are disbursed through accredited lenders, such as SENEER Capital, and come with capped interest rates and fees. SBA loans are exclusively available to small business owners, endorsed by the Small Business Administration.</h3>
                        <button onClick={() => navigate('/apply')}>Learn More</button>
                    </div>
                    <div className="SBALoanMainBanner2BottomBox">
                        <img src={Review} alt="SBA Loan, Small Business Administration Loan, Government-Guaranteed Loan, SBA 7(a) Loan, SBA 504 Loan, SBA Loan Eligibility, SBA Loan Requirements, SBA Loan Rates, SBA Loan Application, SBA Loan Process" />
                        <h2>Small Business Loan Program:</h2>
                        <h3>If you're unsure about which SBA loan program suits your needs, SENEER Capital Services is here to assist. Consult with one of our specialists who will evaluate your business requirements, elucidate your options, and guide you toward the most fitting loan program.</h3>
                        <button onClick={() => navigate('/apply')}>Learn More</button>
                    </div>
                </div>
            </div>
            <div className="SBALoanMainBanner4">
                <div className="SBALoanMainBanner4Left">
                    <img src={blog2} alt="SBA Loan, Small Business Administration Loan, Government-Guaranteed Loan, SBA 7(a) Loan, SBA 504 Loan, SBA Loan Eligibility, SBA Loan Requirements, SBA Loan Rates, SBA Loan Application, SBA Loan Process" />
                    <div className="SBALoanMainBanner4LeftBubble1"></div>
                    <div className="SBALoanMainBanner4LeftBubble2"></div>
                </div>
                {/*  */}
                <div className="SBALoanMainBanner4Right">
                    <h1>Exploring the 7a SBA Loan Program:</h1>
                    <h2>The 7a loan program stands out as a prime choice for businesses seeking working capital or launching their enterprises. It is the most popular SBA loan program, designed as a versatile small business financing option with broad applications.</h2>
                    <div className="SBALoanMainBanner4RightBenefits">
                        <div className="SBALoanMainBanner4RightBenefitsBox">
                            <h3>Most Affordable 7a Loan Interest Rates</h3>
                        </div>
                        <div className="SBALoanMainBanner4RightBenefitsBox">
                            <h3>Interest Rates Guaranteed by the Small Business Administration</h3>
                        </div>
                        <div className="SBALoanMainBanner4RightBenefitsBox">
                            <h3>Unsecured Loan Provider</h3>
                        </div>
                        <div className="SBALoanMainBanner4RightBenefitsBox">
                            <h3>A Top Financing Solution</h3>
                        </div>
                    </div>
                </div>
                {/*  */}
            </div>
            <div className="SBALoanMainBanner5">
                <div className="SBALoanMainBanner5Left">
                    <h1>SENEER Capital Services is here to assist</h1>
                    <div className="SBALoanMainBanner5LeftBox">
                        <h2>1</h2>
                        <div className="SBALoanMainBanner5LeftBoxRigth">
                            <h3>Simplified SBA Loan Access:</h3>
                            <h4>Experience the convenience of having funds readily available for any business expenditure. Access your funds with a simple click.</h4>
                        </div>
                    </div>
                    <div className="SBALoanMainBanner5LeftBox">
                        <h2>2</h2>
                        <div className="SBALoanMainBanner5LeftBoxRigth">
                            <h3>Access a Revolving Line of Credit:</h3>
                            <h4>Utilize as much or as little as needed from your available credit. Your credit line replenishes as you make repayments.</h4>
                        </div>
                    </div>
                    <div className="SBALoanMainBanner5LeftBox">
                        <h2>3</h2>
                        <div className="SBALoanMainBanner5LeftBoxRigth">
                            <h3>Pay Only for What You Utilize:</h3>
                            <h4>Benefit from no opening fees, maintenance charges, prepayment penalties, or account closure fees.</h4>
                        </div>
                    </div>
                    <div className="SBALoanMainBanner5LeftBox">
                        <h2>4</h2>
                        <div className="SBALoanMainBanner5LeftBoxRigth">
                            <h3>Financing Tailored to Your Growth:</h3>
                            <h4>We foster your business expansion by aligning your credit line with your business size at every stage.</h4>
                        </div>
                    </div>
                    <div className="SBALoanMainBanner5LeftBox">
                        <h2>5</h2>
                        <div className="SBALoanMainBanner5LeftBoxRigth">
                            <h3>Qualify for an SBA Loan:</h3>
                            <h4>While traditional SBA loans are more accessible than typical bank loans, they still involve more paperwork and a lengthier funding process compared to direct online lenders. However, due to the economic impact of COVID-19, some Small Business Administration loans have relaxed their requirements to assist a wider range of small business owners.</h4>
                        </div>
                    </div>
                    <button onClick={() => navigate('/apply')}>Apply Now</button>
                </div>
                <div className="SBALoanMainBanner5Right">
                    <img src={blog2} alt="SBA Loan, Small Business Administration Loan, Government-Guaranteed Loan, SBA 7(a) Loan, SBA 504 Loan, SBA Loan Eligibility, SBA Loan Requirements, SBA Loan Rates, SBA Loan Application, SBA Loan Process" />
                    <div className="SBALoanMainBanner5RightBubble1"></div>
                    <div className="SBALoanMainBanner5RightBubble2"></div>
                </div>

            </div>
            <div className="SBALoanMainBanner6">
                <h1>Uncovering the 504/CDC SBA Loan Program:</h1>
                <div className="SBALoanMainBanner6List">
                    <div className="SBALoanMainBanner6TopBox">
                        <h2>SBA Loan Program</h2>
                        <h3>Widely Preferred SBA Loan Program</h3>
                    </div>
                    <div className="SBALoanMainBanner6TopBox">
                        <h2>Requirements</h2>
                        <h3>Low Payment Requirements</h3>
                    </div>
                    <div className="SBALoanMainBanner6TopBox">
                        <h2>Interest</h2>
                        <h3>Fixed Interest Rates</h3>
                    </div>
                    <div className="SBALoanMainBanner6TopBox">
                        <h2>Extended</h2>
                        <h3>Extended Payment Durations</h3>
                    </div>
                </div>
            </div>
            <div className="SBALoanMainBanner3">
                <h1>Prerequisites for Getting Started:</h1>
                <div className="SBALoanMainBanner3List">
                    <div className="SBALoanMainBanner3Box">
                        <div className="SBALoanMainBanner3BoxIMG">
                            <img src={computer} alt="No Hard Credit Pulls" />
                        </div>
                        <h3>Minimum Qualifications</h3>
                        <h4>A FICO score of 600 or higher</h4>
                    </div>
                    <div className="SBALoanMainBanner3Box">
                        <div className="SBALoanMainBanner3BoxIMG">
                            <img src={clock} alt="Pay Only for What You Borrow" />
                        </div>
                        <h3>At least</h3>
                        <h4>3 years in business</h4>
                    </div>
                    <div className="SBALoanMainBanner3Box">
                        <div className="SBALoanMainBanner3BoxIMG">
                            <img src={books} alt="Build Business Credit History" />
                        </div>
                        <h3>A monthly revenue of</h3>
                        <h4>$55,000 or more</h4>
                    </div>
                </div>

            </div>
            <div className="BlogsListFaq">
                <div className="BlogsListFaqHead">
                    <h1>Frequently asked questions</h1>
                    <a href="/faq">View All</a>
                </div>
                <div className="BlogsListFaqList">
                    {FaqList.slice(getNumberFaq, (getNumberFaq + 5)).map((A, index) =>
                        <article key={index} className="FaqListBox">
                            <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                <h1>{A.question}</h1>
                                {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                <h3><span> {A.category}</span></h3>
                            </a>
                        </article>

                    )}
                </div>
            </div>
            <div className="BlogsListReview">
                <div className="BlogsListReviewHead">
                    <h1>Related Articles</h1>
                    <a href="/blog">View All</a>
                </div>
                <div className="BlogsListReviewList">
                    {Blogs.slice(getNumberBlog, (getNumberBlog + 3)).map((A, index) =>

                        <a href={`/blog/${A.BlogPath.replace(/ /g, "_") + "_" + (A.BlogId - 1)}`} key={index} className="BlogsListReviewBox" >
                            <article>
                                <div className="BlogsListReviewBoxLeft">
                                    <img src={A.Photos} alt={A.BlogPath} />
                                </div>
                                <div className="BlogsListReviewBoxRight">
                                    <h1>{A.Title}</h1>
                                    <span>
                                        <h5>{readingTime(A)} MIN READ</h5>
                                        <h4>{A.Date}</h4>
                                    </span>
                                </div>
                            </article>
                        </a>
                    )}
                </div>
            </div>
            <div className="SBALoanMainBanner7">
                <div className="SBALoanMainBanner7Left">
                    <h1>Streamlined Access to Fast Working Capital:</h1>
                    <h2>Traditional bank lenders are not renowned for their speedy loan application processing, resulting in delays for borrowers in need of funds. This is where SENEER Capital Services steps in, offering an online platform to expedite your fund approval, making it available when you need it, often on the same day.</h2>
                    <a href="/apply">Apply Now</a>
                </div>
                <div className="SBALoanMainBanner7Right">
                    <img src={blog3} alt="SBA Loan, Small Business Administration Loan, Government-Guaranteed Loan, SBA 7(a) Loan, SBA 504 Loan, SBA Loan Eligibility, SBA Loan Requirements, SBA Loan Rates, SBA Loan Application, SBA Loan Process" />
                    <div className="SBALoanMainBanner7RightBubble1"></div>
                    <div className="SBALoanMainBanner7RightBubble2"></div>
                </div>
            </div>
        </div>
    )
}

