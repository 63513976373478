import React, { useState } from 'react'
import emailjs from 'emailjs-com';
import './Contact.scss'
import { Helmet } from "react-helmet";
import blog4 from '../../Images/blog4.jpg'
import downArrow from '../../Images/downArrow.png'
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"
import MainPic from '../../Images/MainPic.jpg'
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser'
import contactusbg from '../../Images/contactusbg.png'
import appmoney from '../../Images/appmoney.png'
import appcalendar from '../../Images/appcalendar.png'
import appdropshipping from '../../Images/appdropshipping.png'
import appsearch from '../../Images/appsearch.png'
import appwallet from '../../Images/appwallet.png'

export default function Contact() {
    const navigate = useNavigate()
    const [faq, setFaq] = useState([
        {
            question: "Do SENEER Capital conduct a hard or soft credit inquiry?",
            answer: `
            <p>
            When assessing eligibility, SENEER Capital conducts a soft credit check on the personal credit of the business owner.
            </p >
            `
        },
        {
            question: "rewrite What information do I need to apply?",
            answer: `
            <p>
            Usually, we only need fundamental information about you and your business. We will request:
            </br>
            </br>
            1. Business Tax ID.
            </br>
            </br>
            2. The last three months of business bank statements.
            </br>
            </br>
            3. Social Security number of the business owner(s).
            </p>
            `
        },
        {
            question: "What loan amounts and durations does SENEER Capital offer?",
            answer: `
            <p>
            Our term loans span from $5,000 to $250,000, with terms ranging from 3 to 24 months.
            </br>
            </br>
            Our lines of credit can reach up to $100,000, and they operate on a revolving basis, meaning as you reduce your balance, your available funds grow.
            </p>
            `
        },
        {
            question: "Which sectors does SENEER Capital cater to?",
            answer: `
            <p>
            SENEER Capital collaborates with over 700 diverse business types, including restaurants, auto repair shops, healthcare professionals, contractors, and various other service providers.
            </br>
            </br>
            However, we do not provide services to these excluded industries.
            </p>
            `
        },
    ]);
    const [faqShow, setFaqShow] = useState({ index: 0, valid: true });

    function sendEmail(e) {
        // setFormSubmiting(true)
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

        emailjs.sendForm('service_fqud6um', 'template_vdkxpnd', e.target, 'QeqW-bXHRNjRmhZ6I')
            .then((result) => {
                // setPage1(false)
                // setPage2(false)
                // setPage3(true)
                // setFormSubmiting(false)
                // navigate('/thankpage')
                // window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
            }, (error) => {
                console.log(error.text);
            });
    }

    return (
        <div className="Contact">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>SENEER | Contact Us</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="SENEER | Contact Us" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="SENEER | Contact Us" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.seneercapital.com/contact" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Contact us for swift access to business funding solutions tailored to your business needs. Your inquiries and feedback are valuable to us." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Contact us for swift access to business funding solutions tailored to your business needs. Your inquiries and feedback are valuable to us." data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="SENEER | Contact Us" data-react-helmet="true" />
                <meta name="keywords" content="Financing, Business, business funding, Advance, Funding, Cash, Merchant, Capital, Loan, Credit, Repayment, Blog" data-react-helmet="true" />
            </Helmet>
            <div className="MainBanner">
                <div className="MainBannerLeft">
                    <h1>Get help from one of our loan advisors.</h1>
                    <h2>Monday - Friday 9 a.m. - 7:30 p.m. ET</h2>
                </div>
                <div className="MainBannerRight">
                    <img src={contactusbg} alt="seneer capital contact us" />
                    <div className="MainBannerRightBubble1"></div>
                    <div className="MainBannerRightBubble2"></div>
                </div>
            </div>
            <div className="MainBanner2Top2">
                <div className="MainBanner2TopBox">
                    <h2>Here is how we can help you today.</h2>
                    <div className="MainBanner2TopBoxMenu">
                        <div className="MainBanner2TopBoxBox">
                            <h3>Help with a new loan.</h3>
                            <a target='_blank' href="mailto:info@seneercapital.com">info@seneercapital.com</a>
                            <a target='_blank' href="tel:+1 (313) 710-9766">+1 (313) 710-9766</a>
                        </div>
                        <div className="MainBanner2TopBoxBox">
                            <h3>Mailing address.</h3>
                            <a>17070 Collins Ave #120 Sunny Isles Beach, FL 33160</a>
                        </div>
                        <div className="MainBanner2TopBoxBox">
                            <h3>Partner with us.</h3>
                            <a target='_blank' href="mailto:admin@seneercapital.com">admin@seneercapital.com</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ContactFaq">
                <h1>Speedy answers to frequently posed inquiries.</h1>
                <div className="ContactFaqList">
                    {faq.map((A, index) =>
                        <div key={index} className='ContactFaqListBox'>
                            <div className="ContactFaqListBoxQuestion" onClick={() => setFaqShow({ index: index, valid: a => !a })}>
                                <h2>{A.question}</h2>
                                <img src={downArrow} alt="" />
                            </div>
                            {faqShow.index == index && faqShow.valid ?
                                <div className={`ContactFaqListBoxAnswer`} >
                                    {/* <div className={`ContactFaqListBoxAnswer${index}`} style={{ "display": "none" }}> */}
                                    {console.log(index)}
                                    {parse(A.answer)}
                                </div>
                                : null}
                        </div>
                    )}

                </div>
            </div>
            <div className="ContactBanner2">
                <h2>Do you have another question?</h2>
                <a href='/faq'>Please take a look at our Frequently Asked Questions <u> (FAQ) page.</u> </a>
            </div>
            <div className="ContactFirst">
                <div className="ContactFirstHeader">
                    <h1>Send us an email</h1>
                </div>
                <div className="ContactFirstBody">
                    <div className='ContactTop'>
                        <img src={blog4} alt="" />
                        <div className="ContactTopText">
                            <h1>Contact us</h1>
                            <h3>Please share your contact information and let us know your request, inquiry, or suggestion below.</h3>
                        </div>
                    </div>
                    <form className="contact-form" onSubmit={sendEmail}>
                        <h4>Please feel free to send us an email!</h4>
                        <h5>If you require assistance with applying for a new loan or have inquiries regarding an existing loan, please don't hesitate to send us an email. A member of our loan advisory team will be delighted to assist you!</h5>
                        <h2>Full Name</h2>
                        <input type="text" name="Full_Name" required />
                        <h2>Email</h2>
                        <input type="email" name="Email" required />
                        <div className='ContactFirsthhh'>
                            <div className="ContactFirsthhhBox">
                                <h2>Phone</h2>
                                <input type="tel" name="Phone" required />
                            </div>
                            <div className="ContactFirsthhhBox">
                                <h2>State</h2>
                                <input type="text" name="Country" required />
                            </div>
                        </div>
                        <h2>Massage</h2>
                        <textarea className="Message" name="Message" id="" cols="30" rows="10" required></textarea>
                        <input type="submit" value="SEND" />

                    </form>
                </div>
            </div>
            <NavigatorPath />

        </div>
    )
}

