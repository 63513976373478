import React, { useEffect, useState } from 'react'
import './PortalApps.scss'
import { Helmet } from "react-helmet";
import { useLocation } from 'react-router-dom';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend
)
export default function PortalApps({ ApplicationsData, SellersData, PortalData, Datas, navigateTo }) {
    const location = useLocation()
    const [TopPortalApps, setTopPortalApps] = useState([]);

    useEffect(() => {

        // console.log(new Date())
        // console.log(navigator)
    }, []);

    return (
        <div className="PortalApps">
            <Helmet>
                {/* <!-- TITLE --> */}
                <title>Privacy Policy | The Bump Vape</title>
                {/* <!-- META --> */}
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
                <meta property="og:image" content="article" />
                <meta property="og:url" content="https://www.thebumpvape.com/PortalApps" />
                <meta name="description" content="Protecting your privacy while using our website, product, and software is of utmost importance to us, especially when it involves conducting business or sharing sensitive personal information. This Privacy Notice serves as the governing document for our Privacy Policy, applying to all PortalApps of our website, product, and software." />
                <meta property="og:title" content="Privacy Policy | The Bump Vape" />
                <meta property="og:description" content="Protecting your privacy while using our website, product, and software is of utmost importance to us, especially when it involves conducting business or sharing sensitive personal information. This Privacy Notice serves as the governing document for our Privacy Policy, applying to all PortalApps of our website, product, and software." />
                <meta property="og:site_name" content="Privacy Policy | The Bump Vape" />
                <meta name="keywords" content="BUMP, electronic cigarette, e-cigarette, vaping product, vapes, vape bump, bump Privacy Policy" ></meta>
            </Helmet>
            <div className="PortalAppsDatasMain">
                <div className="PortalAppsData">
                    <table className='PortalAppsTable'>
                        <thead>
                            <tr>
                                {/* Business Info */}
                                <th>Submission Date</th>
                                <th>Legal/Business Name</th>
                                <th>DBA</th>
                                <th>What type of business do you own?</th>
                                <th>What industry best matches your business?</th>
                                <th>Business Address</th>
                                <th>Business Tax ID</th>
                                <th>Business Phone Number</th>
                                <th>Date Business Started</th>
                                <th>Email</th>
                                <th>Agree to our privacy policy</th>
                                <th>User Agent</th>
                                <th>Signature</th>
                                <th>How will you use these funds?</th>
                                <th>Product/Service Sold</th>
                                <th>Get Page URL</th>
                                <th>Corporate Officer/Owner Full Name</th>
                                <th>Title</th>
                                <th>Ownership %</th>
                                <th>Home Address</th>
                                <th>Social Security Number (SSN)</th>
                                <th>Date of Birth</th>
                                <th>Phone Number</th>
                                <th>What's your credit score?</th>
                                <th>Requested Funding Amount</th>
                                <th>What is your business monthly revenue?</th>
                                <th>Last 3 Month Business Bank Statements</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ApplicationsData.map((A, index) =>
                                <tr key={index} className='PortalAppsDatasBottomBox'>
                                    {/* Seller */}
                                    {/* Submission_Date */}
                                    <td>
                                        {A.Submission_Date}
                                    </td>
                                    {/* Legal__Business_Name */}
                                    <td>
                                        {A.Legal__Business_Name}
                                    </td>
                                    {/* DBA */}
                                    <td>
                                        {A.DBA}
                                    </td>
                                    {/* What_type_of_business_do_you_own */}
                                    <td>
                                        {A.What_type_of_business_do_you_own}
                                    </td>
                                    {/* What_industry_best_matches_your_business */}
                                    <td>
                                        {A.What_industry_best_matches_your_business}
                                    </td>
                                    {/* Business_Address */}
                                    <td>
                                        {A.Business_Address.Street_Address + ", " + A.Business_Address.City + ", " + A.Business_Address.State + ", " + A.Business_Address.Zipcode}
                                    </td>
                                    {/* Business_Tax_ID */}
                                    <td>
                                        {A.Business_Tax_ID}
                                    </td>
                                    {/* Business_Phone_Number */}
                                    <td>
                                        {A.Business_Phone_Number}
                                    </td>
                                    {/* Date_Business_Started */}
                                    <td>
                                        {A.Date_Business_Started}
                                    </td>
                                    {/* Email */}
                                    <td>
                                        {A.Email}
                                    </td>
                                    {/* Agree_to_our_privacy_policy */}
                                    <td>
                                        {A.Agree_to_our_privacy_policy}
                                    </td>
                                    {/* User_Agent_String */}
                                    <td>
                                        {A.User_Agent_String}
                                    </td>
                                    {/* Signature */}
                                    <td>
                                        {A.Signature}
                                    </td>
                                    {/* How_will_you_use_these_funds */}
                                    <td>
                                        {A.How_will_you_use_these_funds}
                                    </td>
                                    {/* Product__Service_Sold */}
                                    <td>
                                        {A.Product__Service_Sold}
                                    </td>
                                    {/* Get_Page_URL */}
                                    <td>
                                        {A.Get_Page_URL}
                                    </td>
                                    {/* Corporate_Officer__Owner_Full_Name */}
                                    <td>
                                        {A.Corporate_Officer__Owner_Full_Name}
                                    </td>
                                    {/* Title */}
                                    <td>
                                        {A.Title}
                                    </td>
                                    {/* Ownership */}
                                    <td>
                                        {A.Ownership}
                                    </td>
                                    {/* Home_Address */}
                                    <td>
                                        {A.Home_Address.Street_Address + ", " + A.Home_Address.City + ", " + A.Home_Address.State + ", " + A.Home_Address.Zipcode}
                                    </td>
                                    {/* Social_Security_Number_SSN */}
                                    <td>
                                        {A.Social_Security_Number_SSN}
                                    </td>
                                    {/* Date_of_Birth */}
                                    <td>
                                        {A.Date_of_Birth}
                                    </td>
                                    {/* Phone_Number */}
                                    <td>
                                        {A.Phone_Number}
                                    </td>
                                    {/* What_is_your_credit_score */}
                                    <td>
                                        {A.What_is_your_credit_score}
                                    </td>
                                    {/* Requested_Funding_Amount */}
                                    <td>
                                        {A.Requested_Funding_Amount}
                                    </td>
                                    {/* What_is_your_business_monthly_revenue */}
                                    <td>
                                        {A.What_is_your_business_monthly_revenue}
                                    </td>
                                    {/* {Last_3_Month_Business_Bank_Statements} */}
                                    <td>
                                        {/* {A.Last_3_Month_Business_Bank_Statements} */}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                <div className="PortalAppsWindow">
                    <div className="PortalAppsWindowHeader">

                    </div>

                    <div className="PortalAppsWindowFields">
                        {console.log(ApplicationsData[0])}
                        {Object.keys(ApplicationsData[0]).map((keyName, i) => (
                            <div className="PortalAppsWindowFieldsBox">
                                <h2>{keyName}</h2>
                                <h1>{ApplicationsData[0][keyName]}</h1>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

        </div>
    )
}































{/* <div className="PortalAppsTop">
                <h1>Top PortalApps</h1>
                <h2>{Datas.length}</h2>
                {SellersData.map((A, index) =>
                    <div key={index} className='PortalAppsTopBox'>
                        <h2>{A.name}</h2>
                        <h1>{Datas.filter(s => new URLSearchParams(s.link.substring(s.link.indexOf('?') + 0)).get("seller") == A.sellerId).length}</h1>
                    </div>
                )}
            </div> */}
{/* <div className="PortalAppsDatasHeader">
                <h2>Recent Applications <span>By PortalApps</span></h2>
                <h3>View All</h3>
            </div> */}