import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Helmet } from "react-helmet";
import './SiteMap.scss';

// import { useLocation } from "react-router-dom";
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"
import { useEffect } from 'react';


export default function SiteMap({ Blogs, FaqList, WebSitePages }) {
    const location = useLocation()
    const [SiteMapToArray, setSiteMapToArray] = useState([]);


    useEffect(() => {
        if (location.pathname.split('/')[2] == "website" || location.pathname.split('/')[1] == "sitemaps" || location.pathname.split('/').length <= 2 && location.pathname.split('/')[1] == "sitemap") {
            setSiteMapToArray(WebSitePages)
        } else if (location.pathname.split('/')[2] == "solutions") {
            setSiteMapToArray(WebSitePages)
        } else if (location.pathname.split('/')[2] == "blog") {
            setSiteMapToArray(Blogs)
        } else if (location.pathname.split('/')[2] == "faq") {
            setSiteMapToArray(FaqList)
        } else if (location.pathname.split('/')[2] == "socialmedia") {
            setSiteMapToArray(WebSitePages)
        }
    }, [location.pathname]);

    return (
        <div className="SiteMap">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>SENEER | Site Map</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="SENEER | Site Map" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="SENEER | Site Map" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.seneercapital.com/sitemap" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Navigate our comprehensive Seneer Capital sitemap for a structured overview of our business funding resources. Find your way to valuable information on funding solutions, financial strategies, and opportunities to elevate your business to new heights." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Navigate our comprehensive Seneer Capital sitemap for a structured overview of our business funding resources. Find your way to valuable information on funding solutions, financial strategies, and opportunities to elevate your business to new heights." data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="SENEER | Site Map" data-react-helmet="true" />
                <meta name="keywords" content="Financing, Business, Sitemap, Advance, Funding, Cash, Merchant, Capital, Loan, Credit, Repayment, Blog" data-react-helmet="true" />
            </Helmet>
            <div className="SiteMapMenu">
                <a style={location.pathname.split('/')[2] == "website" || location.pathname.split('/')[1] == "sitemaps" || location.pathname.split('/').length <= 2 && location.pathname.split('/')[1] == "sitemap" ? { "borderBottom": "1px solid #0f212e" } : null} href="/sitemap/website">Website</a>
                <a style={location.pathname.split('/')[2] == "aboutus" ? { "borderBottom": "1px solid #0f212e" } : null} href="/sitemap/aboutus">About Us</a>
                <a style={location.pathname.split('/')[2] == "solutions" ? { "borderBottom": "1px solid #0f212e" } : null} href="/sitemap/solutions">Business Solutions</a>
                <a style={location.pathname.split('/')[2] == "blog" ? { "borderBottom": "1px solid #0f212e" } : null} href="/sitemap/blog">Blog</a>
                <a style={location.pathname.split('/')[2] == "faq" ? { "borderBottom": "1px solid #0f212e" } : null} href="/sitemap/faq">Faq</a>
                <a style={location.pathname.split('/')[2] == "socialmedia" ? { "borderBottom": "1px solid #0f212e" } : null} href="/sitemap/socialmedia">Social Media</a>
            </div>
            <div className="SiteMapDes">
                {location.pathname.split('/')[2] == "website" || location.pathname.split('/')[1] == "sitemaps" || location.pathname.split('/').length <= 2 && location.pathname.split('/')[1] == "sitemap" ?
                    <div className='SiteMapDesBox'>
                        {SiteMapToArray.filter(a => a.Category == "Resources").map((A, index) =>
                            <a key={index} href={`${A.Path}`}>{A.Name}</a>
                        )}
                    </div>

                    : location.pathname.split('/')[2] == "solutions" ?
                        <div className='SiteMapDesBox'>
                            {/* <a href="/solutions">SENEER Capital Blog And Magazine</a> */}
                            {SiteMapToArray.filter(a => a.Category == "Sulotion").map((A, index) =>
                                <a key={index} href={`${A.Path}`}>{A.Name}</a>
                            )}
                        </div>
                        : location.pathname.split('/')[2] == "aboutus" ?
                            <div className='SiteMapDesBox'>
                                {WebSitePages.filter(a => a.Category == "About Us").map((A, index) =>
                                    <a key={index} href={`${A.Path}`}>{A.Name}</a>
                                )}
                                {WebSitePages.filter(a => a.Category == "About").map((A, index) =>
                                    <a key={index} href={`${A.Path}`}>{A.Name}</a>
                                )}
                            </div>
                            : location.pathname.split('/')[2] == "blog" ?
                                <div className='SiteMapDesBox'>
                                    <h1>Category</h1>
                                    <a href="/blog?all">All Blog</a>
                                    <a href="/blog?category=small-business-loan">Small Business Loan</a>
                                    <a href="/blog?category=term-loan">Term Loan</a>
                                    <a href="/blog?category=merchant-cash-advance">Merchant Cash Advance</a>
                                    <a href="/blog?category=working-capital">Working Capital</a>
                                    <a href="/blog?category=line-of-credit">Line of Credit</a>
                                    <a href="/blog?category=same-day-funding">Same Day Funding</a>
                                    <a href="/blog?category=business-line-of-credit">Business Line of Credit</a>
                                    <h1>Blogs</h1>
                                    {SiteMapToArray.reverse().map((A, index) =>
                                        <a key={index} href={`/blog/${A.BlogPath.replace(/ /g, "_") + "_" + (A.BlogId - 1)}`}>{A.BlogPath}</a>
                                    )}
                                </div>
                                : location.pathname.split('/')[2] == "faq" ?
                                    <div className='SiteMapDesBox'>
                                        <h1>Category</h1>
                                        <a href="/blog?all">All Faqs</a>
                                        <a href="/faq#small-business-loan">Small Business Loan</a>
                                        <a href="/faq#term-loan">Term Loan</a>
                                        <a href="/faq#merchant-cash-advance">Merchant Cash Advance</a>
                                        <a href="/faq#working-capital">Working Capital</a>
                                        <a href="/faq#line-of-credit">Line of Credit</a>
                                        <a href="/faq#same-day-funding">Same Day Funding</a>
                                        <a href="/faq#business-line-of-credit">Business Line of Credit</a>
                                        <h1>Faqs</h1>
                                        <a href="/faq">SENEER Questions & Answers</a>
                                        {SiteMapToArray.reverse().map((A, index) =>
                                            <a key={index} href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`}>{A.questionPath}</a>
                                        )}
                                    </div>
                                    : location.pathname.split('/')[2] == "socialmedia" ?
                                        <div className='SiteMapDesBox'>
                                            <a target='blank' href="https://www.facebook.com/profile.php?id=100094632027169">SENEER Capital Facebook</a>
                                            <a target='blank' href="https://www.instagram.com/seneercapital/">SENEER Capital Instagram</a>
                                            <a target='blank' href="https://www.linkedin.com/company/seneercapital">SENEER Capital Linkedin</a>
                                            <a target='blank' href="https://www.threads.net/@seneercapital">SENEER Capital Threads</a>
                                            <a target='blank' href="https://twitter.com/SeneerCapital">SENEER Capital Twitter</a>
                                        </div>
                                        : null
                }

            </div>
            <NavigatorPath />
        </div>
    )
}
