import React from "react";
import './BusinessLineofCredit.scss';
import $ from 'jquery';
import { useState } from "react";
import { Helmet } from "react-helmet";

// images
import Review from '../../Images/Review.png'
import next from '../../Images/next.png'
import blog from '../../Images/blog2.jpg'
import blog3 from '../../Images/blog3.jpg'
import blog2 from '../../Images/blog4.jpg'
import MainPic from '../../Images/MainPic.jpg'

import clock from '../../Images/clock.png'
import computer from '../../Images/computer.png'
import books from '../../Images/books.png'
import { useNavigate } from "react-router-dom";

export default function BusinessLineofCredit({ FaqList, Blogs }) {
    const navigate = useNavigate()
    const [showOne, setshowOne] = useState(true);
    const [showTwo, setshowTwo] = useState(false);
    const [showThree, setshowThree] = useState(false);

    function readingTime(S) {
        const text = S.Text;
        const wpm = 225;
        const words = text.trim().split(/\s+/).length;
        const time = Math.ceil(words / wpm);
        return time
    }

    const getSpecificNumberFaq = () => {
        const number = Math.random() * FaqList.length
        if (number <= (FaqList.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumberFaq()
        }
    }
    const [getNumberFaq, setgetNumberFaq] = useState(getSpecificNumberFaq());
    const getSpecificNumberBlog = () => {
        const number = Math.random() * Blogs.length
        if (number <= (Blogs.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumberBlog()
        }
    }
    const [getNumberBlog, setgetNumberBlog] = useState(getSpecificNumberBlog());

    return (
        <div className="BusinessLineofCredit">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Business Line of Credit: Your Flexible Financial Solution. | SENEER</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title"  property="title" content="Business Line of Credit: Your Flexible Financial Solution. | SENEER"  data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Business Line of Credit: Your Flexible Financial Solution. | SENEER"  data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US"  data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article"  data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article"  data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.seneercapital.com/business-line-of-credit"  data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Unlock the flexibility of a Business Line of Credit. Secure working capital with ease. Explore your financial possibilities today."  data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Unlock the flexibility of a Business Line of Credit. Secure working capital with ease. Explore your financial possibilities today."  data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Business Line of Credit: Your Flexible Financial Solution. | SENEER"  data-react-helmet="true" />
                <meta name="keywords" content="Business Line of Credit, Revolving Credit for Business, Small Business Credit Solutions, Flexible Business Financing, Credit Line Approval, Working Capital Loans, Business Credit Limit, Interest Rates for Credit Lines, Credit History for Businesses, Financial Stability with Credit" data-react-helmet="true" />
            </Helmet>
            {/* BusinessLineofCreditMainBanner */}
            <div className="BusinessLineofCreditMainBanner">
                <div className="BusinessLineofCreditMainBannerLeft">
                    <h1>Business Line of Credit: Your Flexible Financial Solution.</h1>
                    <h2>Experience the ease of accessing working capital when you need it most with our Business Line of Credit. <br /> Enjoy the freedom of securing lines of credit ranging from $5,000 to $250,000.</h2>
                    <button onClick={() => navigate('/apply')}>Apply Now</button>
                </div>
                <div className="BusinessLineofCreditMainBannerRight">
                    <img src={blog} alt="Business Line of Credit, Revolving Credit for Business, Small Business Credit Solutions, Flexible Business Financing, Credit Line Approval, Working Capital Loans, Business Credit Limit, Interest Rates for Credit Lines, Credit History for Businesses, Financial Stability with Credit" />
                    <div className="BusinessLineofCreditMainBannerRightBubble1"></div>
                    <div className="BusinessLineofCreditMainBannerRightBubble2"></div>
                </div>
            </div>
            <div className="BusinessLineofCreditMainBanner2">
                <div className="BusinessLineofCreditMainBanner2Top">
                    <div className="BusinessLineofCreditMainBanner2TopBox">
                        <h2>Revolving</h2>
                        <h3>Gain access to revolving lines of credit, spanning from $5,000 to $250,000.</h3>
                    </div>
                    <div className="BusinessLineofCreditMainBanner2TopBox">
                        <h2>Flexible</h2>
                        <h3>Choose from repayment terms of 12, 18, or 24 months to suit your business's needs.</h3>
                    </div>
                    <div className="BusinessLineofCreditMainBanner2TopBox">
                        <h2>Customizable</h2>
                        <h3>Tailor your payment schedule with options for weekly or monthly payments.</h3>
                    </div>
                </div>
                {/* Title */}
                <h1>Tailored funding solutions designed to meet your needs.</h1>
                <div className="BusinessLineofCreditMainBanner2Bottom">
                    <div className="BusinessLineofCreditMainBanner2BottomBox">
                        <img src={Review} alt="Business Line of Credit, Revolving Credit for Business, Small Business Credit Solutions, Flexible Business Financing, Credit Line Approval, Working Capital Loans, Business Credit Limit, Interest Rates for Credit Lines, Credit History for Businesses, Financial Stability with Credit" />
                        <h2>How Does It Work?</h2>
                        <h3>A Business Line of Credit offers you a predetermined credit limit, allowing you to borrow as required. You only incur interest and fees on the amount you draw from your credit limit. This financial tool is ideal for managing recurring expenses and bridging cash flow gaps, making it a perfect choice for many businesses. With SENEER Capital, your available funds are replenished as you repay borrowed amounts, creating a revolving business line of credit. Additionally, your payment history is reported to business credit bureaus, helping you build your business credit.</h3>
                        <button onClick={() => navigate('/apply')}>Learn More</button>
                    </div>
                </div>
            </div>

            <div className="BusinessLineofCreditMainBanner4">
                <div className="BusinessLineofCreditMainBanner4Left">
                    <img src={blog2} alt="Business Line of Credit, Revolving Credit for Business, Small Business Credit Solutions, Flexible Business Financing, Credit Line Approval, Working Capital Loans, Business Credit Limit, Interest Rates for Credit Lines, Credit History for Businesses, Financial Stability with Credit" />
                    <div className="BusinessLineofCreditMainBanner4LeftBubble1"></div>
                    <div className="BusinessLineofCreditMainBanner4LeftBubble2"></div>
                </div>
                {/*  */}
                <div className="BusinessLineofCreditMainBanner4Right">
                    <h1>Frequently Asked Questions - SENEER Capital Business Line of Credit</h1>
                    <h2>As a direct lender, we customize our financing to suit your specific requirements. Regardless of your objective, our in-house loan advisors are here to assist you in selecting a financing solution with no intermediaries or delays.</h2>
                    <div className="BusinessLineofCreditMainBanner4RightBenefits">
                        <div className="BusinessLineofCreditMainBanner4RightBenefitsBox">
                            
                            <h3>How Does the Payback Work?</h3>
                            <h4>With an SENEER Capital Line of Credit, your draws are consolidated into one loan, making weekly or monthly payments easy. As you pay back your principal, your available funds are replenished. Notably, there are no draw fees, and you can adjust the payment amount and term to ensure a comfortable payment schedule.</h4>
                        </div>
                        <div className="BusinessLineofCreditMainBanner4RightBenefitsBox">
                            
                            <h3>Can I Get a Credit Line Increase?</h3>
                            <h4>Depending on your cash flow, net income, and payback history, you may be eligible for a credit line increase. Simply contact your dedicated loan advisor to explore this option.</h4>
                        </div>
                        <div className="BusinessLineofCreditMainBanner4RightBenefitsBox">
                            
                            <h3>What Will It Cost?</h3>
                            <h4>The total cost of your line of credit varies based on factors such as your personal and business credit scores, time in business, annual revenue, and cash flow.</h4>
                        </div>
                        <div className="BusinessLineofCreditMainBanner4RightBenefitsBox">
                            
                            <h3>What Other Small Business Loans Are Available?</h3>
                            <h4>In addition to your Business Line of Credit, consider exploring our Term Loans, which are available to supplement your financial needs. Reach out to your dedicated loan advisor to explore additional options. We offer a variety of small business loans to cater to your unique funding requirements.</h4>
                        </div>
                    </div>
                </div>
                {/*  */}
            </div>
            <div className="BusinessLineofCreditMainBanner5">
                <div className="BusinessLineofCreditMainBanner5Left">
                    <h1>Experience Swift Business Funding</h1>
                    <div className="BusinessLineofCreditMainBanner5LeftBox">
                        <h2>1</h2>
                        <div className="BusinessLineofCreditMainBanner5LeftBoxRigth">
                            <h3>Complete the Application</h3>
                            <h4>Our streamlined process can be finished in just minutes.</h4>
                        </div>
                    </div>
                    <div className="BusinessLineofCreditMainBanner5LeftBox">
                        <h2>2</h2>
                        <div className="BusinessLineofCreditMainBanner5LeftBoxRigth">
                            <h3>Get a Decision.</h3>
                            <h4>Collaborate with an expert loan advisor to select the best option for your business.</h4>
                        </div>
                    </div>
                    <div className="BusinessLineofCreditMainBanner5LeftBox">
                        <h2>3</h2>
                        <div className="BusinessLineofCreditMainBanner5LeftBoxRigth">
                            <h3>Receive Your Funds.</h3>
                            <h4>Sign your contract and access your funds as soon as the same day.</h4>
                        </div>
                    </div>
                    <button onClick={() => navigate('/apply')}>Apply Now</button>
                </div>
                <div className="BusinessLineofCreditMainBanner5Right">
                    <img src={blog2} alt="Business Line of Credit, Revolving Credit for Business, Small Business Credit Solutions, Flexible Business Financing, Credit Line Approval, Working Capital Loans, Business Credit Limit, Interest Rates for Credit Lines, Credit History for Businesses, Financial Stability with Credit" />
                    <div className="BusinessLineofCreditMainBanner5RightBubble1"></div>
                    <div className="BusinessLineofCreditMainBanner5RightBubble2"></div>
                </div>

            </div>
            
            <div className="BusinessLineofCreditMainBanner6">
                <h1>Are You Eligible?<br /> Check Our Business Line of Credit Requirements</h1>
                <div className="BusinessLineofCreditMainBanner6List">
                    <div className="BusinessLineofCreditMainBanner6TopBox">
                        <h2>1 Year</h2>
                        <h3>At least 1 year in business</h3>
                    </div>
                    <div className="BusinessLineofCreditMainBanner6TopBox">
                        <h2>FICO® Score</h2>
                        <h3>Personal FICO® score of 600 or higher</h3>
                    </div>
                    <div className="BusinessLineofCreditMainBanner6TopBox">
                        <h2>Annual Revenue</h2>
                        <h3>Business annual revenue of $100,000</h3>
                    </div>
                </div>
            </div>
            <div className="BusinessLineofCreditMainBanner3">
                <h1>Benefits of SENEER Capital Business Line of Credit:</h1>
                <div className="BusinessLineofCreditMainBanner3List">
                    <div className="BusinessLineofCreditMainBanner3Box">
                        <div className="BusinessLineofCreditMainBanner3BoxIMG">
                            <img src={computer} alt="No Hard Credit Pulls" />
                        </div>
                        <h3>No Hard Credit Pulls</h3>
                        <h4>Check your eligibility without affecting your credit score.</h4>
                    </div>
                    <div className="BusinessLineofCreditMainBanner3Box">
                        <div className="BusinessLineofCreditMainBanner3BoxIMG">
                            <img src={clock} alt="Pay Only for What You Borrow" />
                        </div>
                        <h3>Pay Only for What You Borrow</h3>
                        <h4>Withdraw funds when you need them, and you'll only pay interest on the funds you use.</h4>
                    </div>
                    <div className="BusinessLineofCreditMainBanner3Box">
                        <div className="BusinessLineofCreditMainBanner3BoxIMG">
                            <img src={books} alt="Build Business Credit History" />
                        </div>
                        <h3>Build Business Credit History</h3>
                        <h4>Your payment history is reported to business credit bureaus, contributing to the growth of your business credit.</h4>
                    </div>
                </div>

            </div>
            <div className="BlogsListFaq">
                <div className="BlogsListFaqHead">
                    <h1>Frequently asked questions</h1>
                    <a href="/faq">View All</a>
                </div>
                <div className="BlogsListFaqList">
                    {FaqList.slice(getNumberFaq, (getNumberFaq + 5)).map((A, index) =>
                        <article key={index} className="FaqListBox">
                            <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                <h1>{A.question}</h1>
                                {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                <h3><span> {A.category}</span></h3>
                            </a>
                        </article>

                    )}
                </div>
            </div>
            <div className="BlogsListReview">
                <div className="BlogsListReviewHead">
                    <h1>Related Articles</h1>
                    <a href="/blog">View All</a>
                </div>
                <div className="BlogsListReviewList">
                    {Blogs.slice(getNumberBlog, (getNumberBlog + 3)).map((A, index) =>

                        <a href={`/blog/${A.BlogPath.replace(/ /g, "_") + "_" + (A.BlogId - 1)}`} key={index} className="BlogsListReviewBox" >
                            <article>
                                <div className="BlogsListReviewBoxLeft">
                                    <img src={A.Photos} alt={A.BlogPath} />
                                </div>
                                <div className="BlogsListReviewBoxRight">
                                    <h1>{A.Title}</h1>
                                    <span>
                                        <h5>{readingTime(A)} MIN READ</h5>
                                        <h4>{A.Date}</h4>
                                    </span>
                                </div>
                            </article>
                        </a>
                    )}
                </div>
            </div>
            <div className="BusinessLineofCreditMainBanner7">
                <div className="BusinessLineofCreditMainBanner7Left">
                    <h1>Take the Risk-Free Step to Qualify Now!</h1>
                    <h2>Discover the ideal financing solution for your business, whether you're embarking on a new venture or scaling up. Our team of seasoned advisors is here to guide you in accordance with your individual business requirements.</h2>
                    <a href="/apply">Apply Now</a>
                </div>
                <div className="BusinessLineofCreditMainBanner7Right">
                    <img src={blog3} alt="Business Line of Credit, Revolving Credit for Business, Small Business Credit Solutions, Flexible Business Financing, Credit Line Approval, Working Capital Loans, Business Credit Limit, Interest Rates for Credit Lines, Credit History for Businesses, Financial Stability with Credit" />
                    <div className="BusinessLineofCreditMainBanner7RightBubble1"></div>
                    <div className="BusinessLineofCreditMainBanner7RightBubble2"></div>
                </div>
            </div>
        </div>
    )
}

