import React, { useState } from 'react'
import { useEffect } from 'react';
import './Links.scss'
import { Helmet } from "react-helmet";

import $ from 'jquery';
// images
import next from '../../Images/next.png'
import Logo from '../../Images/Logo.png'
import blog1 from '../../Images/blog1.jpg'
import blog2 from '../../Images/blog2.jpg'
import blog3 from '../../Images/blog3.jpg'
import blog4 from '../../Images/blog4.jpg'
import blog5 from '../../Images/blog5.jpg'
import blog6 from '../../Images/blog6.jpg'
import blog7 from '../../Images/blog7.jpg'
import blog8 from '../../Images/blog8.jpg'

// socail
import facebook from '../../Images/facebook.png'
import instagram from '../../Images/instagram.png'
import linkedin from '../../Images/linkedin.png'
import twitter from '../../Images/twitter.png'
import threads from '../../Images/threads.png'

export default function Links({ navigateTo }) {

    return (
        <div className="Links">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Our Bio Links | SENEER</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Our Bio Links| SENEER" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Our Bio Links| SENEER" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.seneercapital.com/links" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Our Bio Links SENEER Capital Experience the power of our rapid cash advance, providing customized funding solutions to fuel your business's growth and financial prosperity. Start exploring opportunities today!" data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Experience the power of our rapid cash advance, providing customized funding solutions to fuel your business's growth and financial prosperity. Start exploring opportunities today!" data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Our Bio Links| SENEER" data-react-helmet="true" />
                <meta name="keywords" content="Financing, Business, Advance, Funding, Cash, Merchant, Capital, Loan, Credit, Repayment" data-react-helmet="true" />
            </Helmet>
            <div className="LinksTop"></div>
            <div className="LinksHeader">
                <img src={Logo} alt="" />
                <h1>SENEER Capital</h1>
                <h2>Shop multiple loan offers in minutes</h2>
                <div className="LinksHeaderMenu">
                    <a href='#LinksListSocialMain'>Main</a>
                    <a href='#LinksListSocial'>Social Media</a>
                    <a href='#LinksListSocialBusinessLoans'>Business Loans</a>
                </div>
            </div>
            <div className="LinksList">
                {/* <h2>Main</h2> */}
                <div id='LinksListSocialMain' className="LinksListBox">
                    <a target='_blank' href="/">
                        <img src={blog1} alt="SENEER Capital Main Website" />
                        <h3>Main Website</h3>
                    </a>
                    <span></span>
                </div>
                <div className="LinksListBox">
                    <a target='_blank' href="/apply">
                        <img src={blog2} alt="SENEER Capital Apply Online for business funding" />
                        <h3>Apply Online for business funding</h3>
                    </a>
                    <span></span>
                </div>
                <div className="LinksListBox">
                    <a target='_blank' href="/blog">
                        <img src={blog3} alt="SENEER Capital Blog" />
                        <h3>Blog</h3>
                    </a>
                    <span></span>
                </div>
                <div className="LinksListBox">
                    <a target='_blank' href="/faq">
                        <img src={blog4} alt="SENEER Capital Faq" />
                        <h3>Faq</h3>
                    </a>
                    <span></span>
                </div>
                {/* <h2>Social Media</h2> */}
                <div id='LinksListSocial' className="LinksListBox LinksListBoxsocial">
                    <a target='_blank' href="https://www.facebook.com/profile.php?id=100094632027169">
                        <img src={facebook} alt="SENEER Capital Facebook" />
                        <h3>Follow SENEER on Facebook</h3>
                    </a>
                    <span></span>
                </div>
                <div className="LinksListBox LinksListBoxsocial">
                    <a target='_blank' href="https://www.instagram.com/seneercapital/">
                        <img src={instagram} alt="SENEER Capital Instagram" />
                        <h3>Follow SENEER on Instagram</h3>
                    </a>
                    <span></span>
                </div>
                <div className="LinksListBox LinksListBoxsocial">
                    <a target='_blank' href="https://www.linkedin.com/company/seneercapital">
                        <img src={linkedin} alt="SENEER Capital Linkedin" />
                        <h3>Follow SENEER on Linkedin</h3>
                    </a>
                    <span></span>
                </div>
                <div className="LinksListBox LinksListBoxsocial">
                    <a target='_blank' href="https://www.threads.net/@seneercapital">
                        <img src={threads} alt="SENEER Capital Threads" />
                        <h3>Follow SENEER on Threads</h3>
                    </a>
                    <span></span>
                </div>
                <div className="LinksListBox LinksListBoxsocial">
                    <a target='_blank' href="https://twitter.com/SeneerCapital">
                        <img src={twitter} alt="SENEER Capital Twitter / X" />
                        <h3>Follow SENEER on Twitter / X</h3>
                    </a>
                    <span></span>
                </div>
                {/* <h2>Business Loans</h2> */}
                <div id='LinksListSocialBusinessLoans' className="LinksListBox">
                    <a target='_blank' href="/business-line-of-credit">
                        <img src={blog5} alt="SENEER Capital Business Line of Credit: Your Flexible Financial Solution." />
                        <h3>Business Line of Credit</h3>
                    </a>
                    <span></span>
                </div>
                <div className="LinksListBox">
                    <a target='_blank' href="/long-term-loan">
                        <img src={blog6} alt="SENEER Capital Business Term Loan: Empower Your Business with Swift Funding" />
                        <h3>Business Term Loan</h3>
                    </a>
                    <span></span>
                </div>
                <div className="LinksListBox">
                    <a target='_blank' href="/sba-loan">
                        <img src={blog7} alt="SENEER Capital Apply Online for an SBA Loan" />
                        <h3>Small Business Loan</h3>
                    </a>
                    <span></span>
                </div>
                <div className="LinksListBox">
                    <a target='_blank' href="/same-day-funding">
                        <img src={blog8} alt="SENEER Capital Apply Online for Same Day Business Funding" />
                        <h3>Same Day Funding</h3>
                    </a>
                    <span></span>
                </div>
            </div>
        </div>
    )
}

