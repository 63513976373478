import React from "react";
import './SameDayFunding.scss';
import $ from 'jquery';
import { useState } from "react";
import { Helmet } from "react-helmet";

// images
import Review from '../../Images/Review.png'
import next from '../../Images/next.png'
import blog from '../../Images/blog7.jpg'
import blog3 from '../../Images/blog8.jpg'
import MainPic from '../../Images/MainPic.jpg'

import clock from '../../Images/clock.png'
import computer from '../../Images/computer.png'
import books from '../../Images/books.png'
import { useNavigate } from "react-router-dom";

export default function SameDayFunding({ FaqList, Blogs }) {
    const navigate = useNavigate()
    function readingTime(S) {
        const text = S.Text;
        const wpm = 225;
        const words = text.trim().split(/\s+/).length;
        const time = Math.ceil(words / wpm);
        return time
    }

    const getSpecificNumberFaq = () => {
        const number = Math.random() * FaqList.length
        if (number <= (FaqList.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumberFaq()
        }
    }
    const [getNumberFaq, setgetNumberFaq] = useState(getSpecificNumberFaq());
    const getSpecificNumberBlog = () => {
        const number = Math.random() * Blogs.length
        if (number <= (Blogs.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumberBlog()
        }
    }
    const [getNumberBlog, setgetNumberBlog] = useState(getSpecificNumberBlog());


    return (
        <div className="SameDayFunding">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Apply Online for Same Day Business Funding | SENEER</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Apply Online for Same Day Business Funding | SENEER" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Apply Online for Same Day Business Funding | SENEER" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="website" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="website" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.seneercapital.com/same-day-funding" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Apply online for same-day business funding. Access rapid capital with quick approval and enjoy a seamless funding process. Secure your business's financial future today." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Apply online for same-day business funding. Access rapid capital with quick approval and enjoy a seamless funding process. Secure your business's financial future today." data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Apply Online for Same Day Business Funding | SENEER" data-react-helmet="true" />
                <meta name="keywords" content="Same Day Funding, Quick Cash Disbursement, Rapid Fund Approval, Instant Business Financing, Immediate Money Transfer, Fast Business Capital, Emergency Fund Access, Same Day Loan Approval, Swift Funding Solutions, Speedy Financial Assistance" data-react-helmet="true" />
            </Helmet>
            {/* SameDayFundingMainBanner */}
            <div className="SameDayFundingMainBanner">
                <div className="SameDayFundingMainBannerLeft">
                    <h1>Apply Online for Same Day Business Funding</h1>
                    <h2>Experience our streamlined approval process with same-day funding. Get a Quote in just 5 minutes and secure funding within hours.</h2>
                    <button onClick={() => navigate('/apply')}>Apply Now</button>
                </div>
                <div className="SameDayFundingMainBannerRight">
                    <img src={blog3} alt="Same Day Funding, Quick Cash Disbursement, Rapid Fund Approval, Instant Business Financing, Immediate Money Transfer, Fast Business Capital, Emergency Fund Access, Same Day Loan Approval, Swift Funding Solutions, Speedy Financial Assistance" />
                    <div className="SameDayFundingMainBannerRightBubble1"></div>
                    <div className="SameDayFundingMainBannerRightBubble2"></div>
                </div>
            </div>
            <div className="SameDayFundingMainBanner2">
                <div className="SameDayFundingMainBanner2Top">
                    <div className="SameDayFundingMainBanner2TopBox">
                        <h2>Access Up To</h2>
                        <h3>$1,000,000</h3>
                    </div>
                    <div className="SameDayFundingMainBanner2TopBox">

                        <h2>With Competitive Rates</h2>
                        <h3>Starting As Low As</h3>
                    </div>
                    <div className="SameDayFundingMainBanner2TopBox">

                        <h2>Same Day Business Funding</h2>
                        <h3>Effortless Access to Rapid Working Capital</h3>
                    </div>
                </div>
                {/* Title */}
                <h1>APPLY NOW</h1>
                <div className="SameDayFundingMainBanner2Bottom">
                    <div className="SameDayFundingMainBanner2BottomBox">
                        <img src={Review} alt="Same Day Funding, Quick Cash Disbursement, Rapid Fund Approval, Instant Business Financing, Immediate Money Transfer, Fast Business Capital, Emergency Fund Access, Same Day Loan Approval, Swift Funding Solutions, Speedy Financial Assistance" />
                        <h2>Funds Available on the Same Day</h2>
                        <h3>Experience peace of mind with funds readily available for all your business needs within hours of submitting your application.</h3>
                        <button onClick={() => navigate('/apply')}>Apply</button>
                    </div>
                </div>
            </div>
            <div className="SameDayFundingMainBanner5">
                <div className="SameDayFundingMainBanner5Left">
                    <h1>Streamlined Funding for Your Business:</h1>
                    <div className="SameDayFundingMainBanner5LeftBox">
                        <h2>1</h2>
                        <div className="SameDayFundingMainBanner5LeftBoxRigth">
                            <h3>100% Unsecured Funding</h3>
                            <h4>Enjoy stress-free funding with No Personal Guarantee and No Collateral required. You can focus solely on expanding your business.</h4>
                        </div>
                    </div>
                    <div className="SameDayFundingMainBanner5LeftBox">
                        <h2>2</h2>
                        <div className="SameDayFundingMainBanner5LeftBoxRigth">
                            <h3>Direct Lender Assistance</h3>
                            <h4>Our in-house funding process ensures the utmost privacy and confidentiality of your information.</h4>
                        </div>
                    </div>
                    <div className="SameDayFundingMainBanner5LeftBox">
                        <h2>3</h2>
                        <div className="SameDayFundingMainBanner5LeftBoxRigth">
                            <h3>Flexible Financing for Your Growth</h3>
                            <h4>We support your business expansion by tailoring the right working capital solutions to your business size, at any stage of your journey.</h4>
                        </div>
                    </div>
                    <button onClick={() => navigate('/apply')}>Apply Now</button>
                </div>
                <div className="SameDayFundingMainBanner5Right">
                    <img src={blog} alt="Same Day Funding, Quick Cash Disbursement, Rapid Fund Approval, Instant Business Financing, Immediate Money Transfer, Fast Business Capital, Emergency Fund Access, Same Day Loan Approval, Swift Funding Solutions, Speedy Financial Assistance" />
                    <div className="SameDayFundingMainBanner5RightBubble1"></div>
                    <div className="SameDayFundingMainBanner5RightBubble2"></div>
                </div>

            </div>
            <div className="SameDayFundingMainBanner4">
                <div className="SameDayFundingMainBanner4Left">
                    <img src={blog} alt="Same Day Funding, Quick Cash Disbursement, Rapid Fund Approval, Instant Business Financing, Immediate Money Transfer, Fast Business Capital, Emergency Fund Access, Same Day Loan Approval, Swift Funding Solutions, Speedy Financial Assistance" />
                    <div className="SameDayFundingMainBanner4LeftBubble1"></div>
                    <div className="SameDayFundingMainBanner4LeftBubble2"></div>
                </div>
                {/*  */}
                <div className="SameDayFundingMainBanner4Right">
                    <h1>LET'S DISCUSS IN DETAIL</h1>
                    <h2>Pros and Cons of Quick Business Funding</h2>
                    <div className="SameDayFundingMainBanner4RightBenefits">
                        <div className="SameDayFundingMainBanner4RightBenefitsBox">
                            <h3>SENEER Capital is here to provide funds approved within 24 hours, allowing your business plans to take flight with Same Day funding. However, there are crucial considerations to keep in mind.</h3>
                            <h4>These types of loans offer rapid funding for your business, but they often come with higher interest rates in exchange for the speed of disbursement.</h4>
                        </div>
                    </div>
                </div>
                {/*  */}
            </div>
            <div className="SameDayFundingMainBanner6">
                <h1>What You Need to Get Started<br /> Minimum Qualifications</h1>
                <div className="SameDayFundingMainBanner6List">
                    <div className="SameDayFundingMainBanner6TopBox">
                        <h2>1 Year</h2>
                        <h3>At least 1 year in business</h3>
                    </div>
                    <div className="SameDayFundingMainBanner6TopBox">
                        <h2>FICO® Score</h2>
                        <h3>A FICO score of 600 or above</h3>
                    </div>
                    <div className="SameDayFundingMainBanner6TopBox">
                        <h2>6 Months in Business</h2>
                        <h3>t least 6 months in business</h3>
                    </div>
                    <div className="SameDayFundingMainBanner6TopBox">
                        <h2>Revenue</h2>
                        <h3>A monthly revenue of $10,000 or more</h3>
                    </div>
                </div>
            </div>
            <div className="SameDayFundingMainBanner3">
                <h1>What You Need to Apply:</h1>
                <div className="SameDayFundingMainBanner3List">
                    <div className="SameDayFundingMainBanner3Box">
                        <div className="SameDayFundingMainBanner3BoxIMG">
                            <img src={computer} alt="No Hard Credit Pulls" />
                        </div>
                        <h3>Basic Information</h3>
                        <h4>Basic information about you and your business</h4>
                    </div>
                    <div className="SameDayFundingMainBanner3Box">
                        <div className="SameDayFundingMainBanner3BoxIMG">
                            <img src={clock} alt="Pay Only for What You Borrow" />
                        </div>
                        <h3>Bank Connection</h3>
                        <h4>Bank connection or year-to-date bank statements</h4>
                    </div>
                </div>

            </div>
            <div className="BlogsListFaq">
                <div className="BlogsListFaqHead">
                    <h1>Frequently asked questions</h1>
                    <a href="/faq">View All</a>
                </div>
                <div className="BlogsListFaqList">
                    {FaqList.slice(getNumberFaq, (getNumberFaq + 5)).map((A, index) =>
                        <article key={index} className="FaqListBox">
                            <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                <h1>{A.question}</h1>
                                {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                <h3><span> {A.category}</span></h3>
                            </a>
                        </article>

                    )}
                </div>
            </div>
            <div className="BlogsListReview">
                <div className="BlogsListReviewHead">
                    <h1>Related Articles</h1>
                    <a href="/blog">View All</a>
                </div>
                <div className="BlogsListReviewList">
                    {Blogs.slice(getNumberBlog, (getNumberBlog + 3)).map((A, index) =>

                        <a href={`/blog/${A.BlogPath.replace(/ /g, "_") + "_" + (A.BlogId - 1)}`} key={index} className="BlogsListReviewBox" >
                            <article>
                                <div className="BlogsListReviewBoxLeft">
                                    <img src={A.Photos} alt={A.BlogPath} />
                                </div>
                                <div className="BlogsListReviewBoxRight">
                                    <h1>{A.Title}</h1>
                                    <span>
                                        <h5>{readingTime(A)} MIN READ</h5>
                                        <h4>{A.Date}</h4>
                                    </span>
                                </div>
                            </article>
                        </a>
                    )}
                </div>
            </div>
            <div className="SameDayFundingMainBanner7">
                <div className="SameDayFundingMainBanner7Left">
                    <h1>Check Now to See If You Qualify With No Impact on Your Credit!</h1>
                    <h2>Discover the optimal financing solution for your business, whether you're just starting out or expanding. Collaborate with one of our experienced advisors who can provide guidance tailored to your unique business needs.</h2>
                    <a href="/apply">Apply Now</a>
                </div>
                <div className="SameDayFundingMainBanner7Right">
                    <img src={blog3} alt="Same Day Funding, Quick Cash Disbursement, Rapid Fund Approval, Instant Business Financing, Immediate Money Transfer, Fast Business Capital, Emergency Fund Access, Same Day Loan Approval, Swift Funding Solutions, Speedy Financial Assistance" />
                    <div className="SameDayFundingMainBanner7RightBubble1"></div>
                    <div className="SameDayFundingMainBanner7RightBubble2"></div>
                </div>
            </div>
        </div>
    )
}

