import React from "react";
import './Faq.scss';
import Arrow2 from '../../Images/next.png'
import Review from '../../Images/Review.png'
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from '../../Images/Logo.png'
import closeMenu from '../../Images/closeMenu.png'

// images
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"


export default function Faq({ Blogs, FaqList }) {
    const navigate = useNavigate()
    const [faqlistFilter, setfaqlistFilter] = useState(FaqList);
    const [faqlistInputValue, setfaqlistInputValue] = useState("");
    function readingTime(S) {
        const text = S.Text;
        const wpm = 225;
        const words = text.trim().split(/\s+/).length;
        const time = Math.ceil(words / wpm);
        return time
    }
    const getSpecificNumberBlog = () => {
        const number = Math.random() * Blogs.length
        if (number <= (Blogs.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumberBlog()
        }
    }
    const [getNumberBlog, setgetNumberBlog] = useState(getSpecificNumberBlog());
    const location = useLocation()
    useEffect(() => {
        // console.log(location.hash);
    }, []);

    return (


        <div className="Faq">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>SENEER | Answer And Questions</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="SENEER | Answer And Questions" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="SENEER | Answer And Questions" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* Blog Tags */}
                <meta name="PageType" content="article" />
                <meta name="ContentType" content="article" />
                <meta name="Category" content="article" />
                <meta name="SubCategories" content="article" />
                <meta name="FunnelStage" content="upper" />
                <meta name="Objective" content="education, seo" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.seneercapital.com/faq" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Explore the Seneer Capital Faq Page for insightful articles on business funding strategies, tips, and expert advice. Discover innovative ways to secure capital, grow your business, and achieve financial success." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Explore the Seneer Capital Faq Page for insightful articles on business funding strategies, tips, and expert advice. Discover innovative ways to secure capital, grow your business, and achieve financial success." data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="SENEER | Answer And Questions" data-react-helmet="true" />
                <meta name="keywords" content="Financing, Business, Advance, Funding, Cash, Merchant, Capital, Loan, Credit, Repayment, Faq Page" data-react-helmet="true" />
            </Helmet>
            <div className="FaqMainBanner">
                <h1>Expand your enterprise at your own pace.</h1>
                <h2>Find answers to frequently asked questions. <br />Streamlined small business funding. Complete your application in just minutes!</h2>
                <h5>Categories</h5>
                <div className="FaqMainBannerCategories">
                    <a href="/faq#small-business-loan">Small Business Loan</a>
                    <a href="/faq#term-loan">Term Loan</a>
                    <a href="/faq#merchant-cash-advance">Merchant Cash Advance</a>
                    <a href="/faq#working-capital">Working Capital</a>
                    <a href="/faq#line-of-credit">Line of Credit</a>
                    <a href="/faq#same-day-funding">Same Day Funding</a>
                    <a href="/faq#business-line-of-credit">Business Line of Credit</a>
                </div>

            </div>
            {/* FaqMainList */}
            <div className="FaqMainList">
                {faqlistInputValue ?
                    <div className="FaqList">
                        <div className="FaqListHeaderResults">
                            <h6>Results</h6>
                            <h5 onClick={() => setfaqlistInputValue("")}>Restart Results</h5>
                        </div>
                        {faqlistFilter.filter(a =>
                            a.question.toLocaleLowerCase().includes(faqlistInputValue.toLocaleLowerCase()) ||
                            a.questionPath.toLocaleLowerCase().includes(faqlistInputValue.toLocaleLowerCase())
                        ).map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}

                        {faqlistFilter.length ?
                            <div className="FaqListNoResult">
                                <h1>We're sorry, but your search did not return any results.</h1>
                            </div>
                            : null}

                        <div className="FaqPageHeaderImagePromise">
                            <div className="FaqPageHeaderImagePromiseTop">
                                <div className="FaqPageHeaderImagePromiseTopLeft">
                                    <img src={Logo} alt="" />
                                    <h2>SENEER Capital promise</h2>
                                </div>
                                <a href="/apply">More info</a>
                            </div>
                            <p>At SENEER Capital, our primary goal is to empower you to make well-informed financial decisions. Our commitment to maintaining rigorous editorial standards means that in some instances, this article may include mentions of financial products and services offered by our <a href="/contact">Company</a>. Here's a transparent breakdown of how we generate revenue.</p>
                        </div>
                    </div>
                    :
                    <div className="FaqList">
                        <h6 id="small-business-loan">Small Business Loan</h6>
                        {faqlistFilter.filter(a => a.category == "SBA  Loan").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                        <div className="FaqPageHeaderImagePromise">
                            <div className="FaqPageHeaderImagePromiseTop">
                                <div className="FaqPageHeaderImagePromiseTopLeft">
                                    <img src={Logo} alt="" />
                                    <h2>SENEER Capital promise</h2>
                                </div>
                                <a href="/apply">More info</a>
                            </div>
                            <p>At SENEER Capital, our primary goal is to empower you to make well-informed financial decisions. Our commitment to maintaining rigorous editorial standards means that in some instances, this article may include mentions of financial products and services offered by our <a href="/contact">Company</a>. Here's a transparent breakdown of how we generate revenue.</p>
                        </div>
                        <h6 id="term-loan">Term Loan</h6>
                        {FaqList.filter(a => a.category == "Term Loan").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                        <h6 id="merchant-cash-advance">Merchant Cash Advance</h6>
                        {FaqList.filter(a => a.category == "Merchant Cash Advance").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                        <h6 id="working-capital">Working Capital</h6>
                        {FaqList.filter(a => a.category == "Working Capital").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                        <h6 id="line-of-credit">Line of Credit</h6>
                        {FaqList.filter(a => a.category == "Line of Credit").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                        <h6 id="same-day-funding">Same Day Funding</h6>
                        {FaqList.filter(a => a.category == "Term Loan").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                        <h6 id="business-line-of-credit">Business Line of Credit</h6>
                        {FaqList.filter(a => a.category == "Business Line of Credit").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                    </div>
                }
                <aside className="FaqSearch">
                    <h1>Categories</h1>
                    <div className="FaqMainBannerCategories">
                        <a style={location.hash == "#small-business-loan" ? { "borderBottom": "2px solid #0157ff" } : null} href="/faq#small-business-loan">Small Business Loan</a>
                        <a style={location.hash == "#term-loan" ? { "borderBottom": "2px solid #0157ff" } : null} href="/faq#term-loan">Term Loan</a>
                        <a style={location.hash == "#merchant-cash-advance" ? { "borderBottom": "2px solid #0157ff" } : null} href="/faq#merchant-cash-advance">Merchant Cash Advance</a>
                        <a style={location.hash == "#working-capital" ? { "borderBottom": "2px solid #0157ff" } : null} href="/faq#working-capital">Working Capital</a>
                        <a style={location.hash == "#line-of-credit" ? { "borderBottom": "2px solid #0157ff" } : null} href="/faq#line-of-credit">Line of Credit</a>
                        <a style={location.hash == "#same-day-funding" ? { "borderBottom": "2px solid #0157ff" } : null} href="/faq#same-day-funding">Same Day Funding</a>
                        <a style={location.hash == "#business-line-of-credit" ? { "borderBottom": "2px solid #0157ff" } : null} href="/faq#business-line-of-credit">Business Line of Credit</a>
                    </div>
                    <h1>Search for question</h1>
                    <div className="FaqSearchInput">
                        <input
                            value={faqlistInputValue || ''}
                            onFocus={() => {
                                window.screen.width >= 850 ? window.scrollTo(0, 420) : window.scrollTo(0, 570)
                            }}
                            onChange={(e) => {
                                setfaqlistInputValue(e.target.value)
                                window.screen.width >= 850 ? window.scrollTo(0, 420) : window.scrollTo(0, 570)

                            }} type="text" placeholder="Search..." />
                        {faqlistInputValue ?
                            <img onClick={() => setfaqlistInputValue("")} src={closeMenu} alt="" />
                            :
                            null}
                    </div>
                </aside>
            </div>

            <div className="BlogsListReview">
                <div className="BlogsListReviewHead">
                    <h1>Related Articles</h1>
                </div>
                <div className="BlogsListReviewList">
                    {Blogs.slice(getNumberBlog, (getNumberBlog + 3)).map((A, index) =>
                        <a href={`/blog/${A.BlogPath.replace(/ /g, "_") + "_" + (A.BlogId - 1)}`} key={index} className="BlogsListReviewBox" >
                            <article>

                                <div className="BlogsListReviewBoxLeft">
                                    <img src={A.Photos} alt={A.BlogPath} />
                                </div>
                                <div className="BlogsListReviewBoxRight">
                                    <h1>{A.Title}</h1>
                                    <span>
                                        <h5>{readingTime(A)} MIN READ</h5>
                                        <h4>{A.Date}</h4>
                                    </span>
                                </div>
                            </article>

                        </a>
                    )}
                </div>
            </div>
            <div className="FaqApply">
                <h1>Elevate Your Business with Small Business Lending Specialists</h1>
                <div className="FaqApplyBox">
                    <img src={Review} />
                    <h2> Submit your application within minutes <br /> No commitment required</h2>
                    <a href="/apply" >Apply Now</a>
                </div>

            </div>

            <NavigatorPath />

        </div >
    )
}

