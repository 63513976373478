import './Portal.scss';
import Header from '../Tools/Header/Header';
import Footer from '../Tools/Footer/Footer';
import Menu from '../Tools/Menu/Menu';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MainPortal from './MainPortal';
import MainPic from '../Images/MainPic.jpg'
import NavBar from './Tools/NavBar/NavBar';
// blogs images


function Portal() {
  const [showMenu, setShowMenu] = useState(false)
  const [showVerify, setShowVerify] = useState(true)
  const navigate = useNavigate()
  const location = useLocation()

  // https://www.seneercapital.com/apply?portal=5&supply=1&template=1&seller=2

  // PortalData
  const [PortalData, setPortalData] = useState([
    {
      Portalsourc: 1,
      name: "Facebook",
      icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Facebook_Logo_%282019%29.png/640px-Facebook_Logo_%282019%29.png"
    },
    {
      Portalsourc: 2,
      name: "Instagram",
      icon: "https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-instagram-icon-png-image_6315974.png"
    },
    {
      Portalsourc: 2,
      name: "Email",
      icon: "https://banner2.cleanpng.com/20180331/abe/kisspng-email-computer-icons-signature-block-clip-art-mail-5abf317a9d4f29.3034228715224794826443.jpg",
      Suplly: [
        {
          Name: "Maria",
          Company: "Landfall Media Group",
          CompanyId: 1,
          Template: [
            {
              TemplateId: 1,
              TemplateText: "Please take a moment to fill out the final application form, ensuring all details are accurate and complete."
            }
          ]
        },
      ]
    },
    {
      Portalsourc: 3,
      name: "Cold Call",
      icon: "https://cdn-icons-png.flaticon.com/512/74/74331.png"
    },
    {
      Portalsourc: 4,
      name: "Text",
      icon: "https://cdn.osxdaily.com/wp-content/uploads/2014/11/Messages-icon.png",
      Suplly: [
        {
          Name: "Maria",
          Company: "Landfall Media Group",
          CompanyId: 1,
          Template: [
            {
              TemplateId: 1,
              TemplateText: "Hi %%Name%%, We recently received your file from a broker. Did you get all the funding you needed?"
            }
          ]
        },
      ]
    },
    {
      Portalsourc: 5,
      name: "Email",
      icon: "https://banner2.cleanpng.com/20180331/abe/kisspng-email-computer-icons-signature-block-clip-art-mail-5abf317a9d4f29.3034228715224794826443.jpg",
      Suplly: [
        {
          Name: "Maria",
          Company: "Landfall Media Group",
          CompanyId: 1,
          Template: [
            {
              TemplateId: 1,
              TemplateText: "Please take a moment to fill out the final application form, ensuring all details are accurate and complete."
            }
          ]
        },
      ]
    },
    {
      Portalsourc: 6,
      name: "Linkedin",
      icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/LinkedIn_logo_initials.png/600px-LinkedIn_logo_initials.png?20140125013055"
    },
    {
      Portalsourc: 8,
      name: "Taboola",
      icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/LinkedIn_logo_initials.png/600px-LinkedIn_logo_initials.png?20140125013055"
    },

  ]);
  // SellersData
  const [SellersData, setSellersData] = useState([
    {
      name: "Snir",
      sellerId: 1,
      register: "2023-09-01 14:09:41",
    },
    {
      name: "David",
      sellerId: 2,
      register: "2023-09-01 14:09:41",
    },
    {
      name: "Sun",
      sellerId: 3,
      register: "2023-09-01 14:09:41",
    },
    {
      name: "Ram",
      sellerId: 4,
      register: "2023-09-01 14:09:41",
    },
    {
      name: "Meir",
      sellerId: 5,
      register: "2023-09-01 14:09:41",
    }
  ]);
  // sc = Portalsourc - Which source we send from the app
  // ci = Company - Where we bouth the leads(data) from
  // sr = seller - Broker Id
  // ti = template - which template we used [[11] first digit = groupe / second digit = template id]

  // Total click https://www.shorturl.at/url-total-clicks.php?u=shorturl.at/lqxZ7
  // Emails https://www.seneercapital.com/apply?sc=5&ci=1&sr=1&ti=11
  // Facebook https://www.seneercapital.com/apply?sc=1&ci=1&sr=1
  // Text https://www.seneercapital.com/apply?sc=4&ci=1&sr=1

  // Datas
  const [Datas, setDatas] = useState([
    {
      link: "https://www.seneercapital.com/apply?sc=5&ci=1&sr=1&ti=11",
      // link: "https://www.seneercapital.com/apply?sourc=4&company=1&seller=1",
      date: "2023-09-01 14:09:41"
    },
    {
      link: "https://www.seneercapital.com/apply?sourc=2&seller=2",
      date: "2023-09-01 14:09:41"
    },
    {
      link: "https://www.seneercapital.com/apply?sourc=4&seller=2",
      date: "2023-09-01 14:09:41"
    },
    {
      link: "https://www.seneercapital.com/apply?sourc=4&seller=2",
      date: "2023-09-01 14:09:41"
    },
    {
      link: "https://www.seneercapital.com/apply?sourc=4&seller=2",
      date: "2023-09-01 14:09:41"
    },
    {
      link: "https://www.seneercapital.com/apply?sourc=1&seller=1",
      date: "2023-09-01 14:09:41"
    },
    {
      link: "https://www.seneercapital.com/apply?sourc=5&seller=3",
      date: "2023-09-01 14:09:41"
    },
    {
      link: "https://www.seneercapital.com/apply?sourc=5&seller=1",
      date: "2023-09-01 14:09:41"
    },
    {
      link: "https://www.seneercapital.com/apply?sourc=1&seller=4",
      date: "2023-09-01 14:09:41"
    },
    {
      link: "https://www.seneercapital.com/apply?sourc=6&seller=4",
      date: "2023-09-01 14:09:41"
    },
    {
      link: "https://www.seneercapital.com/apply?sourc=2&seller=4",
      date: "2023-09-01 14:09:41"
    },
    {
      link: "https://www.seneercapital.com/apply?sourc=2&seller=1",
      date: "2023-09-01 14:09:41"
    },
    {
      link: "https://www.seneercapital.com/apply?sourc=1&seller=5",
      date: "2023-09-01 14:09:41"
    },
    {
      link: "https://www.seneercapital.com/apply?sourc=1&seller=5",
      date: "2023-09-01 14:09:41"
    },
    {
      link: "https://www.seneercapital.com/apply?sourc=1&seller=1",
      date: "2023-09-01 14:09:41"
    },
    {
      link: "https://www.seneercapital.com/apply?sourc=3&seller=1",
      date: "2023-09-01 14:09:41"
    },
    {
      link: "https://www.seneercapital.com/apply?sourc=5&seller=1",
      date: "2023-09-01 14:09:41"
    },
    {
      link: "https://www.seneercapital.com/apply?sourc=6&seller=4",
      date: "2023-09-01 14:09:41"
    },
    {
      link: "https://www.seneercapital.com/apply?sourc=1&seller=4",
      date: "2023-09-01 14:09:41"
    },
    {
      link: "https://www.seneercapital.com/apply?sourc=3&seller=4",
      date: "2023-09-01 14:09:41"
    },
    {
      link: "https://www.seneercapital.com/apply?sourc=5&seller=3",
      date: "2023-09-01 14:09:41"
    },
    {
      link: "https://www.seneercapital.com/apply?sourc=6&seller=2",
      date: "2023-09-01 14:09:41"
    },
    {
      link: "https://www.seneercapital.com/apply?sourc=1&seller=1",
      date: "2023-09-01 14:09:41"
    },
    {
      link: "https://www.seneercapital.com/apply?sourc=3&seller=1",
      date: "2023-09-01 14:09:41"
    },
    {
      link: "https://www.seneercapital.com/apply?sourc=5&seller=1",
      date: "2023-09-01 14:09:41"
    },
    {
      link: "https://www.seneercapital.com/apply?sourc=6&seller=1",
      date: "2023-09-01 14:09:41"
    },
  ]);
  // NewData
  const [ApplicationsData, setApplicationsData] = useState([
    {
      "id": "783247",
      "Submission_Date": "Jan 10, 2023",
      "Legal__Business_Name": "Mongin & Comp llc",
      "DBA": "Phoenix Trucking and Site Development LLC",
      "What_type_of_business_do_you_own": "Limited Liability Company",
      "What_industry_best_matches_your_business": "Heavy and Civil Engineering Construction",
      "Business_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Business_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Business_Tax_ID": "238536815",
      "Business_Phone_Number": "(336) 341-8938",
      "Date_Business_Started": "Jan 10, 2023",
      "Email": "spud0055@gmail.com",
      "Agree_to_our_privacy_policy": "Yes",
      "User_Agent_String": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
      "Signature": "sssss",
      "How_will_you_use_these_funds": "Inventory and equipment",
      "Product__Service_Sold": "Food service",
      "Get_Page_URL": "https://www.seneercapital.com/apply?portal=4&supply=1&template=1&seller=2",
      "Corporate_Officer__Owner_Full_Name": "Peter McClary",
      "Title": "president",
      "Ownership": "100",
      "Home_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Home_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Social_Security_Number_SSN": "353602130",
      "Date_of_Birth": "Mar 06, 1961",
      "Phone_Number": "(413) 530-9590",
      "What_is_your_credit_score": "639 or less",
      "Requested_Funding_Amount": "30000",
      "What_is_your_business_monthly_revenue": "$40,000 to $50,000",
      "Last_3_Month_Business_Bank_Statements": "Files",
      // "Last_3_Month_Business_Bank_Statements": [
      //   {
      //     "id":"dsad",
      //     "file":"sss"
      //   }
      // ],
    },
    {
      "id": "783247",
      "Submission_Date": "Jan 10, 2023",
      "Legal__Business_Name": "Mongin & Comp llc",
      "DBA": "Phoenix Trucking and Site Development LLC",
      "What_type_of_business_do_you_own": "Limited Liability Company",
      "What_industry_best_matches_your_business": "Heavy and Civil Engineering Construction",
      "Business_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Business_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Business_Tax_ID": "238536815",
      "Business_Phone_Number": "(336) 341-8938",
      "Date_Business_Started": "Jan 10, 2023",
      "Email": "spud0055@gmail.com",
      "Agree_to_our_privacy_policy": "Yes",
      "User_Agent_String": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
      "Signature": "sssss",
      "How_will_you_use_these_funds": "Inventory and equipment",
      "Product__Service_Sold": "Food service",
      "Get_Page_URL": "https://www.seneercapital.com/apply?portal=4&supply=1&template=1&seller=2",
      "Corporate_Officer__Owner_Full_Name": "Peter McClary",
      "Title": "president",
      "Ownership": "100",
      "Home_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Home_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Social_Security_Number_SSN": "353602130",
      "Date_of_Birth": "Mar 06, 1961",
      "Phone_Number": "(413) 530-9590",
      "What_is_your_credit_score": "639 or less",
      "Requested_Funding_Amount": "30000",
      "What_is_your_business_monthly_revenue": "$40,000 to $50,000",
      "Last_3_Month_Business_Bank_Statements": "Files",
      // "Last_3_Month_Business_Bank_Statements": [
      //   {
      //     "id":"dsad",
      //     "file":"sss"
      //   }
      // ],
    },
    {
      "id": "783247",
      "Submission_Date": "Jan 10, 2023",
      "Legal__Business_Name": "Mongin & Comp llc",
      "DBA": "Phoenix Trucking and Site Development LLC",
      "What_type_of_business_do_you_own": "Limited Liability Company",
      "What_industry_best_matches_your_business": "Heavy and Civil Engineering Construction",
      "Business_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Business_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Business_Tax_ID": "238536815",
      "Business_Phone_Number": "(336) 341-8938",
      "Date_Business_Started": "Jan 10, 2023",
      "Email": "spud0055@gmail.com",
      "Agree_to_our_privacy_policy": "Yes",
      "User_Agent_String": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
      "Signature": "sssss",
      "How_will_you_use_these_funds": "Inventory and equipment",
      "Product__Service_Sold": "Food service",
      "Get_Page_URL": "https://www.seneercapital.com/apply?portal=4&supply=1&template=1&seller=2",
      "Corporate_Officer__Owner_Full_Name": "Peter McClary",
      "Title": "president",
      "Ownership": "100",
      "Home_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Home_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Social_Security_Number_SSN": "353602130",
      "Date_of_Birth": "Mar 06, 1961",
      "Phone_Number": "(413) 530-9590",
      "What_is_your_credit_score": "639 or less",
      "Requested_Funding_Amount": "30000",
      "What_is_your_business_monthly_revenue": "$40,000 to $50,000",
      "Last_3_Month_Business_Bank_Statements": "Files",
      // "Last_3_Month_Business_Bank_Statements": [
      //   {
      //     "id":"dsad",
      //     "file":"sss"
      //   }
      // ],
    },
    {
      "id": "783247",
      "Submission_Date": "Jan 10, 2023",
      "Legal__Business_Name": "Mongin & Comp llc",
      "DBA": "Phoenix Trucking and Site Development LLC",
      "What_type_of_business_do_you_own": "Limited Liability Company",
      "What_industry_best_matches_your_business": "Heavy and Civil Engineering Construction",
      "Business_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Business_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Business_Tax_ID": "238536815",
      "Business_Phone_Number": "(336) 341-8938",
      "Date_Business_Started": "Jan 10, 2023",
      "Email": "spud0055@gmail.com",
      "Agree_to_our_privacy_policy": "Yes",
      "User_Agent_String": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
      "Signature": "sssss",
      "How_will_you_use_these_funds": "Inventory and equipment",
      "Product__Service_Sold": "Food service",
      "Get_Page_URL": "https://www.seneercapital.com/apply?portal=4&supply=1&template=1&seller=2",
      "Corporate_Officer__Owner_Full_Name": "Peter McClary",
      "Title": "president",
      "Ownership": "100",
      "Home_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Home_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Social_Security_Number_SSN": "353602130",
      "Date_of_Birth": "Mar 06, 1961",
      "Phone_Number": "(413) 530-9590",
      "What_is_your_credit_score": "639 or less",
      "Requested_Funding_Amount": "30000",
      "What_is_your_business_monthly_revenue": "$40,000 to $50,000",
      "Last_3_Month_Business_Bank_Statements": "Files",
      // "Last_3_Month_Business_Bank_Statements": [
      //   {
      //     "id":"dsad",
      //     "file":"sss"
      //   }
      // ],
    },
    {
      "id": "783247",
      "Submission_Date": "Jan 10, 2023",
      "Legal__Business_Name": "Mongin & Comp llc",
      "DBA": "Phoenix Trucking and Site Development LLC",
      "What_type_of_business_do_you_own": "Limited Liability Company",
      "What_industry_best_matches_your_business": "Heavy and Civil Engineering Construction",
      "Business_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Business_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Business_Tax_ID": "238536815",
      "Business_Phone_Number": "(336) 341-8938",
      "Date_Business_Started": "Jan 10, 2023",
      "Email": "spud0055@gmail.com",
      "Agree_to_our_privacy_policy": "Yes",
      "User_Agent_String": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
      "Signature": "sssss",
      "How_will_you_use_these_funds": "Inventory and equipment",
      "Product__Service_Sold": "Food service",
      "Get_Page_URL": "https://www.seneercapital.com/apply?portal=4&supply=1&template=1&seller=2",
      "Corporate_Officer__Owner_Full_Name": "Peter McClary",
      "Title": "president",
      "Ownership": "100",
      "Home_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Home_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Social_Security_Number_SSN": "353602130",
      "Date_of_Birth": "Mar 06, 1961",
      "Phone_Number": "(413) 530-9590",
      "What_is_your_credit_score": "639 or less",
      "Requested_Funding_Amount": "30000",
      "What_is_your_business_monthly_revenue": "$40,000 to $50,000",
      "Last_3_Month_Business_Bank_Statements": "Files",
      // "Last_3_Month_Business_Bank_Statements": [
      //   {
      //     "id":"dsad",
      //     "file":"sss"
      //   }
      // ],
    },
    {
      "id": "783247",
      "Submission_Date": "Jan 10, 2023",
      "Legal__Business_Name": "Mongin & Comp llc",
      "DBA": "Phoenix Trucking and Site Development LLC",
      "What_type_of_business_do_you_own": "Limited Liability Company",
      "What_industry_best_matches_your_business": "Heavy and Civil Engineering Construction",
      "Business_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Business_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Business_Tax_ID": "238536815",
      "Business_Phone_Number": "(336) 341-8938",
      "Date_Business_Started": "Jan 10, 2023",
      "Email": "spud0055@gmail.com",
      "Agree_to_our_privacy_policy": "Yes",
      "User_Agent_String": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
      "Signature": "sssss",
      "How_will_you_use_these_funds": "Inventory and equipment",
      "Product__Service_Sold": "Food service",
      "Get_Page_URL": "https://www.seneercapital.com/apply?portal=4&supply=1&template=1&seller=2",
      "Corporate_Officer__Owner_Full_Name": "Peter McClary",
      "Title": "president",
      "Ownership": "100",
      "Home_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Home_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Social_Security_Number_SSN": "353602130",
      "Date_of_Birth": "Mar 06, 1961",
      "Phone_Number": "(413) 530-9590",
      "What_is_your_credit_score": "639 or less",
      "Requested_Funding_Amount": "30000",
      "What_is_your_business_monthly_revenue": "$40,000 to $50,000",
      "Last_3_Month_Business_Bank_Statements": "Files",
      // "Last_3_Month_Business_Bank_Statements": [
      //   {
      //     "id":"dsad",
      //     "file":"sss"
      //   }
      // ],
    },
    {
      "id": "783247",
      "Submission_Date": "Jan 10, 2023",
      "Legal__Business_Name": "Mongin & Comp llc",
      "DBA": "Phoenix Trucking and Site Development LLC",
      "What_type_of_business_do_you_own": "Limited Liability Company",
      "What_industry_best_matches_your_business": "Heavy and Civil Engineering Construction",
      "Business_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Business_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Business_Tax_ID": "238536815",
      "Business_Phone_Number": "(336) 341-8938",
      "Date_Business_Started": "Jan 10, 2023",
      "Email": "spud0055@gmail.com",
      "Agree_to_our_privacy_policy": "Yes",
      "User_Agent_String": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
      "Signature": "sssss",
      "How_will_you_use_these_funds": "Inventory and equipment",
      "Product__Service_Sold": "Food service",
      "Get_Page_URL": "https://www.seneercapital.com/apply?portal=4&supply=1&template=1&seller=2",
      "Corporate_Officer__Owner_Full_Name": "Peter McClary",
      "Title": "president",
      "Ownership": "100",
      "Home_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Home_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Social_Security_Number_SSN": "353602130",
      "Date_of_Birth": "Mar 06, 1961",
      "Phone_Number": "(413) 530-9590",
      "What_is_your_credit_score": "639 or less",
      "Requested_Funding_Amount": "30000",
      "What_is_your_business_monthly_revenue": "$40,000 to $50,000",
      "Last_3_Month_Business_Bank_Statements": "Files",
      // "Last_3_Month_Business_Bank_Statements": [
      //   {
      //     "id":"dsad",
      //     "file":"sss"
      //   }
      // ],
    },
    {
      "id": "783247",
      "Submission_Date": "Jan 10, 2023",
      "Legal__Business_Name": "Mongin & Comp llc",
      "DBA": "Phoenix Trucking and Site Development LLC",
      "What_type_of_business_do_you_own": "Limited Liability Company",
      "What_industry_best_matches_your_business": "Heavy and Civil Engineering Construction",
      "Business_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Business_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Business_Tax_ID": "238536815",
      "Business_Phone_Number": "(336) 341-8938",
      "Date_Business_Started": "Jan 10, 2023",
      "Email": "spud0055@gmail.com",
      "Agree_to_our_privacy_policy": "Yes",
      "User_Agent_String": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
      "Signature": "sssss",
      "How_will_you_use_these_funds": "Inventory and equipment",
      "Product__Service_Sold": "Food service",
      "Get_Page_URL": "https://www.seneercapital.com/apply?portal=4&supply=1&template=1&seller=2",
      "Corporate_Officer__Owner_Full_Name": "Peter McClary",
      "Title": "president",
      "Ownership": "100",
      "Home_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Home_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Social_Security_Number_SSN": "353602130",
      "Date_of_Birth": "Mar 06, 1961",
      "Phone_Number": "(413) 530-9590",
      "What_is_your_credit_score": "639 or less",
      "Requested_Funding_Amount": "30000",
      "What_is_your_business_monthly_revenue": "$40,000 to $50,000",
      "Last_3_Month_Business_Bank_Statements": "Files",
      // "Last_3_Month_Business_Bank_Statements": [
      //   {
      //     "id":"dsad",
      //     "file":"sss"
      //   }
      // ],
    },
    {
      "id": "783247",
      "Submission_Date": "Jan 10, 2023",
      "Legal__Business_Name": "Mongin & Comp llc",
      "DBA": "Phoenix Trucking and Site Development LLC",
      "What_type_of_business_do_you_own": "Limited Liability Company",
      "What_industry_best_matches_your_business": "Heavy and Civil Engineering Construction",
      "Business_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Business_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Business_Tax_ID": "238536815",
      "Business_Phone_Number": "(336) 341-8938",
      "Date_Business_Started": "Jan 10, 2023",
      "Email": "spud0055@gmail.com",
      "Agree_to_our_privacy_policy": "Yes",
      "User_Agent_String": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
      "Signature": "sssss",
      "How_will_you_use_these_funds": "Inventory and equipment",
      "Product__Service_Sold": "Food service",
      "Get_Page_URL": "https://www.seneercapital.com/apply?portal=4&supply=1&template=1&seller=2",
      "Corporate_Officer__Owner_Full_Name": "Peter McClary",
      "Title": "president",
      "Ownership": "100",
      "Home_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Home_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Social_Security_Number_SSN": "353602130",
      "Date_of_Birth": "Mar 06, 1961",
      "Phone_Number": "(413) 530-9590",
      "What_is_your_credit_score": "639 or less",
      "Requested_Funding_Amount": "30000",
      "What_is_your_business_monthly_revenue": "$40,000 to $50,000",
      "Last_3_Month_Business_Bank_Statements": "Files",
      // "Last_3_Month_Business_Bank_Statements": [
      //   {
      //     "id":"dsad",
      //     "file":"sss"
      //   }
      // ],
    },
    {
      "id": "783247",
      "Submission_Date": "Jan 10, 2023",
      "Legal__Business_Name": "Mongin & Comp llc",
      "DBA": "Phoenix Trucking and Site Development LLC",
      "What_type_of_business_do_you_own": "Limited Liability Company",
      "What_industry_best_matches_your_business": "Heavy and Civil Engineering Construction",
      "Business_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Business_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Business_Tax_ID": "238536815",
      "Business_Phone_Number": "(336) 341-8938",
      "Date_Business_Started": "Jan 10, 2023",
      "Email": "spud0055@gmail.com",
      "Agree_to_our_privacy_policy": "Yes",
      "User_Agent_String": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
      "Signature": "sssss",
      "How_will_you_use_these_funds": "Inventory and equipment",
      "Product__Service_Sold": "Food service",
      "Get_Page_URL": "https://www.seneercapital.com/apply?portal=4&supply=1&template=1&seller=2",
      "Corporate_Officer__Owner_Full_Name": "Peter McClary",
      "Title": "president",
      "Ownership": "100",
      "Home_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Home_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Social_Security_Number_SSN": "353602130",
      "Date_of_Birth": "Mar 06, 1961",
      "Phone_Number": "(413) 530-9590",
      "What_is_your_credit_score": "639 or less",
      "Requested_Funding_Amount": "30000",
      "What_is_your_business_monthly_revenue": "$40,000 to $50,000",
      "Last_3_Month_Business_Bank_Statements": "Files",
      // "Last_3_Month_Business_Bank_Statements": [
      //   {
      //     "id":"dsad",
      //     "file":"sss"
      //   }
      // ],
    },
    {
      "id": "783247",
      "Submission_Date": "Jan 10, 2023",
      "Legal__Business_Name": "Mongin & Comp llc",
      "DBA": "Phoenix Trucking and Site Development LLC",
      "What_type_of_business_do_you_own": "Limited Liability Company",
      "What_industry_best_matches_your_business": "Heavy and Civil Engineering Construction",
      "Business_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Business_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Business_Tax_ID": "238536815",
      "Business_Phone_Number": "(336) 341-8938",
      "Date_Business_Started": "Jan 10, 2023",
      "Email": "spud0055@gmail.com",
      "Agree_to_our_privacy_policy": "Yes",
      "User_Agent_String": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
      "Signature": "sssss",
      "How_will_you_use_these_funds": "Inventory and equipment",
      "Product__Service_Sold": "Food service",
      "Get_Page_URL": "https://www.seneercapital.com/apply?portal=4&supply=1&template=1&seller=2",
      "Corporate_Officer__Owner_Full_Name": "Peter McClary",
      "Title": "president",
      "Ownership": "100",
      "Home_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Home_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Social_Security_Number_SSN": "353602130",
      "Date_of_Birth": "Mar 06, 1961",
      "Phone_Number": "(413) 530-9590",
      "What_is_your_credit_score": "639 or less",
      "Requested_Funding_Amount": "30000",
      "What_is_your_business_monthly_revenue": "$40,000 to $50,000",
      "Last_3_Month_Business_Bank_Statements": "Files",
      // "Last_3_Month_Business_Bank_Statements": [
      //   {
      //     "id":"dsad",
      //     "file":"sss"
      //   }
      // ],
    },
    {
      "id": "783247",
      "Submission_Date": "Jan 10, 2023",
      "Legal__Business_Name": "Mongin & Comp llc",
      "DBA": "Phoenix Trucking and Site Development LLC",
      "What_type_of_business_do_you_own": "Limited Liability Company",
      "What_industry_best_matches_your_business": "Heavy and Civil Engineering Construction",
      "Business_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Business_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Business_Tax_ID": "238536815",
      "Business_Phone_Number": "(336) 341-8938",
      "Date_Business_Started": "Jan 10, 2023",
      "Email": "spud0055@gmail.com",
      "Agree_to_our_privacy_policy": "Yes",
      "User_Agent_String": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
      "Signature": "sssss",
      "How_will_you_use_these_funds": "Inventory and equipment",
      "Product__Service_Sold": "Food service",
      "Get_Page_URL": "https://www.seneercapital.com/apply?portal=4&supply=1&template=1&seller=2",
      "Corporate_Officer__Owner_Full_Name": "Peter McClary",
      "Title": "president",
      "Ownership": "100",
      "Home_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Home_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Social_Security_Number_SSN": "353602130",
      "Date_of_Birth": "Mar 06, 1961",
      "Phone_Number": "(413) 530-9590",
      "What_is_your_credit_score": "639 or less",
      "Requested_Funding_Amount": "30000",
      "What_is_your_business_monthly_revenue": "$40,000 to $50,000",
      "Last_3_Month_Business_Bank_Statements": "Files",
      // "Last_3_Month_Business_Bank_Statements": [
      //   {
      //     "id":"dsad",
      //     "file":"sss"
      //   }
      // ],
    },
    {
      "id": "783247",
      "Submission_Date": "Jan 10, 2023",
      "Legal__Business_Name": "Mongin & Comp llc",
      "DBA": "Phoenix Trucking and Site Development LLC",
      "What_type_of_business_do_you_own": "Limited Liability Company",
      "What_industry_best_matches_your_business": "Heavy and Civil Engineering Construction",
      "Business_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Business_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Business_Tax_ID": "238536815",
      "Business_Phone_Number": "(336) 341-8938",
      "Date_Business_Started": "Jan 10, 2023",
      "Email": "spud0055@gmail.com",
      "Agree_to_our_privacy_policy": "Yes",
      "User_Agent_String": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
      "Signature": "sssss",
      "How_will_you_use_these_funds": "Inventory and equipment",
      "Product__Service_Sold": "Food service",
      "Get_Page_URL": "https://www.seneercapital.com/apply?portal=4&supply=1&template=1&seller=2",
      "Corporate_Officer__Owner_Full_Name": "Peter McClary",
      "Title": "president",
      "Ownership": "100",
      "Home_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Home_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Social_Security_Number_SSN": "353602130",
      "Date_of_Birth": "Mar 06, 1961",
      "Phone_Number": "(413) 530-9590",
      "What_is_your_credit_score": "639 or less",
      "Requested_Funding_Amount": "30000",
      "What_is_your_business_monthly_revenue": "$40,000 to $50,000",
      "Last_3_Month_Business_Bank_Statements": "Files",
      // "Last_3_Month_Business_Bank_Statements": [
      //   {
      //     "id":"dsad",
      //     "file":"sss"
      //   }
      // ],
    },
    {
      "id": "783247",
      "Submission_Date": "Jan 10, 2023",
      "Legal__Business_Name": "Mongin & Comp llc",
      "DBA": "Phoenix Trucking and Site Development LLC",
      "What_type_of_business_do_you_own": "Limited Liability Company",
      "What_industry_best_matches_your_business": "Heavy and Civil Engineering Construction",
      "Business_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Business_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Business_Tax_ID": "238536815",
      "Business_Phone_Number": "(336) 341-8938",
      "Date_Business_Started": "Jan 10, 2023",
      "Email": "spud0055@gmail.com",
      "Agree_to_our_privacy_policy": "Yes",
      "User_Agent_String": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
      "Signature": "sssss",
      "How_will_you_use_these_funds": "Inventory and equipment",
      "Product__Service_Sold": "Food service",
      "Get_Page_URL": "https://www.seneercapital.com/apply?portal=4&supply=1&template=1&seller=2",
      "Corporate_Officer__Owner_Full_Name": "Peter McClary",
      "Title": "president",
      "Ownership": "100",
      "Home_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Home_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Social_Security_Number_SSN": "353602130",
      "Date_of_Birth": "Mar 06, 1961",
      "Phone_Number": "(413) 530-9590",
      "What_is_your_credit_score": "639 or less",
      "Requested_Funding_Amount": "30000",
      "What_is_your_business_monthly_revenue": "$40,000 to $50,000",
      "Last_3_Month_Business_Bank_Statements": "Files",
      // "Last_3_Month_Business_Bank_Statements": [
      //   {
      //     "id":"dsad",
      //     "file":"sss"
      //   }
      // ],
    },
    {
      "id": "783247",
      "Submission_Date": "Jan 10, 2023",
      "Legal__Business_Name": "Mongin & Comp llc",
      "DBA": "Phoenix Trucking and Site Development LLC",
      "What_type_of_business_do_you_own": "Limited Liability Company",
      "What_industry_best_matches_your_business": "Heavy and Civil Engineering Construction",
      "Business_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Business_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Business_Tax_ID": "238536815",
      "Business_Phone_Number": "(336) 341-8938",
      "Date_Business_Started": "Jan 10, 2023",
      "Email": "spud0055@gmail.com",
      "Agree_to_our_privacy_policy": "Yes",
      "User_Agent_String": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
      "Signature": "sssss",
      "How_will_you_use_these_funds": "Inventory and equipment",
      "Product__Service_Sold": "Food service",
      "Get_Page_URL": "https://www.seneercapital.com/apply?portal=4&supply=1&template=1&seller=2",
      "Corporate_Officer__Owner_Full_Name": "Peter McClary",
      "Title": "president",
      "Ownership": "100",
      "Home_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Home_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Social_Security_Number_SSN": "353602130",
      "Date_of_Birth": "Mar 06, 1961",
      "Phone_Number": "(413) 530-9590",
      "What_is_your_credit_score": "639 or less",
      "Requested_Funding_Amount": "30000",
      "What_is_your_business_monthly_revenue": "$40,000 to $50,000",
      "Last_3_Month_Business_Bank_Statements": "Files",
      // "Last_3_Month_Business_Bank_Statements": [
      //   {
      //     "id":"dsad",
      //     "file":"sss"
      //   }
      // ],
    },
    {
      "id": "783247",
      "Submission_Date": "Jan 10, 2023",
      "Legal__Business_Name": "Mongin & Comp llc",
      "DBA": "Phoenix Trucking and Site Development LLC",
      "What_type_of_business_do_you_own": "Limited Liability Company",
      "What_industry_best_matches_your_business": "Heavy and Civil Engineering Construction",
      "Business_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Business_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Business_Tax_ID": "238536815",
      "Business_Phone_Number": "(336) 341-8938",
      "Date_Business_Started": "Jan 10, 2023",
      "Email": "spud0055@gmail.com",
      "Agree_to_our_privacy_policy": "Yes",
      "User_Agent_String": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
      "Signature": "sssss",
      "How_will_you_use_these_funds": "Inventory and equipment",
      "Product__Service_Sold": "Food service",
      "Get_Page_URL": "https://www.seneercapital.com/apply?portal=4&supply=1&template=1&seller=2",
      "Corporate_Officer__Owner_Full_Name": "Peter McClary",
      "Title": "president",
      "Ownership": "100",
      "Home_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Home_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Social_Security_Number_SSN": "353602130",
      "Date_of_Birth": "Mar 06, 1961",
      "Phone_Number": "(413) 530-9590",
      "What_is_your_credit_score": "639 or less",
      "Requested_Funding_Amount": "30000",
      "What_is_your_business_monthly_revenue": "$40,000 to $50,000",
      "Last_3_Month_Business_Bank_Statements": "Files",
      // "Last_3_Month_Business_Bank_Statements": [
      //   {
      //     "id":"dsad",
      //     "file":"sss"
      //   }
      // ],
    },
    {
      "id": "783247",
      "Submission_Date": "Jan 10, 2023",
      "Legal__Business_Name": "Mongin & Comp llc",
      "DBA": "Phoenix Trucking and Site Development LLC",
      "What_type_of_business_do_you_own": "Limited Liability Company",
      "What_industry_best_matches_your_business": "Heavy and Civil Engineering Construction",
      "Business_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Business_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Business_Tax_ID": "238536815",
      "Business_Phone_Number": "(336) 341-8938",
      "Date_Business_Started": "Jan 10, 2023",
      "Email": "spud0055@gmail.com",
      "Agree_to_our_privacy_policy": "Yes",
      "User_Agent_String": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
      "Signature": "sssss",
      "How_will_you_use_these_funds": "Inventory and equipment",
      "Product__Service_Sold": "Food service",
      "Get_Page_URL": "https://www.seneercapital.com/apply?portal=4&supply=1&template=1&seller=2",
      "Corporate_Officer__Owner_Full_Name": "Peter McClary",
      "Title": "president",
      "Ownership": "100",
      "Home_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Home_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Social_Security_Number_SSN": "353602130",
      "Date_of_Birth": "Mar 06, 1961",
      "Phone_Number": "(413) 530-9590",
      "What_is_your_credit_score": "639 or less",
      "Requested_Funding_Amount": "30000",
      "What_is_your_business_monthly_revenue": "$40,000 to $50,000",
      "Last_3_Month_Business_Bank_Statements": "Files",
      // "Last_3_Month_Business_Bank_Statements": [
      //   {
      //     "id":"dsad",
      //     "file":"sss"
      //   }
      // ],
    },
    {
      "id": "783247",
      "Submission_Date": "Jan 10, 2023",
      "Legal__Business_Name": "Mongin & Comp llc",
      "DBA": "Phoenix Trucking and Site Development LLC",
      "What_type_of_business_do_you_own": "Limited Liability Company",
      "What_industry_best_matches_your_business": "Heavy and Civil Engineering Construction",
      "Business_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Business_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Business_Tax_ID": "238536815",
      "Business_Phone_Number": "(336) 341-8938",
      "Date_Business_Started": "Jan 10, 2023",
      "Email": "spud0055@gmail.com",
      "Agree_to_our_privacy_policy": "Yes",
      "User_Agent_String": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
      "Signature": "sssss",
      "How_will_you_use_these_funds": "Inventory and equipment",
      "Product__Service_Sold": "Food service",
      "Get_Page_URL": "https://www.seneercapital.com/apply?portal=4&supply=1&template=1&seller=2",
      "Corporate_Officer__Owner_Full_Name": "Peter McClary",
      "Title": "president",
      "Ownership": "100",
      "Home_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Home_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Social_Security_Number_SSN": "353602130",
      "Date_of_Birth": "Mar 06, 1961",
      "Phone_Number": "(413) 530-9590",
      "What_is_your_credit_score": "639 or less",
      "Requested_Funding_Amount": "30000",
      "What_is_your_business_monthly_revenue": "$40,000 to $50,000",
      "Last_3_Month_Business_Bank_Statements": "Files",
      // "Last_3_Month_Business_Bank_Statements": [
      //   {
      //     "id":"dsad",
      //     "file":"sss"
      //   }
      // ],
    },
    {
      "id": "783247",
      "Submission_Date": "Jan 10, 2023",
      "Legal__Business_Name": "Mongin & Comp llc",
      "DBA": "Phoenix Trucking and Site Development LLC",
      "What_type_of_business_do_you_own": "Limited Liability Company",
      "What_industry_best_matches_your_business": "Heavy and Civil Engineering Construction",
      "Business_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Business_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Business_Tax_ID": "238536815",
      "Business_Phone_Number": "(336) 341-8938",
      "Date_Business_Started": "Jan 10, 2023",
      "Email": "spud0055@gmail.com",
      "Agree_to_our_privacy_policy": "Yes",
      "User_Agent_String": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
      "Signature": "sssss",
      "How_will_you_use_these_funds": "Inventory and equipment",
      "Product__Service_Sold": "Food service",
      "Get_Page_URL": "https://www.seneercapital.com/apply?portal=4&supply=1&template=1&seller=2",
      "Corporate_Officer__Owner_Full_Name": "Peter McClary",
      "Title": "president",
      "Ownership": "100",
      "Home_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Home_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Social_Security_Number_SSN": "353602130",
      "Date_of_Birth": "Mar 06, 1961",
      "Phone_Number": "(413) 530-9590",
      "What_is_your_credit_score": "639 or less",
      "Requested_Funding_Amount": "30000",
      "What_is_your_business_monthly_revenue": "$40,000 to $50,000",
      "Last_3_Month_Business_Bank_Statements": "Files",
      // "Last_3_Month_Business_Bank_Statements": [
      //   {
      //     "id":"dsad",
      //     "file":"sss"
      //   }
      // ],
    },
    {
      "id": "783247",
      "Submission_Date": "Jan 10, 2023",
      "Legal__Business_Name": "Mongin & Comp llc",
      "DBA": "Phoenix Trucking and Site Development LLC",
      "What_type_of_business_do_you_own": "Limited Liability Company",
      "What_industry_best_matches_your_business": "Heavy and Civil Engineering Construction",
      "Business_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Business_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Business_Tax_ID": "238536815",
      "Business_Phone_Number": "(336) 341-8938",
      "Date_Business_Started": "Jan 10, 2023",
      "Email": "spud0055@gmail.com",
      "Agree_to_our_privacy_policy": "Yes",
      "User_Agent_String": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
      "Signature": "sssss",
      "How_will_you_use_these_funds": "Inventory and equipment",
      "Product__Service_Sold": "Food service",
      "Get_Page_URL": "https://www.seneercapital.com/apply?portal=4&supply=1&template=1&seller=2",
      "Corporate_Officer__Owner_Full_Name": "Peter McClary",
      "Title": "president",
      "Ownership": "100",
      "Home_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Home_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Social_Security_Number_SSN": "353602130",
      "Date_of_Birth": "Mar 06, 1961",
      "Phone_Number": "(413) 530-9590",
      "What_is_your_credit_score": "639 or less",
      "Requested_Funding_Amount": "30000",
      "What_is_your_business_monthly_revenue": "$40,000 to $50,000",
      "Last_3_Month_Business_Bank_Statements": "Files",
      // "Last_3_Month_Business_Bank_Statements": [
      //   {
      //     "id":"dsad",
      //     "file":"sss"
      //   }
      // ],
    },
    {
      "id": "783247",
      "Submission_Date": "Jan 10, 2023",
      "Legal__Business_Name": "Mongin & Comp llc",
      "DBA": "Phoenix Trucking and Site Development LLC",
      "What_type_of_business_do_you_own": "Limited Liability Company",
      "What_industry_best_matches_your_business": "Heavy and Civil Engineering Construction",
      "Business_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Business_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Business_Tax_ID": "238536815",
      "Business_Phone_Number": "(336) 341-8938",
      "Date_Business_Started": "Jan 10, 2023",
      "Email": "spud0055@gmail.com",
      "Agree_to_our_privacy_policy": "Yes",
      "User_Agent_String": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
      "Signature": "sssss",
      "How_will_you_use_these_funds": "Inventory and equipment",
      "Product__Service_Sold": "Food service",
      "Get_Page_URL": "https://www.seneercapital.com/apply?portal=4&supply=1&template=1&seller=2",
      "Corporate_Officer__Owner_Full_Name": "Peter McClary",
      "Title": "president",
      "Ownership": "100",
      "Home_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Home_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Social_Security_Number_SSN": "353602130",
      "Date_of_Birth": "Mar 06, 1961",
      "Phone_Number": "(413) 530-9590",
      "What_is_your_credit_score": "639 or less",
      "Requested_Funding_Amount": "30000",
      "What_is_your_business_monthly_revenue": "$40,000 to $50,000",
      "Last_3_Month_Business_Bank_Statements": "Files",
      // "Last_3_Month_Business_Bank_Statements": [
      //   {
      //     "id":"dsad",
      //     "file":"sss"
      //   }
      // ],
    },
    {
      "id": "783247",
      "Submission_Date": "Jan 10, 2023",
      "Legal__Business_Name": "Mongin & Comp llc",
      "DBA": "Phoenix Trucking and Site Development LLC",
      "What_type_of_business_do_you_own": "Limited Liability Company",
      "What_industry_best_matches_your_business": "Heavy and Civil Engineering Construction",
      "Business_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Business_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Business_Tax_ID": "238536815",
      "Business_Phone_Number": "(336) 341-8938",
      "Date_Business_Started": "Jan 10, 2023",
      "Email": "spud0055@gmail.com",
      "Agree_to_our_privacy_policy": "Yes",
      "User_Agent_String": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
      "Signature": "sssss",
      "How_will_you_use_these_funds": "Inventory and equipment",
      "Product__Service_Sold": "Food service",
      "Get_Page_URL": "https://www.seneercapital.com/apply?portal=4&supply=1&template=1&seller=2",
      "Corporate_Officer__Owner_Full_Name": "Peter McClary",
      "Title": "president",
      "Ownership": "100",
      "Home_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Home_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Social_Security_Number_SSN": "353602130",
      "Date_of_Birth": "Mar 06, 1961",
      "Phone_Number": "(413) 530-9590",
      "What_is_your_credit_score": "639 or less",
      "Requested_Funding_Amount": "30000",
      "What_is_your_business_monthly_revenue": "$40,000 to $50,000",
      "Last_3_Month_Business_Bank_Statements": "Files",
      // "Last_3_Month_Business_Bank_Statements": [
      //   {
      //     "id":"dsad",
      //     "file":"sss"
      //   }
      // ],
    },
    {
      "id": "783247",
      "Submission_Date": "Jan 10, 2023",
      "Legal__Business_Name": "Mongin & Comp llc",
      "DBA": "Phoenix Trucking and Site Development LLC",
      "What_type_of_business_do_you_own": "Limited Liability Company",
      "What_industry_best_matches_your_business": "Heavy and Civil Engineering Construction",
      "Business_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Business_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Business_Tax_ID": "238536815",
      "Business_Phone_Number": "(336) 341-8938",
      "Date_Business_Started": "Jan 10, 2023",
      "Email": "spud0055@gmail.com",
      "Agree_to_our_privacy_policy": "Yes",
      "User_Agent_String": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
      "Signature": "sssss",
      "How_will_you_use_these_funds": "Inventory and equipment",
      "Product__Service_Sold": "Food service",
      "Get_Page_URL": "https://www.seneercapital.com/apply?portal=4&supply=1&template=1&seller=2",
      "Corporate_Officer__Owner_Full_Name": "Peter McClary",
      "Title": "president",
      "Ownership": "100",
      "Home_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Home_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Social_Security_Number_SSN": "353602130",
      "Date_of_Birth": "Mar 06, 1961",
      "Phone_Number": "(413) 530-9590",
      "What_is_your_credit_score": "639 or less",
      "Requested_Funding_Amount": "30000",
      "What_is_your_business_monthly_revenue": "$40,000 to $50,000",
      "Last_3_Month_Business_Bank_Statements": "Files",
      // "Last_3_Month_Business_Bank_Statements": [
      //   {
      //     "id":"dsad",
      //     "file":"sss"
      //   }
      // ],
    },
    {
      "id": "783247",
      "Submission_Date": "Jan 10, 2023",
      "Legal__Business_Name": "Mongin & Comp llc",
      "DBA": "Phoenix Trucking and Site Development LLC",
      "What_type_of_business_do_you_own": "Limited Liability Company",
      "What_industry_best_matches_your_business": "Heavy and Civil Engineering Construction",
      "Business_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Business_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Business_Tax_ID": "238536815",
      "Business_Phone_Number": "(336) 341-8938",
      "Date_Business_Started": "Jan 10, 2023",
      "Email": "spud0055@gmail.com",
      "Agree_to_our_privacy_policy": "Yes",
      "User_Agent_String": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
      "Signature": "sssss",
      "How_will_you_use_these_funds": "Inventory and equipment",
      "Product__Service_Sold": "Food service",
      "Get_Page_URL": "https://www.seneercapital.com/apply?portal=4&supply=1&template=1&seller=2",
      "Corporate_Officer__Owner_Full_Name": "Peter McClary",
      "Title": "president",
      "Ownership": "100",
      "Home_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Home_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Social_Security_Number_SSN": "353602130",
      "Date_of_Birth": "Mar 06, 1961",
      "Phone_Number": "(413) 530-9590",
      "What_is_your_credit_score": "639 or less",
      "Requested_Funding_Amount": "30000",
      "What_is_your_business_monthly_revenue": "$40,000 to $50,000",
      "Last_3_Month_Business_Bank_Statements": "Files",
      // "Last_3_Month_Business_Bank_Statements": [
      //   {
      //     "id":"dsad",
      //     "file":"sss"
      //   }
      // ],
    },
    {
      "id": "783247",
      "Submission_Date": "Jan 10, 2023",
      "Legal__Business_Name": "Mongin & Comp llc",
      "DBA": "Phoenix Trucking and Site Development LLC",
      "What_type_of_business_do_you_own": "Limited Liability Company",
      "What_industry_best_matches_your_business": "Heavy and Civil Engineering Construction",
      "Business_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Business_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Business_Tax_ID": "238536815",
      "Business_Phone_Number": "(336) 341-8938",
      "Date_Business_Started": "Jan 10, 2023",
      "Email": "spud0055@gmail.com",
      "Agree_to_our_privacy_policy": "Yes",
      "User_Agent_String": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
      "Signature": "sssss",
      "How_will_you_use_these_funds": "Inventory and equipment",
      "Product__Service_Sold": "Food service",
      "Get_Page_URL": "https://www.seneercapital.com/apply?portal=4&supply=1&template=1&seller=2",
      "Corporate_Officer__Owner_Full_Name": "Peter McClary",
      "Title": "president",
      "Ownership": "100",
      "Home_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Home_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Social_Security_Number_SSN": "353602130",
      "Date_of_Birth": "Mar 06, 1961",
      "Phone_Number": "(413) 530-9590",
      "What_is_your_credit_score": "639 or less",
      "Requested_Funding_Amount": "30000",
      "What_is_your_business_monthly_revenue": "$40,000 to $50,000",
      "Last_3_Month_Business_Bank_Statements": "Files",
      // "Last_3_Month_Business_Bank_Statements": [
      //   {
      //     "id":"dsad",
      //     "file":"sss"
      //   }
      // ],
    },
    {
      "id": "783247",
      "Submission_Date": "Jan 10, 2023",
      "Legal__Business_Name": "Mongin & Comp llc",
      "DBA": "Phoenix Trucking and Site Development LLC",
      "What_type_of_business_do_you_own": "Limited Liability Company",
      "What_industry_best_matches_your_business": "Heavy and Civil Engineering Construction",
      "Business_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Business_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Business_Tax_ID": "238536815",
      "Business_Phone_Number": "(336) 341-8938",
      "Date_Business_Started": "Jan 10, 2023",
      "Email": "spud0055@gmail.com",
      "Agree_to_our_privacy_policy": "Yes",
      "User_Agent_String": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
      "Signature": "sssss",
      "How_will_you_use_these_funds": "Inventory and equipment",
      "Product__Service_Sold": "Food service",
      "Get_Page_URL": "https://www.seneercapital.com/apply?portal=4&supply=1&template=1&seller=2",
      "Corporate_Officer__Owner_Full_Name": "Peter McClary",
      "Title": "president",
      "Ownership": "100",
      "Home_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Home_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Social_Security_Number_SSN": "353602130",
      "Date_of_Birth": "Mar 06, 1961",
      "Phone_Number": "(413) 530-9590",
      "What_is_your_credit_score": "639 or less",
      "Requested_Funding_Amount": "30000",
      "What_is_your_business_monthly_revenue": "$40,000 to $50,000",
      "Last_3_Month_Business_Bank_Statements": "Files",
      // "Last_3_Month_Business_Bank_Statements": [
      //   {
      //     "id":"dsad",
      //     "file":"sss"
      //   }
      // ],
    },
    {
      "id": "783247",
      "Submission_Date": "Jan 10, 2023",
      "Legal__Business_Name": "Mongin & Comp llc",
      "DBA": "Phoenix Trucking and Site Development LLC",
      "What_type_of_business_do_you_own": "Limited Liability Company",
      "What_industry_best_matches_your_business": "Heavy and Civil Engineering Construction",
      "Business_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Business_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Business_Tax_ID": "238536815",
      "Business_Phone_Number": "(336) 341-8938",
      "Date_Business_Started": "Jan 10, 2023",
      "Email": "spud0055@gmail.com",
      "Agree_to_our_privacy_policy": "Yes",
      "User_Agent_String": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
      "Signature": "sssss",
      "How_will_you_use_these_funds": "Inventory and equipment",
      "Product__Service_Sold": "Food service",
      "Get_Page_URL": "https://www.seneercapital.com/apply?portal=4&supply=1&template=1&seller=2",
      "Corporate_Officer__Owner_Full_Name": "Peter McClary",
      "Title": "president",
      "Ownership": "100",
      "Home_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Home_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Social_Security_Number_SSN": "353602130",
      "Date_of_Birth": "Mar 06, 1961",
      "Phone_Number": "(413) 530-9590",
      "What_is_your_credit_score": "639 or less",
      "Requested_Funding_Amount": "30000",
      "What_is_your_business_monthly_revenue": "$40,000 to $50,000",
      "Last_3_Month_Business_Bank_Statements": "Files",
      // "Last_3_Month_Business_Bank_Statements": [
      //   {
      //     "id":"dsad",
      //     "file":"sss"
      //   }
      // ],
    },
    {
      "id": "783247",
      "Submission_Date": "Jan 10, 2023",
      "Legal__Business_Name": "Mongin & Comp llc",
      "DBA": "Phoenix Trucking and Site Development LLC",
      "What_type_of_business_do_you_own": "Limited Liability Company",
      "What_industry_best_matches_your_business": "Heavy and Civil Engineering Construction",
      "Business_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Business_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Business_Tax_ID": "238536815",
      "Business_Phone_Number": "(336) 341-8938",
      "Date_Business_Started": "Jan 10, 2023",
      "Email": "spud0055@gmail.com",
      "Agree_to_our_privacy_policy": "Yes",
      "User_Agent_String": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
      "Signature": "sssss",
      "How_will_you_use_these_funds": "Inventory and equipment",
      "Product__Service_Sold": "Food service",
      "Get_Page_URL": "https://www.seneercapital.com/apply?portal=4&supply=1&template=1&seller=2",
      "Corporate_Officer__Owner_Full_Name": "Peter McClary",
      "Title": "president",
      "Ownership": "100",
      "Home_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Home_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Social_Security_Number_SSN": "353602130",
      "Date_of_Birth": "Mar 06, 1961",
      "Phone_Number": "(413) 530-9590",
      "What_is_your_credit_score": "639 or less",
      "Requested_Funding_Amount": "30000",
      "What_is_your_business_monthly_revenue": "$40,000 to $50,000",
      "Last_3_Month_Business_Bank_Statements": "Files",
      // "Last_3_Month_Business_Bank_Statements": [
      //   {
      //     "id":"dsad",
      //     "file":"sss"
      //   }
      // ],
    },
    {
      "id": "783247",
      "Submission_Date": "Jan 10, 2023",
      "Legal__Business_Name": "Mongin & Comp llc",
      "DBA": "Phoenix Trucking and Site Development LLC",
      "What_type_of_business_do_you_own": "Limited Liability Company",
      "What_industry_best_matches_your_business": "Heavy and Civil Engineering Construction",
      "Business_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Business_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Business_Tax_ID": "238536815",
      "Business_Phone_Number": "(336) 341-8938",
      "Date_Business_Started": "Jan 10, 2023",
      "Email": "spud0055@gmail.com",
      "Agree_to_our_privacy_policy": "Yes",
      "User_Agent_String": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
      "Signature": "sssss",
      "How_will_you_use_these_funds": "Inventory and equipment",
      "Product__Service_Sold": "Food service",
      "Get_Page_URL": "https://www.seneercapital.com/apply?portal=4&supply=1&template=1&seller=2",
      "Corporate_Officer__Owner_Full_Name": "Peter McClary",
      "Title": "president",
      "Ownership": "100",
      "Home_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Home_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Social_Security_Number_SSN": "353602130",
      "Date_of_Birth": "Mar 06, 1961",
      "Phone_Number": "(413) 530-9590",
      "What_is_your_credit_score": "639 or less",
      "Requested_Funding_Amount": "30000",
      "What_is_your_business_monthly_revenue": "$40,000 to $50,000",
      "Last_3_Month_Business_Bank_Statements": "Files",
      // "Last_3_Month_Business_Bank_Statements": [
      //   {
      //     "id":"dsad",
      //     "file":"sss"
      //   }
      // ],
    },
    {
      "id": "783247",
      "Submission_Date": "Jan 10, 2023",
      "Legal__Business_Name": "Mongin & Comp llc",
      "DBA": "Phoenix Trucking and Site Development LLC",
      "What_type_of_business_do_you_own": "Limited Liability Company",
      "What_industry_best_matches_your_business": "Heavy and Civil Engineering Construction",
      "Business_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Business_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Business_Tax_ID": "238536815",
      "Business_Phone_Number": "(336) 341-8938",
      "Date_Business_Started": "Jan 10, 2023",
      "Email": "spud0055@gmail.com",
      "Agree_to_our_privacy_policy": "Yes",
      "User_Agent_String": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
      "Signature": "sssss",
      "How_will_you_use_these_funds": "Inventory and equipment",
      "Product__Service_Sold": "Food service",
      "Get_Page_URL": "https://www.seneercapital.com/apply?portal=4&supply=1&template=1&seller=2",
      "Corporate_Officer__Owner_Full_Name": "Peter McClary",
      "Title": "president",
      "Ownership": "100",
      "Home_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Home_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Social_Security_Number_SSN": "353602130",
      "Date_of_Birth": "Mar 06, 1961",
      "Phone_Number": "(413) 530-9590",
      "What_is_your_credit_score": "639 or less",
      "Requested_Funding_Amount": "30000",
      "What_is_your_business_monthly_revenue": "$40,000 to $50,000",
      "Last_3_Month_Business_Bank_Statements": "Files",
      // "Last_3_Month_Business_Bank_Statements": [
      //   {
      //     "id":"dsad",
      //     "file":"sss"
      //   }
      // ],
    },
    {
      "id": "783247",
      "Submission_Date": "Jan 10, 2023",
      "Legal__Business_Name": "Mongin & Comp llc",
      "DBA": "Phoenix Trucking and Site Development LLC",
      "What_type_of_business_do_you_own": "Limited Liability Company",
      "What_industry_best_matches_your_business": "Heavy and Civil Engineering Construction",
      "Business_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Business_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Business_Tax_ID": "238536815",
      "Business_Phone_Number": "(336) 341-8938",
      "Date_Business_Started": "Jan 10, 2023",
      "Email": "spud0055@gmail.com",
      "Agree_to_our_privacy_policy": "Yes",
      "User_Agent_String": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
      "Signature": "sssss",
      "How_will_you_use_these_funds": "Inventory and equipment",
      "Product__Service_Sold": "Food service",
      "Get_Page_URL": "https://www.seneercapital.com/apply?portal=4&supply=1&template=1&seller=2",
      "Corporate_Officer__Owner_Full_Name": "Peter McClary",
      "Title": "president",
      "Ownership": "100",
      "Home_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Home_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Social_Security_Number_SSN": "353602130",
      "Date_of_Birth": "Mar 06, 1961",
      "Phone_Number": "(413) 530-9590",
      "What_is_your_credit_score": "639 or less",
      "Requested_Funding_Amount": "30000",
      "What_is_your_business_monthly_revenue": "$40,000 to $50,000",
      "Last_3_Month_Business_Bank_Statements": "Files",
      // "Last_3_Month_Business_Bank_Statements": [
      //   {
      //     "id":"dsad",
      //     "file":"sss"
      //   }
      // ],
    },
    {
      "id": "783247",
      "Submission_Date": "Jan 10, 2023",
      "Legal__Business_Name": "Mongin & Comp llc",
      "DBA": "Phoenix Trucking and Site Development LLC",
      "What_type_of_business_do_you_own": "Limited Liability Company",
      "What_industry_best_matches_your_business": "Heavy and Civil Engineering Construction",
      "Business_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Business_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Business_Tax_ID": "238536815",
      "Business_Phone_Number": "(336) 341-8938",
      "Date_Business_Started": "Jan 10, 2023",
      "Email": "spud0055@gmail.com",
      "Agree_to_our_privacy_policy": "Yes",
      "User_Agent_String": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
      "Signature": "sssss",
      "How_will_you_use_these_funds": "Inventory and equipment",
      "Product__Service_Sold": "Food service",
      "Get_Page_URL": "https://www.seneercapital.com/apply?portal=4&supply=1&template=1&seller=2",
      "Corporate_Officer__Owner_Full_Name": "Peter McClary",
      "Title": "president",
      "Ownership": "100",
      "Home_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Home_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Social_Security_Number_SSN": "353602130",
      "Date_of_Birth": "Mar 06, 1961",
      "Phone_Number": "(413) 530-9590",
      "What_is_your_credit_score": "639 or less",
      "Requested_Funding_Amount": "30000",
      "What_is_your_business_monthly_revenue": "$40,000 to $50,000",
      "Last_3_Month_Business_Bank_Statements": "Files",
      // "Last_3_Month_Business_Bank_Statements": [
      //   {
      //     "id":"dsad",
      //     "file":"sss"
      //   }
      // ],
    },
    {
      "id": "783247",
      "Submission_Date": "Jan 10, 2023",
      "Legal__Business_Name": "Mongin & Comp llc",
      "DBA": "Phoenix Trucking and Site Development LLC",
      "What_type_of_business_do_you_own": "Limited Liability Company",
      "What_industry_best_matches_your_business": "Heavy and Civil Engineering Construction",
      "Business_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Business_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Business_Tax_ID": "238536815",
      "Business_Phone_Number": "(336) 341-8938",
      "Date_Business_Started": "Jan 10, 2023",
      "Email": "spud0055@gmail.com",
      "Agree_to_our_privacy_policy": "Yes",
      "User_Agent_String": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
      "Signature": "sssss",
      "How_will_you_use_these_funds": "Inventory and equipment",
      "Product__Service_Sold": "Food service",
      "Get_Page_URL": "https://www.seneercapital.com/apply?portal=4&supply=1&template=1&seller=2",
      "Corporate_Officer__Owner_Full_Name": "Peter McClary",
      "Title": "president",
      "Ownership": "100",
      "Home_Address": "5817 Jones Rd, Los Angeles, CA / California, 90323",
      // "Home_Address": {
      //   "Street_Address": "5817 Jones Rd",
      //   "Street_Address_Line_2": "",
      //   "City": "Los Angeles",
      //   "State": "CA / California",
      //   "Zipcode": "90323"
      // },
      "Social_Security_Number_SSN": "353602130",
      "Date_of_Birth": "Mar 06, 1961",
      "Phone_Number": "(413) 530-9590",
      "What_is_your_credit_score": "639 or less",
      "Requested_Funding_Amount": "30000",
      "What_is_your_business_monthly_revenue": "$40,000 to $50,000",
      "Last_3_Month_Business_Bank_Statements": "Files",
      // "Last_3_Month_Business_Bank_Statements": [
      //   {
      //     "id":"dsad",
      //     "file":"sss"
      //   }
      // ],
    },
  ]);
  const navigateTo = (page) => {
    navigate('/' + page)
    setShowMenu(false)

  }


  useEffect(() => {
    // (async () => {
    //   const AutoUser = await fetch('https://api.jotform.com/user?apiKey=cd3acc5cd5fdba38be6a314732b0b44b', {
    //     headers: {
    //       Accept: "application/json",
    //     }
    //   })
    //   const dataAutoUser = await AutoUser.json()
    //   console.log(dataAutoUser)

    //   const SubbsGet = await fetch('https://api.jotform.com/form/232388604634157/submissions?offset=0&limit=50&apiKey=cd3acc5cd5fdba38be6a314732b0b44b', {
    //     headers: {
    //       Accept: "application/json",
    //     }
    //   })
    //   const dataSubbsGet = await SubbsGet.json()
    //   console.log(dataSubbsGet)
    // })()


    if (showVerify == true) {
      document.body.style.overflow = "hidden"
      document.body.style.overflow = "scroll"
    } else {

      document.body.style.overflow = "scroll"
    }

  }, [showVerify]);

  return (
    <div className="Portal" style={false ? { "document.html.style.overflow": "hidden" } : null}>
      <NavBar />
      <MainPortal
        PortalData={PortalData}
        Datas={Datas}
        ApplicationsData={ApplicationsData}
        SellersData={SellersData}
        navigateTo={navigateTo}
      />
      {/* {location.pathname == "/applications" || location.pathname == "/applications2" ? null : <Footer navigateTo={navigateTo} />} */}
    </div>
  );
}

export default Portal;
