import React, { useState } from 'react'
import './Thanks.scss'
import Logo from '../../Images/Logo.png'

import { Helmet } from "react-helmet";
// var __html = require('./ThanksJS.html');

export default function Thanks({ navigateTo }) {
    const styles = {} // if you want to add some custom CSS
    const URL = "https://form.jotform.com/jsform/231987996785081" //embed URL

    return (
        <div className="Thanks">
            <Helmet>
                {/* <!-- TITLE --> */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Welcome to SENEER Capital Prefered Member®</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="SENEER | Welcome to SENEER Capital Prefered Member®" />
                <meta name="og:title" property="og:title" content="SENEER | Welcome to SENEER Capital Prefered Member®" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" />
                <meta name="og:type" property="og:type" content="website" />
                <meta name="og:image" property="og:image" content="website" />
                {/* <!-- Full link to the current webpage address --> */}
                <meta name="og:url" property="og:url" content="https://www.seneercapital.com/thanks" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Visit our cash advance application page for a streamlined process, ensuring fast and efficient funding tailored to your unique business needs." />
                <meta name="og:description" property="og:description" content="Visit our cash advance application page for a streamlined process, ensuring fast and efficient funding tailored to your unique business needs." />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:site_name" property="og:site_name" content="SENEER | Welcome to SENEER Capital Prefered Member®" />
                <meta name="keywords" content="Financing, Business, Advance, Funding, Cash, Merchant, Capital, Loan, Credit, Repayment" ></meta>
            </Helmet>
            <div className="ThanksBox">
                <img src={Logo} alt="" />
                <h1>Welcome to SENEER Capital Prefered Member®</h1>
                <h3>Thank you for applying for Business Funding we've received your application.</h3>

            </div>

            <div className="HowItWorkMainExplain">
                <div className="HowItWorkMainExplainHeader">
                    <h1>Here’s what happened:</h1>
                    <h2>We received an application for Business funding with SENEER Capital. We're currently reviewing your request</h2>
                </div>
                <div className="HowItWorkMainExplainBody">
                    <div className="HowItWorkMainExplainBodyBoxLine"></div>

                    <div className="HowItWorkMainExplainBodyBox">
                        <div className="HowItWorkMainExplainBodyBoxLineDot"></div>
                        <div className="HowItWorkMainExplainBodyBoxLeft">
                            <h2>STEP 1</h2>
                            <h3>Email has <br /> Been sent</h3>
                        </div>
                        <div className="HowItWorkMainExplainBodyBoxRight">
                            <h4>Confirmation email will be sent to you at this moment, please check your mailbox.</h4>
                        </div>
                    </div>

                    <div className="HowItWorkMainExplainBodyBox">
                        <div className="HowItWorkMainExplainBodyBoxLineDot"></div>
                        <div className="HowItWorkMainExplainBodyBoxLeft">
                            <h2>STEP 2</h2>
                            <h3>Review <br /> & Approve</h3>
                        </div>
                        <div className="HowItWorkMainExplainBodyBoxRight">
                            <h4>After the thorough underwriting process is completed, a highly skilled funding specialist will personally connect with you. They will guide you through the various offers available, tailored specifically to meet the unique needs of your business.</h4>
                        </div>
                    </div>

                    <div className="HowItWorkMainExplainBodyBox">
                        <div className="HowItWorkMainExplainBodyBoxLineDot"></div>
                        <div className="HowItWorkMainExplainBodyBoxLeft">
                            <h2>STEP 3</h2>
                            <h3>Get <br /> Funded</h3>
                        </div>
                        <div className="HowItWorkMainExplainBodyBoxRight">
                            <h4>Once you've accepted the offer, we'll promptly send you a contract that outlines the terms and conditions of the agreement. This contract will be tailored specifically to your chosen offer, ensuring complete transparency and clarity.</h4>
                        </div>
                    </div>
                </div>

                <div className="HowItWorkMainWhyUsBorder"></div>
            </div>

            <div className="ThanksBottom">
                <h6>
                    Sincerely,
                    <br />
                    SENEER Capital
                </h6>
                <h6>
                    We've put together a pages with some helpful resources for you.
                    <br />
                    <br />
                    <a href="blog" target="_blank" >Blog</a> or <a href="faq" target="_blank">FAQ</a>. Need help ? Ask at <a target="_blank" href="mailto:info@seneercapital.com">info@seneercapital.com</a>
                </h6>
                <span>
                    <a target='_blank' href='/'>Take me to Home Page</a>
                </span>

            </div>
        </div>
    )
}


