import React from "react";
import './SuccessStories.scss';
import Arrow2 from '../../Images/next.png'
import Review from '../../Images/Review.png'
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// images
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"


export default function SuccessStories({ FaqList, Blogs }) {
    const location = useLocation()
    const navigate = useNavigate()
    const [getPathValue, setgetPathValue] = useState("");

    const [header, setHeader] = useState(false)
    const listenScrollEvent = () => {
        if (window.scrollY < 50 && window.screen.width < 690) {
            return setHeader(false)
        } else if (window.scrollY > 50 && window.screen.width < 690) {
            return setHeader(true)
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);
        return () =>
            window.removeEventListener('scroll', listenScrollEvent);

    }, [window.scrollY]);

    function readingTime(S) {
        const text = S.Text;
        const wpm = 225;
        const words = text.trim().split(/\s+/).length;
        const time = Math.ceil(words / wpm);
        return time
    }
    // readingTime();

    useEffect(() => {

        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        let value = params.category;
        setgetPathValue(params.category ? params.category : null)
        console.log(value);
        console.log(getPathValue);
    }, []);


    const getSpecificNumberFaq = () => {
        const number = Math.random() * FaqList.length
        if (number <= (FaqList.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumberFaq()
        }
    }
    const [getNumberFaq, setgetNumberFaq] = useState(getSpecificNumberFaq());

    return (

        <div className="SuccessStories">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>SENEER | Capital Success Stories And Magazine</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="SENEER Capital | Success  Stories" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="SENEER Capital | Success Stories" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* SuccessStories Tags */}
                <meta name="PageType" content="article" />
                <meta name="ContentType" content="article" />
                <meta name="Category" content="article" />
                <meta name="SubCategories" content="article" />
                <meta name="FunnelStage" content="upper" />
                <meta name="Objective" content="education, seo" />




                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.seneercapital.com/blog" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Explore the Seneer Capital blog for insightful articles on business funding strategies, tips, and expert advice. Discover innovative ways to secure capital, grow your business, and achieve financial success." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Explore the Seneer Capital blog for insightful articles on business funding strategies, tips, and expert advice. Discover innovative ways to secure capital, grow your business, and achieve financial success." data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="SENEER | Capital Success Stories And Magazine" data-react-helmet="true" />
                <meta name="keywords" content="Financing, Business, Advance, Funding, Cash, Merchant, Capital, Loan, Credit, Repayment, Success Stories" data-react-helmet="true" />
            </Helmet>
            <div className="SuccessStoriesHeader">
                <div className="SuccessStoriesHeaderTop">
                    <h1>Why Businesses Love SENEER Capital</h1>
                    <h3>
                        We're more than just a financial institution – we're your trusted partner in success. Businesses love SENEER Capital for our commitment to empowering growth and providing personalized financial solutions tailored to their unique needs.
                    </h3>
                </div>
                <div className="SuccessStoriesHeaderBottom">
                    <h1>Articles</h1>
                </div>
            </div>
            <div className="SuccessStoriessList">
                {Blogs.filter(a => getPathValue ? a.CategoryPath == getPathValue : a.CategoryPath != getPathValue).reverse().map((A, index) =>
                    <a href={`/blog/${A.BlogPath.replace(/ /g, "_").toLowerCase() + "_" + (A.BlogId - 1)}`} key={index} className="SuccessStoriessListBox" >
                        <article>
                            <div className="SuccessStoriessListBoxLeft">
                                <img src={A.Photos} alt={`${A.BlogPath} | SENEER Capital | SENEX | Business | Busniess Laon | ondeck`} />
                            </div>
                            <div className="SuccessStoriessListBoxRight">
                                <h1>{A.Title}</h1>
                                <h2>{A.Discription.slice(0, 60 - 1) + "..."}</h2>
                                <span>
                                    <h5>{readingTime(Blogs[index])} MIN READ</h5>
                                    <h4>{A.Date}</h4>
                                </span>
                            </div>
                        </article>

                    </a>
                )}
            </div>
            <div className="SuccessStoriessListFaq">
                <div className="SuccessStoriessListFaqHead">
                    <h1>Frequently asked questions</h1>
                    <a href="/faq">View All</a>
                </div>
                <div className="SuccessStoriessListFaqList">
                    {FaqList.slice(getNumberFaq, (getNumberFaq + 5)).map((A, index) =>
                        <article key={index} className="FaqListBox">
                            <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                <h1>{A.question}</h1>
                                {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                <h3><span> {A.category}</span></h3>
                            </a>
                        </article>

                    )}
                </div>
            </div>
            <div className="SuccessStoriesApply">
                <h1>Elevate Your Business with Small Business Lending Specialists</h1>
                <div className="SuccessStoriesApplyBox">
                    <img src={Review} alt="business loan reviews, small business reviews, business line of credit reviews, small business lenders" />
                    <h2> Submit your application within minutes <br /> No commitment required</h2>
                    <button onClick={() => navigate('/apply')}>Apply Now</button>
                </div>

            </div>

            <NavigatorPath />

        </div >
    )
}

