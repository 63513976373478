import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import './NavBar.scss'
// Componetns
// Images
import Arrow from '../../../Images/Arrow.webp'
import Dashboard from '../../../Images/Dashboard.svg'
import Search from '../../../Images/Search.svg'
import Model from '../../../Images/Model.svg'
import Rules from '../../../Images/Rules.svg'
import Notification from '../../../Images/Notification.svg'
import Cases from '../../../Images/Cases.svg'
import Settings from '../../../Images/Settings.svg'

export default function NavBar({  }) {
    const [isNavBarOpen, setNavBarOpen] = useState(false);
    const [isLogoutOpen, setLogoutOpen] = useState(false);
    const [JsonFile, setJsonFile] = useState({
        accountName: '',
        accountImage: ''
    });

    const navigate = useNavigate()
    const location = useLocation().pathname

    useEffect(() => {

    }, []);

    return (
        <div id='NavBar' className='NavBar'>
            <div className="OperateNavBar"
            onClick={() => {
                localStorage.setItem('isNavBarOpen', !isNavBarOpen)
                setNavBarOpen(!isNavBarOpen)
            }}>
                <img src={Arrow} alt="" style={isNavBarOpen ? { "transform": "rotate(180deg)" } : { "transform": "rotate(0deg)" }} />
            </div>

            <div className="MainSiteLinks" style={isNavBarOpen ? { "width": "220px" } : { "width": "60px" }}>
                <div className="SiteLinks">
                    {/* Dashboard */}
                    <div
                        onClick={() => navigate('/portal/platform')}
                        className={location === "/portal/platform" ? "Link LinkSelected" : "Link"}
                        style={!isNavBarOpen ? { "marginRight": "10px", "padding": "20px" } : null}>
                        <img src={Dashboard} alt="" />
                        {isNavBarOpen ? <h2>Dashboard</h2> : null}
                    </div>
                    {/* Rules */}
                    <div
                        onClick={() => navigate('/portal/applications')}
                        className={location === "/portal/applications" || location === "/applications/new" || location === `/applications/edit/${location.split('/')[3]}` || location === "/applications/newmanual" || location === `/applications/editmanual/${location.split('/')[3]}` ? "Link LinkSelected" : "Link"}
                        style={!isNavBarOpen ? { "marginRight": "10px", "padding": "20px" } : null}>
                        <img src={Rules} alt="" />
                        {isNavBarOpen ? <h2>applications</h2> : null}
                    </div>
                    {/* Pipeline */}
                    <div
                        onClick={() => navigate('/portal/sellers')}
                        className={location === "/portal/sellers" || location === `/pipelines/${location.split('/')[2]}` ? "Link LinkSelected" : "Link"}
                        style={!isNavBarOpen ? { "marginRight": "10px", "padding": "20px" } : null}>
                        <img src={Model} alt="" />
                        {isNavBarOpen ? <h2>Sellers</h2> : null}
                    </div>
                    {/* search */}
                    <div
                        onClick={() => navigate('/search')}
                        className={location === "/search" ? "Link LinkSelected" : "Link"}
                        style={!isNavBarOpen ? { "marginRight": "10px", "padding": "20px" } : null}>
                        <img src={Search} alt="" />
                        {isNavBarOpen ? <h2>Search</h2> : null}
                    </div>
                    {/* Alerts */}
                    <div
                        onClick={() => navigate('/alerts')}
                        className={location === "/alerts" || location === `/alerts/${location.split('/')[2]}`  ? "Link LinkSelected" : "Link"}
                        style={!isNavBarOpen ? { "marginRight": "10px", "padding": "20px" } : null}>
                        <img src={Notification} alt="" />
                        {isNavBarOpen ? <h2>Alerts</h2> : null}
                    </div>
                    {/* Cases */}
                    <div
                        onClick={() => navigate('/cases')}
                        className={location === "/cases" || location === `/singlecase/${location.split('/')[2]}/${location.split('/')[3]}` ? "Link LinkSelected" : "Link"}
                        style={!isNavBarOpen ? { "marginRight": "10px", "padding": "20px" } : null}>
                        <img src={Cases} alt="" />
                        {isNavBarOpen ? <h2>Cases</h2> : null}
                    </div>
                    {/* Settings */}
                    <div
                        onClick={() => navigate('/settings/account')}
                        className={location === "/settings/account" || location === "/settings/notification" || location === "/settings/customization" || location === "/settings/general" ? "Link LinkSelected" : "Link"}
                        style={!isNavBarOpen ? { "marginRight": "10px", "padding": "20px" } : null}>
                        <img src={Settings} alt="" />
                        {isNavBarOpen ? <h2>Settings</h2> : null}
                    </div>
                </div>
            </div>
        </div>
    )
}
