import React from 'react'
import { useNavigate } from 'react-router-dom'
import './Footer.scss';
import facebook from '../../Images/facebook.png'
import instagram from '../../Images/instagram.png'
import linkedin from '../../Images/linkedin.png'
import twitter from '../../Images/twitter.png'
import threads from '../../Images/threads.png'
import Logo from '../../Images/Logo.png'

// import { useLocation } from "react-router-dom";

export default function Footer({ WebSitePages, navigateTo }) {
    const Navigate = useNavigate()
    return (
        <div className="Footer">
            <div className="Footer2">
                <div className="Footer2Logo" onClick={() => navigateTo("")}>
                    <img src={Logo} alt="" />
                    <h1><b>SENEER </b>Capital</h1>
                </div>
                <div className='socialmediafooter'>
                    <a target='blank' href="https://www.facebook.com/profile.php?id=100094632027169">
                        <img src={facebook} alt="SENEER Capital facebook" />
                    </a>
                    <a target='blank' href="https://www.instagram.com/seneercapital/">
                        <img src={instagram} alt="SENEER Capital instagram" />
                    </a>
                    <a target='blank' href="https://www.linkedin.com/company/seneercapital">
                        <img src={linkedin} alt="SENEER Capital linkedin" />
                    </a>
                    <a target='blank' href="https://www.threads.net/@seneercapital">
                        <img src={threads} alt="SENEER Capital threads" />
                    </a>
                    <a target='blank' href="https://twitter.com/SeneerCapital">
                        <img src={twitter} alt="SENEER Capital twitter X" />
                    </a>
                </div>
            </div>
            <div className="FooterTop">

                <div className="SiteMapDiv">
                    <h1>SENEER Capital</h1>
                    <div className="SiteMapDivList">
                        {WebSitePages.filter(a => a.Category == "Resources").map((A, index) =>
                            <a key={index} href={`${A.Path}`}>{A.Name}</a>
                        )}
                    </div>
                </div>
                <div className="SiteMapDiv">
                    <h1>Business solutions</h1>
                    <div className="SiteMapDivList">
                        {WebSitePages.filter(a => a.Category == "Sulotion").map((A, index) =>
                            <a key={index} href={`${A.Path}`}>{A.Name}</a>
                        )}
                    </div>
                </div>
                <div className="SiteMapDiv">
                    <h1>About Us</h1>
                    <div className="SiteMapDivList">
                        {WebSitePages.filter(a => a.Category == "About Us").map((A, index) =>
                            <a key={index} href={`${A.Path}`}>{A.Name}</a>
                        )}
                        {WebSitePages.filter(a => a.Category == "About").map((A, index) =>
                            <a key={index} href={`${A.Path}`}>{A.Name}</a>
                        )}
                    </div>
                </div>
                <div className="SiteMapDiv">
                    <h1>Contact Us</h1>
                    <div className="SiteMapDivList">
                        <a target='_blank' href="">
                            17070 Collins Ave #120 Sunny Isles Beach, FL 33160
                        </a>
                        <a target='_blank' href="tel:+1 (313) 710-9766">
                            +1 (313) 710-9766
                        </a>
                        <a target='_blank' href="mailto:info@seneercapital.com">
                            info@seneercapital.com
                        </a>
                    </div>
                </div>

            </div>
            <div className="Footer3">
                <h1>Copyright © 2023 SENEER. All rights reserved.</h1>
                <p>Please note that there are certain industries we cannot serve. For a complete list, please refer to our restricted industries.</p>
                <p>
                    CONFIDENTIALITY NOTICE: This message (including any attachments) is intended solely for the use of Seneer Capital Services, its affiliates and the individual addressee(s). This message may contain confidential and/or private information privileged to the recipient or recipients named above. If you are not the authorized recipient(s), or the employee or agent responsible for delivering this message to the intended recipient(s), please immediately notify the sender by e-mail at the address shown above and delete this message from your system, other storage mechanism and/or shred the document and any attachments. Any unauthorized use, review or dissemination of this message in whole or in part by persons or entities other than the intended recipient is strictly prohibited. Seneer Capital Services shall not be liable for the improper or incomplete transmission of the information contained in this communication nor for any delay in its receipt or damage to your system.
                </p>
            </div>
        </div>
    )
}
