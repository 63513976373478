import React, { useState } from 'react'
import './ApplicationsPortal.scss'
import { Helmet } from "react-helmet";
import { useLocation } from 'react-router-dom';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

export default function ApplicationsPortal({ PortalData, Datas, navigateTo }) {
    const location = useLocation()


    const signUp = async () => {

        const res = await fetch('http://localhost:1000/api/users/register', {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify({
                // userEmail: userEmail,
                // userPassword: userPassword,
                // userCode: userCode ? userCode : 'null',
                // userTerms: userTerms
            })
        })
        const data = await res.json()
        console.log(data)

        {/* <button
                onClick={()=>{
                    signUp()
                }}
                >Send Email</button> */}

    }

    return (
        <div className="ApplicationsPortal">
            <Helmet>
                {/* <!-- TITLE --> */}
                <title>Privacy Policy | The Bump Vape</title>
                {/* <!-- META --> */}
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
                <meta property="og:image" content="article" />
                <meta property="og:url" content="https://www.thebumpvape.com/ApplicationsPortal" />
                <meta name="description" content="Protecting your privacy while using our website, product, and software is of utmost importance to us, especially when it involves conducting business or sharing sensitive personal information. This Privacy Notice serves as the governing document for our Privacy Policy, applying to all ApplicationsPortal of our website, product, and software." />
                <meta property="og:title" content="Privacy Policy | The Bump Vape" />
                <meta property="og:description" content="Protecting your privacy while using our website, product, and software is of utmost importance to us, especially when it involves conducting business or sharing sensitive personal information. This Privacy Notice serves as the governing document for our Privacy Policy, applying to all ApplicationsPortal of our website, product, and software." />
                <meta property="og:site_name" content="Privacy Policy | The Bump Vape" />
                <meta name="keywords" content="BUMP, electronic cigarette, e-cigarette, vaping product, vapes, vape bump, bump Privacy Policy" ></meta>
            </Helmet>
            <div className="ApplicationsPortalDatas">

                <div className="ApplicationsPortalDatas2">
                    <div className="ApplicationsPortalDatasHeader">
                        <h1>ApplicationsPortal</h1>
                        <h1>ApplicationsPortal</h1>
                    </div>
                    <div className="ApplicationsPortalDatasBottom">
                        {PortalData.map((A, index) =>
                            <div className="ApplicationsPortalDatasBottomBox">
                                <h1><img src={A.icon} alt="" /> {A.name} </h1>
                                <h2>{Datas.filter(s => new URLSearchParams(s.link.substring(s.link.indexOf('?') + 0)).get("sourc") == A.Portalsourc).length}</h2>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

