import React, { useState } from 'react'
import leftMenu from '../../Images/leftMenu.png'
import next from '../../Images/next.png'
import closeMenu from '../../Images/closeMenu.png'
import Logo from '../../Images/Logo.png'
import unlocke from '../../Images/unlock.png'
import compliant from '../../Images/compliant.png'
import Review from '../../Images/Review.png'
import verified from '../../Images/verified.png'
import './Header.scss';
import $ from 'jquery';
import { useLocation } from "react-router-dom";

import appmoney from '../../Images/appmoney.png'
import appcalendar from '../../Images/appcalendar.png'
import appsearch from '../../Images/appsearch.png'
import appdropshipping from '../../Images/appdropshipping.png'
import appwallet from '../../Images/appwallet.png'
import Close from '../../Images/closeMenu.png'
import Arrow from '../../Images/downArrow.png'

export default function Header({ showMenu, setShowMenu, navigateTo, WebSitePages }) {
    const [OpenFirstMenu, setOpenFirstMenu] = useState(false);
    const [header, setHeader] = useState(false)
    const [header2, setHeader2] = useState(false)
    const [expleation, setExpleation] = useState(false);

    // const { pathname } = useLocation();
    // if (pathname === "/order") return null;
    return (
        <div className="Header">

            <div className="HeaderTopComputer">
                <div className="Header2" onClick={() => navigateTo("")}>
                    <img src={Logo} alt="" />
                    <h1><b>SENEER </b>Capital</h1>
                </div>

                <div className="Header1">
                    <div className="Header1Box">
                        <div className="Header1BoxHedaer">
                            <h1>Business Loans</h1>
                            <img src={Arrow} alt="" />
                        </div>

                        <div className="Header1BoxMenu">
                            <a href="business-line-of-credit" className="Header1BoxMenuBox">
                                <img src={appmoney} alt="business line of credit" />
                                <h3>Line of Credit</h3>
                            </a>
                            <a href="long-term-loan" className="Header1BoxMenuBox">
                                <img src={appcalendar} alt="long term loan" />
                                <h3>Term Loan</h3>
                            </a>
                            <a href="sba-loan" className="Header1BoxMenuBox">
                                <img src={appdropshipping} alt="small business loan" />
                                <h3>Small Business Loan</h3>
                            </a>
                            <a href="same-day-funding" className="Header1BoxMenuBox">
                                <img src={appwallet} alt="same day funding" />
                                <h3>Same Day Funding</h3>
                            </a>
                        </div>
                    </div>
                    <div className="Header1Box">
                        <div className="Header1BoxHedaer">
                            <h1>Resources</h1>
                            <img src={Arrow} alt="" />
                        </div>

                        <div className="Header1BoxMenu">
                            {WebSitePages.filter(a => a.Category == "Resources").map((A, index) =>
                                <a key={index} href={`${A.Path}`} className="Header1BoxMenuBox">
                                    <h3>{A.Name}</h3>
                                </a>
                            )}
                        </div>
                    </div>
                    <div className="Header1Box">
                        <div className="Header1BoxHedaer">
                            <h1>About Us</h1>
                            <img src={Arrow} alt="" />
                        </div>

                        <div className="Header1BoxMenu">
                            {WebSitePages.filter(a => a.Category == "About Us").map((A, index) =>
                                <a key={index} href={`${A.Path}`} className="Header1BoxMenuBox">
                                    <h3>{A.Name}</h3>
                                </a>
                            )}
                        </div>
                    </div>
                </div>
                <div className='Header3'>
                    <img src={verified} alt="" onClick={() => setExpleation(true)} />
                </div>
            </div>

            <div className="HeaderTop">
                <div className="Header1">
                    <img src={verified} alt="" onClick={() => setExpleation(true)} />
                </div>
                <div className="Header2" onClick={() => navigateTo("")}>
                    <img src={Logo} alt="" />
                    <h1><b>SENEER </b>Capital</h1>
                </div>
                <div className='Header3'>
                    {showMenu ?
                        <img
                            style={header ? { "filter": "invert(1)", "width": "30px" } : { "filter": "invert(0)", "width": "30px" }}
                            src={closeMenu} alt=""
                            className='menuCloseBtn'
                            onClick={() => setShowMenu(false)}
                        />
                        :
                        <img
                            style={header ? { "filter": "invert(1)" } : { "filter": "invert(0)" }}
                            src={leftMenu} alt=""
                            onClick={() => setShowMenu(true)}
                        />
                    }

                </div>
            </div>



            <div className="HeaderBottom">

                <div className='Header2'>
                    <div className="Header2Box" onClick={() => navigateTo("contact")}>
                        <img src={compliant} alt="" />
                        <h2 >Contact</h2>
                    </div>
                    <div className="Header2Box" onClick={() => navigateTo("blog")}>
                        <img src={Review} alt="" />
                        <h2 >Blog</h2>
                    </div>
                </div>
                <div className='Header1'>
                    <h2 onClick={() => navigateTo("apply")}>APPLY NOW<img src={next} alt="" /></h2>
                </div>
            </div>

            {/* Applications Toturial  */}
            {expleation ?
                <div className="ApplicationsToturial">
                    <div className="ApplicationsToturialShadow" onClick={() => setExpleation(false)}></div>
                    <div className="ApplicationsToturialMain">
                        <div className="ApplicationsToturialMainImgClose">
                            <img src={Close} alt="" onClick={() => setExpleation(false)} />
                        </div>
                        <div className="ApplicationsToturialMainHeader">
                            <h3>You Are Secure</h3>
                            <h4>We prioritize your financial security and protect you with encryption, identity verification, and the most widely secure payment processor.</h4>
                        </div>
                        <div className="ApplicationsToturialMainBody">
                            <div className="ApplicationsToturialMainBodyBox">
                                <div className="ApplicationsToturialMainBodyBoxLeft">
                                    <img src={appsearch} alt="" />
                                </div>
                                <div className="ApplicationsToturialMainBodyBoxRight">
                                    <h3>Soft Credit Check</h3>
                                    <h4>
                                        A soft inquiry allows SENEER Capital to review your credit to get a sense of how much fund your business will qualified for.
                                    </h4>
                                    <h5>This will NOT affect your credit score.</h5>
                                </div>
                            </div>
                            <div className="ApplicationsToturialMainBodyBox">
                                <div className="ApplicationsToturialMainBodyBoxLeft">
                                    <img src={appmoney} alt="" />
                                </div>
                                <div className="ApplicationsToturialMainBodyBoxRight">
                                    <h3>Your Data, Encrypted</h3>
                                    <h4>We protect your personal and financial data with bank level encryption.</h4>
                                </div>
                            </div>
                            <div className="ApplicationsToturialMainBodyBox">
                                <div className="ApplicationsToturialMainBodyBoxLeft">
                                    <img src={appwallet} alt="" />
                                </div>
                                <div className="ApplicationsToturialMainBodyBoxRight">
                                    <h3>3 Minute Application</h3>
                                    <h4>Our application form is easy to fill out and can be completed in less than 3 minutes!</h4>
                                </div>
                            </div>
                            <div className="ApplicationsToturialMainBodyBox">
                                <div className="ApplicationsToturialMainBodyBoxLeft">
                                    <img src={appcalendar} alt="" />
                                </div>
                                <div className="ApplicationsToturialMainBodyBoxRight">
                                    <h3>Get Fund Today!</h3>
                                    <h4>Once you've accepted the offer, we'll promptly send you a contract that outlines the terms and conditions of the agreement.</h4>
                                </div>
                            </div>
                        </div>
                        <div className="ApplicationsToturialMainBodyClose" onClick={() => setExpleation(false)}>
                            <h1>Dismiss</h1>
                        </div>
                    </div>
                </div>
                : null}
        </div>
    )
}
