import React, { useState } from 'react'
import './Platforms.scss'
import { Helmet } from "react-helmet";
import { useLocation } from 'react-router-dom';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend
)
export default function Platforms({ PortalData, Datas, navigateTo }) {
    const location = useLocation()

    const data = {
        labels: PortalData.map((A, index) => A.name),
        datasets: [{
            label: 'Applications',
            // data: Datas.map(),
            data: PortalData.map((A, index) => Datas.filter(s => new URLSearchParams(s.link.substring(s.link.indexOf('?') + 0)).get("sourc") == A.Portalsourc).length),
            backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)',
                'rgb(25, 205, 86)',
                'rgb(75, 100, 16)',
                'rgb(155, 205, 326)',
                'rgb(355, 15, 136)',
            ],
            hoverOffset: 4
        }]
    };
    const config = {
        type: 'doughnut',
        data: data,
    };


    const validEmails = async () => {
        const res = await fetch('http://localhost:1000/api/valid/validemailserver', {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify({
                // 
            })
        })
        const data = await res.json()
        console.log(data);

        if (data.err) {
            console.log(data);
        } else if (data.msg) {
            console.log(data);
        }

    }

    const sendEmail = async () => {
        const res = await fetch('http://localhost:1000/api/users/sendEmails', {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify({
                // 
            })
        })
        const data = await res.json()
        console.log(data);

        if (data.err) {
            console.log(data);
        } else if (data.msg) {
            console.log(data);
        }

    }

    return (
        <div className="Platforms">
            <Helmet>
                {/* <!-- TITLE --> */}
                <title>Privacy Policy | The Bump Vape</title>
                {/* <!-- META --> */}
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
                <meta property="og:image" content="article" />
                <meta property="og:url" content="https://www.thebumpvape.com/Platforms" />
                <meta name="description" content="Protecting your privacy while using our website, product, and software is of utmost importance to us, especially when it involves conducting business or sharing sensitive personal information. This Privacy Notice serves as the governing document for our Privacy Policy, applying to all users of our website, product, and software." />
                <meta property="og:title" content="Privacy Policy | The Bump Vape" />
                <meta property="og:description" content="Protecting your privacy while using our website, product, and software is of utmost importance to us, especially when it involves conducting business or sharing sensitive personal information. This Privacy Notice serves as the governing document for our Privacy Policy, applying to all users of our website, product, and software." />
                <meta property="og:site_name" content="Privacy Policy | The Bump Vape" />
                <meta name="keywords" content="BUMP, electronic cigarette, e-cigarette, vaping product, vapes, vape bump, bump Privacy Policy" ></meta>
            </Helmet>
            <button onClick={()=>validEmails()}>Valid Emails</button>
            <h1>next</h1>
            <button onClick={()=>sendEmail()}>send Emails</button>
            <div className="PlatformsMainData">
                <div className="PlatformsMainDataBox">
                    <h1>Applications</h1>
                    <h2>{Datas.length}</h2>
                    <h3>All Time</h3>
                </div>
                <div className="PlatformsMainDataBox">
                    <h1>Applications</h1>
                    <h2>{Datas.length}</h2>
                    <h3>All Time</h3>
                </div>
                <div className="PlatformsMainDataBox">
                    <h1>Applications</h1>
                    <h2>{Datas.length}</h2>
                    <h3>All Time</h3>
                </div>
            </div>
            <div className="PlatformsDatas">
                <div className="PlatformsDatas1">
                    <h1>Applications</h1>
                    <h2>{Datas.length}</h2>
                    <Doughnut
                        data={data}
                    // options={options}
                    >

                    </Doughnut>
                </div>
                <div className="PlatformsDatas2">
                    <div className="PlatformsDatasHeader">
                        <h1>Platforms</h1>
                        <h1>Applications</h1>
                    </div>
                    <div className="PlatformsDatasBottom">
                        {PortalData.map((A, index) =>
                            <div className="PlatformsDatasBottomBox">
                                <h1><img src={A.icon} alt="" /> {A.name} </h1>
                                <h2>{Datas.filter(s => new URLSearchParams(s.link.substring(s.link.indexOf('?') + 0)).get("sourc") == A.Portalsourc).length}</h2>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

