import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Contact from '../Components/Contact/Contact'
import HomePage from '../Components/HomePage/HomePage'
import Applications from '../Components/Applications/Applications'
import Blog from '../Components/Blog/Blog'
import BlogPage from '../Components/BlogPage/BlogPage'
import Portal from '../Portal/Portal'
import SiteMap from '../Tools/SiteMap/SiteMap'
import NotFound from '../Tools/NotFound/NotFound'
import PrivacyPolicy from '../Components/PrivacyPolicy/PrivacyPolicy'
import TermsConditions from '../Components/TermsConditions/TermsConditions'
import Faq from '../Components/Faq/Faq'
import FaqPage from '../Components/FaqPage/FaqPage'
import Thanks from '../Tools/Thanks/Thanks'
import BusinessLineofCredit from '../Components/BusinessLineofCredit/BusinessLineofCredit'
import LongTermLoan from '../Components/LongTermLoan/LongTermLoan'
import SameDayFunding from '../Components/SameDayFunding/SameDayFunding'
import SBALoan from '../Components/SBALoan/SBALoan'
import SuccessStories from '../Components/SuccessStories/SuccessStories'
import BlogEditor from '../Components/BlogEditor/BlogEditor'
import Links from '../Components/Links/Links'


export default function Main({ navigateTo, Blogs, FaqList, WebSitePages }) {

    return (
        // <div>
        <Routes>
            <Route path="/" element={<HomePage navigateTo={navigateTo} Blogs={Blogs} FaqList={FaqList} />} />
            <Route path="/blog" element={<Blog Blogs={Blogs} FaqList={FaqList} />} />
            <Route path="/blog/:id" element={<BlogPage Blogs={Blogs} FaqList={FaqList} />} />
            <Route path="/blogeditor" element={<BlogEditor />} />
            <Route path="/faq" element={<Faq FaqList={FaqList} Blogs={Blogs} />} />
            <Route path="/faq/:id" element={<FaqPage FaqList={FaqList} Blogs={Blogs} />} />
            <Route path="/successstories" element={<SuccessStories Blogs={Blogs} FaqList={FaqList} />} />
            <Route path="/portal/*" element={<Portal Blogs={Blogs} />} />
            {/*  */}
            <Route path="/business-line-of-credit" element={<BusinessLineofCredit Blogs={Blogs} FaqList={FaqList} />} />
            <Route path="/long-term-loan" element={<LongTermLoan Blogs={Blogs} FaqList={FaqList} />} />
            <Route path="/same-day-funding" element={<SameDayFunding Blogs={Blogs} FaqList={FaqList} />} />
            <Route path="/sba-loan" element={<SBALoan Blogs={Blogs} FaqList={FaqList} />} />
            {/*  */}
            <Route path="/contact" element={<Contact />} />
            <Route path="/links" element={<Links />} />
            <Route path="/apply" element={<Applications navigateTo={navigateTo} />} />
            <Route path="/thanks" element={<Thanks navigateTo={navigateTo} />} />
            {/* <Route path="/about" element={<About  />} /> */}
            <Route path="/privacypolicy" element={<PrivacyPolicy navigateTo={navigateTo} />} />
            <Route path="/termsconditions" element={<TermsConditions navigateTo={navigateTo} />} />
            {/* <Route path="/support" element={<Support  navigateTo={navigateTo} />} /> */}


            {/* SiteMap */}
            {["/sitemaps", "/sitemap", "/widgets"].map((path, index) =>
                <Route path={path} key={index} element={<SiteMap Blogs={Blogs} FaqList={FaqList} WebSitePages={WebSitePages} />} />
            )}
            <Route path="/sitemap/:id" element={<SiteMap Blogs={Blogs} FaqList={FaqList} WebSitePages={WebSitePages} />} />
            {/* 404 */}
            <Route path="*" element={<NotFound />} />

            {/* Emails */}
            <Route path="/emails" element={<Faq FaqList={FaqList} Blogs={Blogs} />} />

        </Routes>
        // </div>
    )
}
