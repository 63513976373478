import React from "react";
import './LongTermLoan.scss';
import $ from 'jquery';
import { useState } from "react";
import { Helmet } from "react-helmet";

// images
import Review from '../../Images/Review.png'
import next from '../../Images/next.png'
import blog from '../../Images/blog6.jpg'
import blog3 from '../../Images/blog5.jpg'
import MainPic from '../../Images/MainPic.jpg'

import clock from '../../Images/clock.png'
import computer from '../../Images/computer.png'
import books from '../../Images/books.png'
import { useNavigate } from "react-router-dom";

export default function LongTermLoan({ FaqList, Blogs }) {
    const navigate = useNavigate()
    function readingTime(S) {
        const text = S.Text;
        const wpm = 225;
        const words = text.trim().split(/\s+/).length;
        const time = Math.ceil(words / wpm);
        return time
    }

    const getSpecificNumberFaq = () => {
        const number = Math.random() * FaqList.length
        if (number <= (FaqList.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumberFaq()
        }
    }
    const [getNumberFaq, setgetNumberFaq] = useState(getSpecificNumberFaq());
    const getSpecificNumberBlog = () => {
        const number = Math.random() * Blogs.length
        if (number <= (Blogs.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumberBlog()
        }
    }
    const [getNumberBlog, setgetNumberBlog] = useState(getSpecificNumberBlog());
    return (
        <div className="LongTermLoan">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Business Term Loan: Empower Your Business with Swift Funding | SENEER</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Business Term Loan: Empower Your Business with Swift Funding | SENEER" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Business Term Loan: Empower Your Business with Swift Funding | SENEER" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="website" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="website" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.seneercapital.com/long-term-loan" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Empower your business with swift funding through a Business Term Loan. Achieve your financial goals with speed and efficiency. Apply today!" data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Empower your business with swift funding through a Business Term Loan. Achieve your financial goals with speed and efficiency. Apply today!" data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Business Term Loan: Empower Your Business with Swift Funding | SENEER" data-react-helmet="true" />
                <meta name="keywords" content="Long Term Loans, Low-Interest Long Term Loans, Flexible Repayment Terms, Personal Long Term Loans, Business Long Term Loans, Online Long Term Loans, Secured Long Term Loans, Unsecured Long Term Loans, Long Term Loan Providers, Best Long Term Loan Rates" data-react-helmet="true" />
            </Helmet>
            {/* LongTermLoanMainBanner */}
            <div className="LongTermLoanMainBanner">
                <div className="LongTermLoanMainBannerLeft">
                    <h1>Business Term Loan: Empower Your Business with Swift Funding</h1>
                    <h2>Apply now to unlock fast funding opportunities for your business, with loan amounts available up to $250,000.</h2>
                    <button onClick={() => navigate('/apply')}>Apply Now</button>
                </div>
                <div className="LongTermLoanMainBannerRight">
                    <img src={blog} alt="Long Term Loans, Low-Interest Long Term Loans, Flexible Repayment Terms, Personal Long Term Loans, Business Long Term Loans, Online Long Term Loans, Secured Long Term Loans, Unsecured Long Term Loans, Long Term Loan Providers, Best Long Term Loan Rates" />
                    <div className="LongTermLoanMainBannerRightBubble1"></div>
                    <div className="LongTermLoanMainBannerRightBubble2"></div>
                </div>
            </div>
            <div className="LongTermLoanMainBanner2">
                <div className="LongTermLoanMainBanner2Top">
                    <div className="LongTermLoanMainBanner2TopBox">
                        <h2>Revolving</h2>
                        <h3>Purchasing inventory</h3>
                    </div>
                    <div className="LongTermLoanMainBanner2TopBox">

                        <h2>Providing working capital</h2>
                        <h3>Expanding or renovating</h3>
                    </div>
                    <div className="LongTermLoanMainBanner2TopBox">

                        <h2>Upgrading equipment</h2>
                        <h3>Hiring more employees</h3>
                    </div>
                </div>
                {/* Title */}
                <h1>Take Charge of Your Business with SENEER Capital Term Loan:</h1>
                <div className="LongTermLoanMainBanner2Bottom">
                    <div className="LongTermLoanMainBanner2BottomBox">
                        <img src={Review} alt="Long Term Loans, Low-Interest Long Term Loans, Flexible Repayment Terms, Personal Long Term Loans, Business Long Term Loans, Online Long Term Loans, Secured Long Term Loans, Unsecured Long Term Loans, Long Term Loan Providers, Best Long Term Loan Rates" />
                        <h2>Business with SENEER Capital Term Loan</h2>
                        <h3>Running a successful business requires the necessary working capital to thrive. Whether you need to invest in equipment, meet payroll obligations, or fuel your business expansion, the conventional route of approaching banks for small business loans often involves extensive paperwork and time-consuming processes, which can be a challenge for busy entrepreneurs.</h3>
                        <h2>That's where SENEER Capital steps in.</h2>
                        <h3>Our Small Business Term Loans offer funding options ranging from $5,000 to $250,000 with repayment terms extending up to 12 months. If approved, your funds can be in your hands as soon as the same business day. In addition, we provide an online Business Line of Credit, giving you the flexibility to maintain funds on hand. Both of these financing options can be used simultaneously for eligible customers.</h3>
                        <button onClick={() => navigate('/apply')}>Learn More</button>
                    </div>
                </div>
            </div>
            <div className="LongTermLoanMainBanner5">
                <div className="LongTermLoanMainBanner5Left">
                    <h1>Streamlined Funding for Your Business:</h1>
                    <div className="LongTermLoanMainBanner5LeftBox">
                        <h2>1</h2>
                        <div className="LongTermLoanMainBanner5LeftBoxRigth">
                            <h3>Application</h3>
                            <h4>Complete our user-friendly application in just minutes.</h4>
                        </div>
                    </div>
                    <div className="LongTermLoanMainBanner5LeftBox">
                        <h2>2</h2>
                        <div className="LongTermLoanMainBanner5LeftBoxRigth">
                            <h3>Decision</h3>
                            <h4>Discover your eligibility for a term loan, line of credit, or both. Once approved, select your loan amount and repayment terms.</h4>
                        </div>
                    </div>
                    <div className="LongTermLoanMainBanner5LeftBox">
                        <h2>3</h2>
                        <div className="LongTermLoanMainBanner5LeftBoxRigth">
                            <h3>Funds</h3>
                            <h4>Sign the contract and gain access to your funds as soon as the same day.</h4>
                        </div>
                    </div>
                    <button onClick={() => navigate('/apply')}>Apply Now</button>
                </div>
                <div className="LongTermLoanMainBanner5Right">
                    <img src={blog} alt="Long Term Loans, Low-Interest Long Term Loans, Flexible Repayment Terms, Personal Long Term Loans, Business Long Term Loans, Online Long Term Loans, Secured Long Term Loans, Unsecured Long Term Loans, Long Term Loan Providers, Best Long Term Loan Rates" />
                    <div className="LongTermLoanMainBanner5RightBubble1"></div>
                    <div className="LongTermLoanMainBanner5RightBubble2"></div>
                </div>

            </div>
            <div className="LongTermLoanMainBanner4">
                <div className="LongTermLoanMainBanner4Left">
                    <img src={blog} alt="Long Term Loans, Low-Interest Long Term Loans, Flexible Repayment Terms, Personal Long Term Loans, Business Long Term Loans, Online Long Term Loans, Secured Long Term Loans, Unsecured Long Term Loans, Long Term Loan Providers, Best Long Term Loan Rates" />
                    <div className="LongTermLoanMainBanner4LeftBubble1"></div>
                    <div className="LongTermLoanMainBanner4LeftBubble2"></div>
                </div>
                {/*  */}
                <div className="LongTermLoanMainBanner4Right">
                    <h1>Frequently Asked Questions - SENEER Capital Term Loan</h1>
                    <h2>SENEER Capital term loans provide a lump sum of cash that can be used for various business purposes.</h2>
                    <div className="LongTermLoanMainBanner4RightBenefits">
                        <div className="LongTermLoanMainBanner4RightBenefitsBox">

                            <h3>How Soon Can I Get My Funds?</h3>
                            <h4>SENEER Capital term loan can fund your bank account by 5 p.m. on the same day you're approved. Compared to standard ACH transfers, which can take up to three business days, this provides a swift solution.</h4>
                        </div>
                        <div className="LongTermLoanMainBanner4RightBenefitsBox">

                            <h3>Can I Get More Funds After My First Loan?</h3>
                            <h4>Many SENEER Capital Term Loan customers renew their loan. When you renew, all remaining interest on the original term loan is waived, and you receive a new loan with a lower origination fee.</h4>
                        </div>
                        <div className="LongTermLoanMainBanner4RightBenefitsBox">

                            <h3>What is the SMART Box®?</h3>
                            <h4>SENEER Capital SMART Box Capital Comparison Tool offers a clear breakdown of the cost of capital, making it easier to evaluate potential small business loan offers.</h4>
                        </div>
                        <div className="LongTermLoanMainBanner4RightBenefitsBox">

                            <h3>Can I Repay My SENEER Capital Term Loan Early? </h3>
                            <h4>Yes, you can pay off your loan early without any penalty or fee if you qualify for our 100% prepayment benefit option.</h4>
                        </div>
                        <div className="LongTermLoanMainBanner4RightBenefitsBox">

                            <h3>Are Payments Fixed or Variable?  </h3>
                            <h4>Payments for SENEER Capital term loan are fixed, ensuring a consistent and manageable payment structure based on your loan amount and term length.</h4>
                        </div>
                    </div>
                </div>
                {/*  */}
            </div>
            <div className="LongTermLoanMainBanner6">
                <h1>Are You Eligible?<br /> Check Our Business Term Loan Requirements:</h1>
                <div className="LongTermLoanMainBanner6List">
                    <div className="LongTermLoanMainBanner6TopBox">
                        <h2>1 Year</h2>
                        <h3>At least 1 year in business</h3>
                    </div>
                    <div className="LongTermLoanMainBanner6TopBox">
                        <h2>FICO® Score</h2>
                        <h3>Personal FICO® score of 600 or higher</h3>
                    </div>
                    <div className="LongTermLoanMainBanner6TopBox">
                        <h2>Annual Revenue</h2>
                        <h3>Business annual revenue of $100,000</h3>
                    </div>
                    <div className="LongTermLoanMainBanner6TopBox">
                        <h2>Account</h2>
                        <h3>Business checking account</h3>
                    </div>
                </div>
            </div>
            <div className="LongTermLoanMainBanner3">
                <h1>Advantages of SENEER Capital Business Financing:</h1>
                <div className="LongTermLoanMainBanner3List">
                    <div className="LongTermLoanMainBanner3Box">
                        <div className="LongTermLoanMainBanner3BoxIMG">
                            <img src={computer} alt="No Hard Credit Pulls" />
                        </div>
                        <h3>No Hard Credit Inquiries</h3>
                        <h4>Check your eligibility without affecting your credit score.</h4>
                    </div>
                    <div className="LongTermLoanMainBanner3Box">
                        <div className="LongTermLoanMainBanner3BoxIMG">
                            <img src={clock} alt="Pay Only for What You Borrow" />
                        </div>
                        <h3>Rapid Funding</h3>
                        <h4>Upon approval of an SENEER Capital Term Loan, receive your funds on the same day.</h4>
                    </div>
                    <div className="LongTermLoanMainBanner3Box">
                        <div className="LongTermLoanMainBanner3BoxIMG">
                            <img src={books} alt="Build Business Credit History" />
                        </div>
                        <h3>Building Business Credit</h3>
                        <h4>We report your payment history to business credit bureaus, aiding in the establishment of a robust business credit history with on-time payments.</h4>
                    </div>
                </div>

            </div>
            <div className="BlogsListFaq">
                <div className="BlogsListFaqHead">
                    <h1>Frequently asked questions</h1>
                    <a href="/faq">View All</a>
                </div>
                <div className="BlogsListFaqList">
                    {FaqList.slice(getNumberFaq, (getNumberFaq + 5)).map((A, index) =>
                        <article key={index} className="FaqListBox">
                            <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                <h1>{A.question}</h1>
                                {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                <h3><span> {A.category}</span></h3>
                            </a>
                        </article>

                    )}
                </div>
            </div>
            <div className="BlogsListReview">
                <div className="BlogsListReviewHead">
                    <h1>Related Articles</h1>
                    <a href="/blog">View All</a>
                </div>
                <div className="BlogsListReviewList">
                    {Blogs.slice(getNumberBlog, (getNumberBlog + 3)).map((A, index) =>

                        <a href={`/blog/${A.BlogPath.replace(/ /g, "_") + "_" + (A.BlogId - 1)}`} key={index} className="BlogsListReviewBox" >
                            <article>
                                <div className="BlogsListReviewBoxLeft">
                                    <img src={A.Photos} alt={A.BlogPath} />
                                </div>
                                <div className="BlogsListReviewBoxRight">
                                    <h1>{A.Title}</h1>
                                    <span>
                                        <h5>{readingTime(A)} MIN READ</h5>
                                        <h4>{A.Date}</h4>
                                    </span>
                                </div>
                            </article>
                        </a>
                    )}
                </div>
            </div>
            <div className="LongTermLoanMainBanner7">
                <div className="LongTermLoanMainBanner7Left">
                    <h1>Discover how business owners like Ellen Rozelle Turner from The William Everett Group have used their SENEER Capital Term Loan to fuel growth and success.</h1>
                    <h2>In our initial stages, securing traditional funding was challenging since conventional banks often hesitate to support small businesses until they have a proven track record. Fortunately, we discovered SENEER Capital, and we deeply appreciate the flexibility they've provided us as we've grown over the years.</h2>
                    <a href="/apply">Apply Now</a>
                </div>
                <div className="LongTermLoanMainBanner7Right">
                    <img src={blog3} alt="Long Term Loans, Low-Interest Long Term Loans, Flexible Repayment Terms, Personal Long Term Loans, Business Long Term Loans, Online Long Term Loans, Secured Long Term Loans, Unsecured Long Term Loans, Long Term Loan Providers, Best Long Term Loan Rates" />
                    <div className="LongTermLoanMainBanner7RightBubble1"></div>
                    <div className="LongTermLoanMainBanner7RightBubble2"></div>
                </div>
            </div>
        </div>
    )
}

