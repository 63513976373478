import React from "react";
import './HomePage.scss';
import $ from 'jquery';
import { useState } from "react";
import { Helmet } from "react-helmet";

// images
import Review from '../../Images/Review.png'
import next from '../../Images/next.png'
import MainPic from '../../Images/MainPic.jpg'
import blog6 from '../../Images/blog6.jpg'

import clock from '../../Images/clock.png'
import computer from '../../Images/computer.png'
import books from '../../Images/books.png'
import bgnobg from '../../Images/bgnobg.png'

import appmoney from '../../Images/appmoney.png'
import appcalendar from '../../Images/appcalendar.png'
import appdropshipping from '../../Images/appdropshipping.png'
import appsearch from '../../Images/appsearch.png'
import appwallet from '../../Images/appwallet.png'

export default function HomePage({ navigateTo, FaqList, Blogs }) {

    function readingTime(S) {
        const text = S.Text;
        const wpm = 225;
        const words = text.trim().split(/\s+/).length;
        const time = Math.ceil(words / wpm);
        return time
    }

    const getSpecificNumberFaq = () => {
        const number = Math.random() * FaqList.length
        if (number <= (FaqList.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumberFaq()
        }
    }
    const [getNumberFaq, setgetNumberFaq] = useState(getSpecificNumberFaq());
    const getSpecificNumberBlog = () => {
        const number = Math.random() * Blogs.length
        if (number <= (Blogs.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumberBlog()
        }
    }
    const [getNumberBlog, setgetNumberBlog] = useState(getSpecificNumberBlog());

    return (
        <div className="HomePage">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Quick and Effortless Small Business Financing | SENEER</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Quick and Effortless Small Business Financing | SENEER" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Quick and Effortless Small Business Financing | SENEER" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.seneercapital.com/" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Experience the power of our rapid cash advance, providing customized funding solutions to fuel your business's growth and financial prosperity. Start exploring opportunities today!" data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Experience the power of our rapid cash advance, providing customized funding solutions to fuel your business's growth and financial prosperity. Start exploring opportunities today!" data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Quick and Effortless Small Business Financing | SENEER" data-react-helmet="true" />
                <meta name="keywords" content="Financing, Business, Advance, Funding, Cash, Merchant, Capital, Loan, Credit, Repayment" data-react-helmet="true" />
            </Helmet>
            {/* MainBanner */}

            <div className="MainBanner">
                <div className="MainBannerLeft">
                    <h1>Expand your enterprise at your own pace.</h1>
                    <h2>Streamlined small business funding.<br />Complete your application in just minutes!</h2>
                    <button onClick={() => navigateTo('apply')}>Apply Now</button>
                </div>
                <div className="MainBannerRight">
                    <img src={bgnobg} alt="" />
                    <div className="MainBannerRightBubble1"></div>
                    <div className="MainBannerRightBubble2"></div>
                </div>
            </div>
            <div className="MainBanner2Top2">
                <div className="MainBanner2TopBox">
                    <h2>Funding options built to work for you.</h2>
                    <div className="MainBanner2TopBoxMenu">
                        <a href="business-line-of-credit" className="MainBanner2TopBoxBox">
                            <img src={appmoney} alt="business line of credit" />
                            <h3>Line of Credit</h3>
                        </a>
                        <a href="long-term-loan" className="MainBanner2TopBoxBox">
                            <img src={appcalendar} alt="long term loan" />
                            <h3>Term Loan</h3>
                        </a>
                        <a href="sba-loan" className="MainBanner2TopBoxBox">
                            <img src={appdropshipping} alt="small business loan" />
                            <h3>Small Business Loan</h3>
                        </a>
                        <a href="same-day-funding" className="MainBanner2TopBoxBox">
                            <img src={appwallet} alt="same day funding" />
                            <h3>Same Day Funding</h3>
                        </a>
                    </div>
                </div>
            </div>
            <div className="MainBannerReviews">
                <div className="MainBannerReviewsHeader">
                    <h1>Proudly recognized as "#1 Business Loans" by industry experts.</h1>
                </div>
                <div className="MainBannerReviewsMenu">
                    <div className="MainBannerReviewsMenuBox">
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/Yahoo%21_Finance_logo_2021.png/320px-Yahoo%21_Finance_logo_2021.png" alt="" />
                    </div>
                    <div className="MainBannerReviewsMenuBox">
                        <img src="https://cdn.worldvectorlogo.com/logos/marketwatch-logo.svg" alt="" />
                    </div>
                    <div className="MainBannerReviewsMenuBox">
                        <img src="https://1000logos.net/wp-content/uploads/2024/02/Business-Insider-Logo.png" alt="" />
                    </div>
                    <div className="MainBannerReviewsMenuBox">
                        <img src="https://1000logos.net/wp-content/uploads/2016/10/Associated-Press-logo.png" alt="" />
                    </div>
                    <div className="MainBannerReviewsMenuBox">
                        <img src="https://1000logos.net/wp-content/uploads/2022/05/Benzinga-Logo.png" alt="" />
                    </div>
                    <div className="MainBannerReviewsMenuBox">
                        <img src="https://www.iadea.com/wp-content/uploads/2018/10/digital-journal-logo.png" alt="" />
                    </div>
                </div>
            </div>
            <div className="MainBanner2">
                <div className="MainBanner2TopTop">
                    <div className="MainBanner2TopTopLeft">
                        <img src={MainPic} alt="" />
                    </div>
                    <div className="MainBanner2TopTopRight">
                        <div className="MainBanner2TopBox">
                            <h2>3-Minute</h2>
                            <h3>application with swift funding.</h3>
                        </div>
                        <div className="MainBanner2TopBox">
                            <h2>Over $2.5 billion</h2>
                            <h3>distributed to American businesses.</h3>
                        </div>
                        <div className="MainBanner2TopBox">
                            <h2>A top-notch A+ </h2>
                            <h3>rating from the Better Business Bureau.</h3>
                        </div>
                    </div>
                </div>

                {/* Title */}
                <h1>Tailored funding solutions designed to meet your needs.</h1>
                <div className="MainBanner2Bottom">
                    <div className="MainBanner2BottomBox">
                        <img src={appmoney} alt="" />
                        <h2>Introducing the SENEER Line of Credit</h2>
                        <h3>A revolving credit line available round the clock.</h3>

                        <h4>• Choose from credit limits ranging between $5,000 and $1,000,000.</h4>
                        <h4>• Enjoy flexible repayment options spanning 3, 6, 12, 18, or 24 months.</h4>
                        <h4>• Ideal for maintaining readily accessible funds.</h4>
                        <a href="/business-line-of-credit">Learn More</a>
                    </div>
                    <div className="MainBanner2BottomBox">
                        <img src={appcalendar} alt="" />
                        <h2>Explore the SENEER Term Loan</h2>
                        <h3>Access a single lump sum of cash with the possibility of future extensions.</h3>

                        <h4>• Loan amounts ranging from $5,000 to $1,000,000.</h4>
                        <h4>• Flexible repayment terms extending up to 24 months.</h4>
                        <h4>• Perfect for substantial investments in your business.</h4>
                        <a href="/long-term-loan">Learn More</a>
                    </div>
                </div>
                <div className="MainBanner2Bottom">
                    <div className="MainBanner2BottomBox">
                        <img src={appdropshipping} alt="" />
                        <h2>Apply Online for an SBA Loan</h2>
                        <h3>Embrace the flexibility of Small Business loans with manageable monthly payments </h3>

                        <h4>• SBA Loans Available Up To - $5,000,000</h4>
                        <h4>• With Attractive Rates Starting As Low As 2.25%</h4>
                        <h4>• Enjoy SBA Loan Terms Extending Up To 30 Years</h4>
                        <a href="/sba-loan">Learn More</a>
                    </div>
                    <div className="MainBanner2BottomBox">
                        <img src={appwallet} alt="" />
                        <h2>Same Day Business Funding</h2>
                        <h3>Experience our streamlined approval process with same-day funding. </h3>

                        <h4>• Access Up To $1,000,000</h4>
                        <h4>• With Competitive Rates Starting As Low As</h4>
                        <h4>• Same Day Business Funding Effortless Access to Rapid Working Capital</h4>
                        <a href="/long-term-loan">Learn More</a>
                    </div>
                </div>
            </div>
            <div className="MainBanner3">
                <h1>Discover why entrepreneurs prefer our services.</h1>
                <div className="MainBanner3List">
                    <div className="MainBanner3Box">
                        <div className="MainBanner3BoxIMG">
                            <img src={computer} alt="" />
                        </div>
                        <h3>No intrusive credit inquiries</h3>
                        <h4>Assess your eligibility without any impact on your credit score.***</h4>
                    </div>
                    <div className="MainBanner3Box">
                        <div className="MainBanner3BoxIMG">
                            <img src={clock} alt="" />
                        </div>
                        <h3>Rapid funding</h3>
                        <h4>Receive your funds as swiftly as within the same day.</h4>
                    </div>
                    <div className="MainBanner3Box">
                        <div className="MainBanner3BoxIMG">
                            <img src={books} alt="" />
                        </div>
                        <h3>Establish a robust business credit record</h3>
                        <h4>We report to business credit agencies, facilitating the development of your business credit history through punctual payments.</h4>
                    </div>
                </div>

            </div>
            <div className="MainBanner4">
                <div className="MainBanner4Left">
                    <img src={MainPic} alt="" />
                    <div className="MainBanner4LeftBubble1"></div>
                    <div className="MainBanner4LeftBubble2"></div>
                </div>
                <div className="MainBanner4Right">
                    <h1>What possibilities await with funding from SENEER Capital?</h1>
                    <h2>As a direct lender, we customize our financing to suit your specific requirements. Regardless of your objective, our in-house loan advisors are here to assist you in selecting a financing solution with no intermediaries or delays.</h2>
                    <div className="MainBanner4RightBenefits">
                        <div className="MainBanner4RightBenefitsBox">
                            <img src={next} alt="" />
                            <h3>Working Capital</h3>
                        </div>
                        <div className="MainBanner4RightBenefitsBox">
                            <img src={next} alt="" />
                            <h3>Expansion</h3>
                        </div>
                        <div className="MainBanner4RightBenefitsBox">
                            <img src={next} alt="" />
                            <h3>Inventory Purchase</h3>
                        </div>
                        <div className="MainBanner4RightBenefitsBox">
                            <img src={next} alt="" />
                            <h3>Equipment Purchase</h3>
                        </div>
                        <div className="MainBanner4RightBenefitsBox">
                            <img src={next} alt="" />
                            <h3>Hiring</h3>
                        </div>
                        <div className="MainBanner4RightBenefitsBox">
                            <img src={next} alt="" />
                            <h3>Marketing</h3>
                        </div>
                        <div className="MainBanner4RightBenefitsBox">
                            <img src={next} alt="" />
                            <h3>Diversification</h3>
                        </div>
                        <div className="MainBanner4RightBenefitsBox">
                            <img src={next} alt="" />
                            <h3>Renovation</h3>
                        </div>
                        <div className="MainBanner4RightBenefitsBox">
                            <img src={next} alt="" />
                            <h3>Seasonal Cash Flow</h3>
                        </div>
                        <div className="MainBanner4RightBenefitsBox">
                            <img src={next} alt="" />
                            <h3>Emergency Funds</h3>
                        </div>
                        <div className="MainBanner4RightBenefitsBox">
                            <img src={next} alt="" />
                            <h3>Technology Adoption</h3>
                        </div>
                        <div className="MainBanner4RightBenefitsBox">
                            <img src={next} alt="" />
                            <h3>Supplier Negotiation</h3>
                        </div>
                        <div className="MainBanner4RightBenefitsBox">
                            <img src={next} alt="" />
                            <h3>Competitive Advantage</h3>
                        </div>
                        <div className="MainBanner4RightBenefitsBox">
                            <img src={next} alt="" />
                            <h3>Debt Consolidation</h3>
                        </div>
                        <div className="MainBanner4RightBenefitsBox">
                            <img src={next} alt="" />
                            <h3>Credit Building</h3>
                        </div>

                    </div>
                </div>
            </div>
            <div className="MainBanner5">
                <div className="MainBanner5Left">
                    <h1>Financing that aligns with your pace.</h1>
                    <div className="MainBanner5LeftBox">
                        <h2>1</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Application in a breeze.</h3>
                            <h4>Our simplified process is crafted to be finished within mere minutes.</h4>
                        </div>
                    </div>
                    <div className="MainBanner5LeftBox">
                        <h2>2</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Decision in your hands.</h3>
                            <h4>Collaborate with a seasoned loan advisor to select the optimal choice for your needs.</h4>
                        </div>
                    </div>
                    <div className="MainBanner5LeftBox">
                        <h2>3</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Funds at your disposal.</h3>
                            <h4>Sign your agreement and access your funds, possibly as soon as the very same day.</h4>
                        </div>
                    </div>
                    <button onClick={() => navigateTo('apply')}>Apply Now</button>
                </div>
                <div className="MainBanner5Right">
                    <img src={blog6} alt="" />
                    <div className="MainBanner5RightBubble1"></div>
                    <div className="MainBanner5RightBubble2"></div>
                </div>

            </div>
            <div className="MainBanner6">
                <h1>Do we meet your criteria? <br />Verify our minimum requirements.*</h1>
                <div className="MainBanner6List">
                    <div className="MainBanner6TopBox">
                        <h2>1 Year</h2>
                        <h3>of business operation</h3>
                    </div>
                    <div className="MainBanner6TopBox">
                        <h2>$100K</h2>
                        <h3>in annual business revenue</h3>
                    </div>
                    <div className="MainBanner6TopBox">
                        <h2>Business</h2>
                        <h3>checking account</h3>
                    </div>
                </div>
            </div>
            <div className="BlogsListFaq">
                <div className="BlogsListFaqHead">
                    <h1>Frequently asked questions</h1>
                    <a href="/faq">View All</a>
                </div>
                <div className="BlogsListFaqList">
                    {FaqList.slice(getNumberFaq, (getNumberFaq + 5)).map((A, index) =>
                        <article key={index} className="FaqListBox">
                            <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                <h1>{A.question}</h1>
                                {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                <h3><span> {A.category}</span></h3>
                            </a>
                        </article>

                    )}
                </div>
            </div>
            <div className="BlogsListReview">
                <div className="BlogsListReviewHead">
                    <h1>Related Articles</h1>
                    <a href="/blog">View All</a>
                </div>
                <div className="BlogsListReviewList">
                    {Blogs.slice(getNumberBlog, (getNumberBlog + 3)).map((A, index) =>

                        <a href={`/blog/${A.BlogPath.replace(/ /g, "_") + "_" + (A.BlogId - 1)}`} key={index} className="BlogsListReviewBox" >
                            <article>
                                <div className="BlogsListReviewBoxLeft">
                                    <img src={A.Photos} alt={A.BlogPath} />
                                </div>
                                <div className="BlogsListReviewBoxRight">
                                    <h1>{A.Title}</h1>
                                    <span>
                                        <h5>{readingTime(A)} MIN READ</h5>
                                        <h4>{A.Date}</h4>
                                    </span>
                                </div>
                            </article>
                        </a>
                    )}
                </div>
            </div>
            <div className="MainBanner7">
                <div className="MainBanner7Left">
                    <h1>We specialize in small businesses and are prepared to answer your call.</h1>
                    <h2>You don't have to be well-versed in small business loans. Our team of loan advisors based in the United States is here to assist you throughout the entire process.</h2>
                    <h2>
                        {/* (888) 269-4246 */}
                        <br />
                        Monday – Friday
                        <br />
                        8:30 a.m. – 7:30 p.m. ET
                    </h2>
                </div>
                <div className="MainBanner7Right">
                    <img src={MainPic} alt="" />
                    <div className="MainBanner7RightBubble1"></div>
                    <div className="MainBanner7RightBubble2"></div>
                </div>
            </div>
        </div>
    )
}

