import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Platforms from './Components/Platforms/Platforms'
import ApplicationsPortal from './Components/ApplicationsPortal/ApplicationsPortal'
import PortalApps from './Components/PortalApps/PortalApps'


export default function Main({ApplicationsData, SellersData, PortalData, Datas, navigateTo, Blogs }) {
    
    return (
        <div>
            <Routes>
                <Route path="/platform" element={<Platforms SellersData={SellersData} PortalData={PortalData} Datas={Datas} navigateTo={navigateTo} />} />                
                <Route path="/applications" element={<ApplicationsPortal SellersData={SellersData} PortalData={PortalData} Datas={Datas} navigateTo={navigateTo} />} />                
                <Route path="/portalapps" element={<PortalApps ApplicationsData={ApplicationsData} SellersData={SellersData} PortalData={PortalData} Datas={Datas} navigateTo={navigateTo} />} />                
            </Routes>
        </div>
    )
}
